// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TextInput_label__PJXqx {
  color: #000;
  font-size: 16px;
  display: inline-block;
  margin-bottom: 6px;
}
.TextInput_textInput__j\\+AXF {
  width: 100%;
  padding: 8px 8px 8px 8px;
  background-color: transparent;
  border: 1px solid #5d5d5d;
  border-radius: 6px;
  color: #000;
  font-size: 0.875rem;
}

::-webkit-datetime-edit {
  color: #fff;
}
`, "",{"version":3,"sources":["webpack://./src/component/common/TextInput/TextInput.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,eAAe;EACf,qBAAqB;EACrB,kBAAkB;AACpB;AACA;EACE,WAAW;EACX,wBAAwB;EACxB,6BAA6B;EAC7B,yBAAyB;EACzB,kBAAkB;EAClB,WAAW;EACX,mBAAmB;AACrB;;AAEA;EACE,WAAW;AACb","sourcesContent":[".label {\n  color: #000;\n  font-size: 16px;\n  display: inline-block;\n  margin-bottom: 6px;\n}\n.textInput {\n  width: 100%;\n  padding: 8px 8px 8px 8px;\n  background-color: transparent;\n  border: 1px solid #5d5d5d;\n  border-radius: 6px;\n  color: #000;\n  font-size: 0.875rem;\n}\n\n::-webkit-datetime-edit {\n  color: #fff;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"label": `TextInput_label__PJXqx`,
	"textInput": `TextInput_textInput__j+AXF`
};
export default ___CSS_LOADER_EXPORT___;
