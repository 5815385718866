import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import appConfig from "../AppConfig";

const initialState = {
  loading: false,
  data: [],
  error: "",
};

export const getViscosityList = createAsyncThunk(
  "getViscosityList",
  async ({ packSizeSelectedItem, msLoggedRoleId }, { rejectWithValue }) => {
    const urls = `${appConfig.apiUrl}msGetViscosity?msLoggedRoleId=${msLoggedRoleId}&packId=${packSizeSelectedItem}`;
    const headers = {
      "X-API-Key": appConfig.apiKey,
      "Content-Type": "application/x-www-form-urlencoded",
    };
    const response = await axios.get(urls, { headers: headers });
    try {
      const result = response.data.data;
      return result;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const viscosityDetails = createSlice({
  name: "viscosityDetails",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getViscosityList.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getViscosityList.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.error = false;
    });
    builder.addCase(getViscosityList.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = action.error.message;
    });
  },
});

export default viscosityDetails.reducer;
