import React from "react";
import { useDispatch } from "react-redux";
import { getmemberValidInvalid } from "../../features/memberValidInvalidSlice";

function MemberMapped() {
  const dispatch = useDispatch();
  const params = new URLSearchParams(window.location.search);
  const validData = params.get("valid");
  const invalidData = params.get("invalid");
  const mappedId = params.get("Id");
  const msLoggedUserId = Number(localStorage.getItem("userId"));

  const handleDownLoadValidInvalidMember = async (status) => {
    const params = {
      uniqueId: mappedId,
      dataStatus: status,
      type: "Members",
      uploadedBy: msLoggedUserId,
    };
    await dispatch(getmemberValidInvalid(params))
      .then((response) => {
        const currentDate = new Date();
        const timestamp = currentDate.getTime();
        const formattedDate = currentDate.toISOString().slice(0, 10);
        const suggestedFileName = `MappedData_${status}_${formattedDate}_${timestamp}.csv`;
        const blob = new Blob([response.payload], { type: "text/csv" });

        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = suggestedFileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((error) => {
        console.error("Download error:", error);
      });
  };

  return (
    <>
      <div className="container" style={{ minHeight: "81.8vh" }}>
        <div className="row">
          <div className="col-md-12">
            <h3 className="text-capitalize fs-5 fst-italic mt-5">
               Member Upload Data
            </h3>
          </div>
          <div className="col-md-8 mt-3">
            <div className="row">
              <div className="col-sm-4 mb-3">
                <button
                  onClick={() => handleDownLoadValidInvalidMember("Valid")}
                  className={
                    validData === "0"
                      ? "btn btn-secondary w-100"
                      : "btn btn-danger w-100"
                  }
                  disabled={validData === "0" ? true : false}
                  id={validData === "0" ? "disableValidBtn" : ""}
                >
                  Download Valid ({validData})
                </button>
              </div>
              <div className="col-sm-4">
                <button
                  onClick={() => handleDownLoadValidInvalidMember("Invalid")}
                  className={
                    invalidData === "0"
                      ? "btn btn-secondary w-100"
                      : "btn btn-danger w-100"
                  }
                  disabled={invalidData === "0" ? true : false}
                  id={invalidData === "0" ? "disablebtn" : ""}
                >
                  Download Invalid ({invalidData})
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MemberMapped;
