import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import appConfig from "../AppConfig";

const initialState = {
  loading: false,
  data: [],
  error: "",
};
export const getMemberListData = createAsyncThunk(
  "getMemberListData",
  async (
    {
      formattedStartDate,
      formattedEndDate,
      districtItemSelected,
      mobileNumber,
      stateItemSelected,
      startIndex,
      msLoggedUserId,
      msLoggedRoleId,
    },
    { rejectWithValue }
  ) => {
    const urls = `${appConfig.apiUrl}msMemberList?msLoggedUserId=${msLoggedUserId}&msLoggedRoleId=${msLoggedRoleId}&fromDate=${formattedStartDate}&toDate=${formattedEndDate}&district=${districtItemSelected}&state=${stateItemSelected}&mobile=${mobileNumber}&start=${startIndex}&length=10`;
    const headers = {
      "X-API-Key": appConfig.apiKey,
    };
    const response = await axios.get(urls, { headers: headers });
    try {
      const result = await response.data;
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// export const downloadMemberListReports = createAsyncThunk(
//   "downloadMemberListReports",
//   async (
//     {
//       formattedStartDate,
//       formattedEndDate,
//       mobileNumber,
//       districtItemSelected,
//       stateItemSelected,
//       startIndex,
//       msLoggedUserId,
//       msLoggedRoleId,
//     },
//     { rejectWithValue }
//   ) => {
//     const urls = `${appConfig.apiUrl}msMemberReportDownload?msLoggedUserId=${msLoggedUserId}&msLoggedRoleId=${msLoggedRoleId}&fromDate=${formattedStartDate}&toDate=${formattedEndDate}&district=${districtItemSelected}&state=${stateItemSelected}&mobile=${mobileNumber}`;
//     const headers = {
//       "X-API-Key": appConfig.apiKey,
//     };
//     const response = await axios.get(urls, {
//       headers: headers,
//       responseType: "blob",
//     });
//     try {
//       const result = await response.data;
//       // console.log("result", result);
//       return result;
//     } catch (error) {
//       return rejectWithValue(error);
//     }
//   }
// );

export const memberListDetails = createSlice({
  name: "memberListDetails",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getMemberListData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getMemberListData.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.error = "";
    });
    builder.addCase(getMemberListData.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = action.error.message;
    });
    // builder.addCase(downloadMemberListReports.pending, (state) => {
    //   state.loading = true;
    // });
    // builder.addCase(downloadMemberListReports.fulfilled, (state) => {
    //   state.loading = false;
    // });
    // builder.addCase(downloadMemberListReports.rejected, (state, action) => {
    //   state.loading = false;
    //   state.data = [];
    //   state.error = action.error.message;
    // });
  },
});

export default memberListDetails.reducer;
