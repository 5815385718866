import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import appConfig from '../AppConfig';

// Async thunk to fetch carton transaction report
export const fetchCartonTransactionReport = createAsyncThunk(
  'cartonTransactionReport/fetchCartonTransactionReport',
  async ({ msLoggedUserId, msLoggedRoleId, startDate, endDate, token, state, district, mobileNumber,startIndex }, { rejectWithValue }) => {
    const fromDate = startDate ? new Date(startDate).toISOString().split('T')[0] : '';
    const toDate = endDate ? new Date(endDate).toISOString().split('T')[0] : '';
    
    const url = `${appConfig.apiUrl}msCartonTransactionReport?token=${token}&msLoggedUserId=${msLoggedUserId}&msLoggedRoleId=${msLoggedRoleId}&fromDate=${fromDate}&toDate=${toDate}&state=${state}&district=${district}&userMobile=${mobileNumber}&start=${startIndex}&length=10`;
    const headers = { "X-API-Key": appConfig.apiKey };

    try {
      const response = await axios.get(url, { headers });
      console.log("Carton Transaction Report API Response:", response.data);
      return response.data;
    } catch (error) {
       
      return rejectWithValue(error.response ? error.response.data : error.message);
    }
  }
);

// Slice to handle carton transaction report state
const cartonTransactionReportSlice = createSlice({
  name: 'cartonTransactionReport',
  initialState: {
    reports: [],
    loading: false,
    error: null
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCartonTransactionReport.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchCartonTransactionReport.fulfilled, (state, action) => {
        console.log("Carton Transaction Report State Update:", action.payload);
         // await setTimeout(() => {
    //     console.log("Carton Transaction Report API Request:", url);
    //     }, 10000); // 1000 milliseconds = 1 second
        state.loading = false;
        state.reports = action.payload || [];
      })
      .addCase(fetchCartonTransactionReport.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  }
});

export default cartonTransactionReportSlice.reducer;
