import React, { useState, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchBonusPointReport } from "../features/BonusPointReportSlice";
import { Button, Table } from "react-bootstrap";
import styles from "../../src/component/common/TableComponent/CustomTable.module.css";
import TablePagination from "./common/TablePagination/TablePagination";
import Common from "./common/common";
import Filters from "./common/Filters/Filters";
import { getStateData } from "../features/stateSlice";
import { getDistrictData } from "../features/districtSlice";
import { downloadFile } from "../features/DownloadFileSlice";

export default function BonusPointsReports() {
  const msLoggedUserId = localStorage.getItem("userId");
  const msLoggedRoleId = localStorage.getItem("roleId");
  const token = localStorage.getItem("logintoken");

  const { data, loading, error, totalRecords } = useSelector(
    (state) => state.bonusPointReport.reports
  );

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const totalPages = Math.ceil(totalRecords / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  // const displayedData = tableData.slice(startIndex, startIndex + itemsPerPage);
  const dispatch = useDispatch();


  const stateList = useSelector((state) => state.stateList.data);
  const districtList = useSelector((state) => state.districtList.data);


  console.log("this is districtList", districtList);

  // State variables for filters
  const [filters, setFilters] = useState({
    stateItemSelected: "",
    districtItemSelected: "",
    startDate: "",
    endDate: "",
    mobileNumber: "",
  });

  // Handle filter changes without triggering API calls
  const handleFilterChange = (name, e) => {
    //in case of date inputs, e will directly give value of dates, while in select inputs, e gives event

    console.log("this is name and e", name, e);
    // console.log("this is name and value", name, e.target.value)
    if (name === "stateItemSelected") {
      setFilters((prev) => ({
        ...prev,
        stateItemSelected: e.target.value,
        districtItemSelected: "", // Reset district when state changes
      }));
    } else if (name === "districtItemSelected") {
      setFilters((prev) => ({
        ...prev,
        districtItemSelected: e.target.value,
      }));
    } else if (name === "mobileNumber") {
      setFilters((prev) => ({
        ...prev,
        mobileNumber: e.target.value,
      }));
    }
    else {
      setFilters((prev) => {
        const updatedFilters = { ...prev, [name]: e };

        // Ensure startDate is not greater than endDate
        if (
          name === "startDate" &&
          updatedFilters.endDate &&
          updatedFilters.startDate > updatedFilters.endDate
        ) {
          updatedFilters.endDate = ""; // if we are selecting startDate that is greater than endDate
        }

        // Ensure endDate is not before startDate
        if (
          name === "endDate" &&
          updatedFilters.startDate &&
          updatedFilters.endDate < updatedFilters.startDate
        ) {
          updatedFilters.startDate = ""; // if we are selecting endDate that is snamller than startDate
        }

        return updatedFilters;
      });
    }

    // setCurrentPage(1); // Reset to first page when filters change
  };

  // Handle filter submit - only place where API is called
  const handleSubmit = useCallback(() => {
    dispatch(
      fetchBonusPointReport({
        msLoggedUserId,
        msLoggedRoleId,
        token,
        state: filters.stateItemSelected,
        district: filters.districtItemSelected,
        startDate: filters.startDate,
        endDate: filters.endDate,
        mobileNumber: filters.mobileNumber,
        startIndex: startIndex
      })
    );
  }, [dispatch, msLoggedUserId, msLoggedRoleId, token, filters]);

  useEffect(() => {
    dispatch(
      fetchBonusPointReport({
        msLoggedUserId,
        msLoggedRoleId,
        token,
        state: filters.stateItemSelected,
        district: filters.districtItemSelected,
        startDate: filters.startDate,
        endDate: filters.endDate,
        mobileNumber: filters.mobileNumber,
        startIndex: startIndex
      })
    );
  }, [dispatch, currentPage]);

  const tableData = data
    ? data.map((item, index) => ({
      "S.No.": (currentPage - 1) * itemsPerPage + index + 1,
      "User Name": item.userName,
      "User Role": item.userRole,
      State: item.userState,
      District: item.userDistrict,
      SubCategory: item.subCategory,
      Tier: item.tierName,
      Month: item.tierMonth,
      Year: item.tierYear,
      "Total Points": item.totalPoints || "N/A",
      "Total Scans": item.totalScan || "N/A",
      "Achieved Bonus Points": item.achievedBonusPoints || "N/A",
      "Bonus Percentage(%)": `${item.bonusPercentage}%`,
      "Points Created Date": item.pointsCreatedDate,
      "User Mobile": item.userMobile,
      "Bonus Remarks": item.bonusRemarks
    }))
    : [];

  //pagination state


  const handleDownload = () => {
    dispatch(
      downloadFile({
        url: "msTierUserBonusPointDownload",
        msLoggedUserId,
        msLoggedRoleId,
        token,
      })
    );
  };

  useEffect(() => {
    dispatch(
      fetchBonusPointReport({
        msLoggedUserId,
        msLoggedRoleId,
        token,
      })
    );
    dispatch(getStateData({ msLoggedRoleId, msLoggedUserId }));
  }, []);
  useEffect(() => {
    if (filters.stateItemSelected) {
      dispatch(
        getDistrictData({
          selectedStates: filters.stateItemSelected,
          msLoggedUserId,
          msLoggedRoleId,
        })
      );
    }
  }, [filters.stateItemSelected, dispatch]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="container" style={{ minHeight: "81.8vh" }}>
      <h2 className="text-capitalize fs-5 fst-italic mt-5">
        Bonus Point Report
      </h2>
      <div className="">
        <div>
          <div className="flex mb-4">
            <Filters
              filters={filters}
              startDate={filters.startDate}
              endDate={filters.endDate}
              setStartDate={(value) => handleFilterChange("startDate", value)}
              setEndDate={(value) => handleFilterChange("endDate", value)}
              stateItemSelected={filters.stateItemSelected}
              handleStateChange={(value) =>
                handleFilterChange("stateItemSelected", value)
              }
              districtItemSelected={filters.districtItemSelected}
              handleDistrictChange={(value) =>
                handleFilterChange("districtItemSelected", value)
              }
              stateList={stateList}
              districtList={districtList}
              handleSubmit={handleSubmit}
              msLoggedRoleId={msLoggedRoleId}
              filterPage="bonusPoints"
              handleDownload={handleDownload}
              showMobileNumber={true}
              handleMobileNumberChange={(value) =>
                handleFilterChange("mobileNumber", value)
              }
            />
          </div>
          <Common.CustomTable
            columns={[
              "User Name",
              "User Role",
              "User Mobile",
              "State",
              "District",
              "Month",
              "Year",
              "SubCategory",
              "Tier",
              "Total Points",
              "Total Scans",
              "Achieved Bonus Points",
              "Bonus Percentage(%)",
              "Points Created Date",
              "Bonus Remarks"
            ]}
            data={tableData}
            itemsPerPage={itemsPerPage}
            totalPages={totalPages}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            totalRecords={totalRecords}
          />
        </div>
      </div>
    </div>
  );
}
