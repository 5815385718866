import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import appConfig from "../../../AppConfig";
import CustomTable from "../../../component/common/TableComponent/TableComponent";
import { useDispatch, useSelector } from "react-redux";
import { downloadSectionData } from "../../../features/downloadSection";
const columns = [
  "S.No.",
  "Report Name",
  "File Name",
  "Status",
  "Created",
];
const TransactionReportTable = () => {
  const dispatch=useDispatch();
  const msLoggedUserId = localStorage.getItem("userId");
  const msLoggedRoleId = localStorage.getItem("roleId");
  const {data,totalRecords}=useSelector(state=>state.downloadSectionDataList.data)
  console.log("data>>>>>>",totalRecords)
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const totalPages = Math.ceil(totalRecords / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  // table data
  const tableData = data
    ? data.map((item, index) => ({
      "S.No.": (currentPage - 1) * 10 + index + 1,
      "Report Name": item.reportName,
      "File Name": item.filename,
      "Status": item.fileStatus==="Ready to download"?<a className="tablebtn" href={item.downloadLink} target="_blank">Download</a>:item.fileStatus==="In Progressing"?<button className="greybtn" disabled>In Progress</button>:null,
      "Created": item.createdDate,
      }))
    : [];

  const handleDownload = () =>{

  }

  useEffect(() => {
    dispatch(downloadSectionData({ startIndex, msLoggedUserId, msLoggedRoleId }))
  }, []);
  useEffect(() => {
    dispatch(downloadSectionData({ startIndex, msLoggedUserId, msLoggedRoleId }))
  }, [currentPage]);

  return (
    <div className="container" style={{ minHeight: "81.8vh" }}>
      <div className="row">
        <div className="col-md-12 mt-4">
            <h1 id="heading">Reports Download Section </h1>
        </div>
        <div className="col-md-12 mt-4">
        <CustomTable
          columns={columns}
          data={tableData}
          itemsPerPage={itemsPerPage}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          totalPages={totalPages}
          
        /></div>
      </div>
    </div>
  );
};

export default TransactionReportTable;
