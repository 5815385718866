import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import appConfig from "../AppConfig";

const initialState = {
  data: [],
  loading: false,
  error: "",
  message: "",
  status: "",
};

export const downloadSectionData = createAsyncThunk(
  "downloadSectionData",
  async ({msLoggedUserId,msLoggedRoleId,startIndex}, { rejectWithValue }) => {
    // ${appConfig.apiUrl}msGeneratedReportsSection?msLoggedUserId=11&msLoggedRoleId=1&start=0&length=10`
    const urls = `${appConfig.apiUrl}msGeneratedReportsSection?msLoggedUserId=${msLoggedUserId}&msLoggedRoleId=${msLoggedRoleId}&start=${startIndex}&length=10`;
    const headers = {
      "X-API-Key": appConfig.apiKey,
    };
    const response = await axios.get(urls, {
      headers: headers,
      // responseType: "blob",
    });
    try {
      const result = await response.data;
      console.log(result)
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const downloadSectionDetails = createSlice({
  name: "downloadSectionDetails",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(downloadSectionData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(downloadSectionData.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      // state.message = action.payload.message; // Update message in state
      // state.status = action.payload.status; // Update status in state
      // state.error = "";
    });
    builder.addCase(downloadSectionData.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = action.error.message;
    });
  },
});

export default downloadSectionDetails.reducer;
