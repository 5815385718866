function NotFound() {
  
  return (
    <div className="main-container">
    <h2>404 Page Not Found!</h2>
    </div>
  );
}

export default NotFound;
