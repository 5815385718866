import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Common } from "../../../component/common/common";
import { useDispatch, useSelector } from "react-redux";
import { getStateData } from "../../../features/stateSlice";
import { getDistrictData } from "../../../features/districtSlice";
import { getMemberListData } from "../../../features/memberListSlice";
import { updateMemberStatus } from "../../../features/updateMemberStatusSlice";
import Filters from "../../../component/common/Filters/Filters";
import { downloadMemberListData } from "../../../features/downloadMemberListSlice";
import { Spinner } from "react-bootstrap";
function MemberList() {
  const msLoggedUserId = localStorage.getItem("userId");
  const msLoggedRoleId = localStorage.getItem("roleId");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { data, totalRecords, message } = useSelector(
    (state) => state.memberList.data
  );
  const loading = useSelector((state) => state.memberList.loading);
  const stateList = useSelector((state) => state.stateList.data);
  const districtList = useSelector((state) => state.districtList.data);
  // date
  const [startDate, setStartDate] = useState(() => {
    const today = new Date();
    today.setDate(today.getDate() - 1); // Subtract 1 day
    return today;
  });
  const [endDate, setEndDate] = useState("");
  const formattedStartDate = startDate
    ? startDate.toLocaleDateString("en-CA", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      })
    : "";
  const formattedEndDate = endDate
    ? endDate.toLocaleDateString("en-CA", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      })
    : "";
  //
  const [stateItemSelected, setStateItemSelected] = useState("");
  const [districtItemSelected, setDistrictItemSelected] = useState("");

  // Modals
  const [showModal, setShowModal] = useState(false);
  const openModal = () => setShowModal(true);
  const closeModal = () => setShowModal(false);

  // saveuserStatus
  const [saveUserStatus, setSaveUserStatus] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [downloadingMap, setDownloadingMap] = useState('');


  // Add a state variable to store the userId to update
  const [userIdToUpdate, setUserIdToUpdate] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const handleCancel = () => {
    closeModal();
  };

  useEffect(() => {
    dispatch(
      getMemberListData({
        formattedStartDate,
        formattedEndDate,
        mobileNumber,
        districtItemSelected,
        stateItemSelected,
        startIndex,
        msLoggedUserId,
        msLoggedRoleId,
      })
    );
  }, [dispatch, currentPage]);

  const handleOk = async () => {
    closeModal();
    await dispatch(
      updateMemberStatus({ userIdToUpdate, saveUserStatus, msLoggedUserId })
    );
    await dispatch(
      getMemberListData({
        formattedStartDate,
        formattedEndDate,
        mobileNumber,
        districtItemSelected,
        stateItemSelected,
        startIndex,
        msLoggedUserId,
        msLoggedRoleId,
      })
    );
  };

  useEffect(() => {
    dispatch(getStateData({ msLoggedUserId, msLoggedRoleId }));
  }, []);
  useEffect(() => {
    dispatch(
      getDistrictData({
        msLoggedUserId,
        msLoggedRoleId,
        selectedState: stateItemSelected,
      })
    );
  }, [stateItemSelected]);

  const handleSubmit = () => {
    dispatch(
      getMemberListData({
        formattedStartDate,
        formattedEndDate,
        mobileNumber,
        districtItemSelected,
        stateItemSelected,
        startIndex,
        msLoggedUserId,
        msLoggedRoleId,
      })
    );
  };

  const handleUserToggle = (userId, userActive) => {
    openModal();
    const newStatus = userActive === "Yes" ? "Inactive" : "Active";
    setSaveUserStatus(newStatus);
    setUserIdToUpdate(userId);
  };

  const handleStateChange = (e) => {
    setStateItemSelected(e.target.value);
  };
  const handleDistrictChange = (e) => {
    setDistrictItemSelected(e.target.value);
  };

  const handleDownload = () => {
    
  setDownloadingMap(true);
    dispatch(
      downloadMemberListData({
        formattedStartDate,
        formattedEndDate,
        mobileNumber,
        districtItemSelected,
        stateItemSelected,
        startIndex,
        msLoggedUserId,
        msLoggedRoleId,
      })
    )
      .then((response) => {
        console.log("entered the memberlist response");
        const currentDate = new Date();
        const timestamp = currentDate.getTime();
        const formattedDate = currentDate.toISOString().slice(0, 10);
        const suggestedFileName = `member_lists_${formattedDate}_${timestamp}.csv`;
        const blob = new Blob([response.payload], { type: "text/csv" });

        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = suggestedFileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        
        setDownloadingMap(false);
      })
      .catch((error) => {
        console.error("Download error:", error);
        
        setDownloadingMap(false);
      });
  };
  const handleToEdit = (usrId) => {
    navigate(`/member/${usrId}`);
  };

  const itemsPerPage = 10;
  const totalPages = Math.ceil(totalRecords / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  // const endIndex = startIndex + itemsPerPage;

  // colums for table
  const columns = [
    "User Name",
    "User Code",
    "User Mobile Number",
    "User DOB",
    "User Email",
    "User Pincode",
    "User Address",
    "User District",
    "User City",
    "User State",
    "User Workshop",
    "User Pan",
    "User Role",
    "User Pan Approved Status",
    "User Pan Approved By",
    "User Pan Approved Date",
    "User Creation Date",
    "Th Name",
    "Th Mobile Number",
    "Th Email",
    "Sh Name",
    "Sh Mobile Number",
    "Sh Email",
    "Rh Name",
    "Rh Mobile Number",
    "Rh Email",
    "Total Earned Points",
    "Total Burned Points",
    "Total Balance Points"
  ];

  const columnsToDisplay =
    msLoggedRoleId === "1" ? ["Status & Action", ...columns] : columns;
  const newColumn = ["S.No.", ...columnsToDisplay];
  const tableData = data
    ? data.map((item, index) => {
        const formatDate = (date) => {
          if (!date) return "";

          const d = new Date(date);
          const year = d.getFullYear();
          const month = String(d.getMonth() + 1).padStart(2, "0");
          const day = String(d.getDate()).padStart(2, "0");

          return `${day}-${month}-${year}`;
        };

        return {
          "S.No.": (currentPage - 1) * 10 + index + 1,
          "Status & Action": (
            <>
              <button
                className={
                  item.usr_active === "Yes"
                    ? "btn btn-primary btn-sm w-100"
                    : "btn btn-danger btn-sm w-100"
                }
                onClick={() => handleUserToggle(item.userId, item.usr_active)}
              >
                {item.usr_active === "Yes" ? "Deactivate" : "Activate"}
              </button>
              <button
                className="btn btn-success btn-sm w-100 mt-2"
                onClick={() => handleToEdit(item.userId)}
              >
                Edit
              </button>
            </>
          ),
          "User Name": item.name,
          "User Code": item.userCode,
          "User Mobile Number": item.mobileno,
          "User DOB": formatDate(item.userDob),
          "User Email": item.userEmail,
          "User Pincode": item.userPincode,
          "User Address": item.userAddress,
          "User District": item.userDistrict,
          "User City": item.userCity,
          "User State": item.userState,
          "User Workshop": item.userWorkshop,
          "User Pan": item.userPan,
          "User Role": item.userRole,
          "User Pan Approved Status": item.panStatus,
          "User Pan Approved By": item.pan_approved_by,
          "User Pan Approved Date": formatDate(item.pan_approved_date),
          "User Creation Date": formatDate(item.userCreatedOn),
          "Th Name": item.thName,
          "Th Mobile Number": item.thMobile,
          "Th Email": item.thEmail,
          "Sh Name": item.shName,
          "Sh Mobile Number": item.shMobile,
          "Sh Email": item.shEmail,
          "Rh Name": item.rhName,
          "Rh Mobile Number": item.rhMobile,
          "Rh Email": item.rhEmail,
          "Total Earned Points": item.totalCredit,
          "Total Burned Points": item.totalDebit,
          "Total Balance Points": item.totalBalance
        };
      })
    : [];
  return (
    <div className="container" style={{ minHeight: "81.8vh" }}>
      <div className="row">
        <div className="col-12 mt-5">
          <h2 className="text-capitalize fs-5 fst-italic">Member Lists</h2>
        </div>
        <div className="col-md-12">
          <Filters
            startDate={startDate}
            endDate={endDate}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            mobileNumber={mobileNumber}
            setMobileNumber={setMobileNumber}
            stateItemSelected={stateItemSelected}
            handleStateChange={handleStateChange}
            stateList={stateList}
            districtList={districtList}
            districtItemSelected={districtItemSelected}
            handleDistrictChange={handleDistrictChange}
            handleSubmit={handleSubmit}
            handleDownload={handleDownload}
            msLoggedRoleId={msLoggedRoleId}
          />
        </div>
        {loading ? (
          <div className="d-flex justify-content-center mt-4">
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        ) : (
          <div className="col-md-12 my-4">
            <Common.CustomTable
              data={tableData}
              columns={newColumn}
              itemsPerPage={itemsPerPage}
              totalPages={totalPages}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              totalRecords={totalRecords}
              message={message}
            />
          </div>
        )}
        <div className="col-md-12 mt-4">
          <Common.Modal
            showModal={showModal}
            onClose={closeModal}
            body={
              saveUserStatus !== "Active"
                ? "Are you sure want to deactivate the member?"
                : "Are you sure want to activate the member?"
            }
            onCancel={handleCancel}
            onOk={handleOk}
          />
        </div>
      </div>
    </div>
  );
}

export default MemberList;
