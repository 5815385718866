import React, { useState, useEffect } from "react";
import axios from "axios";
import appConfig from "../../AppConfig";
import Spinner from 'react-bootstrap/Spinner';
import Button from "../../component/Button/Button";

function MonthlyEarnedReports() {
  const [months, setMonths] = useState([]);
  const [years, setYears] = useState([]);
  const [scanedDone, setScanedDone] = useState('');
  const [scanedCount, setScanCount] = useState('');
  const [orderPlaced, setOrderPlaced] = useState('');
  const [redemptionData, setRedemptionData] = useState('');
  const [loading, setLoading] = useState(false); 
  

  // Get current month and year
  const currentDate = new Date();
  const currentMonth = currentDate.toLocaleString('default', { month: 'long' });
  const currentYear = currentDate.getFullYear();
  const [selectedMonth, setSelectedMonth] = useState(currentMonth);
  const [selectedYear, setSelectedYear] = useState(currentYear);

  useEffect(() => {
    const fetchDropdownData = async () => {
      try {
        const monthResponse = await axios.get(`${appConfig.apiUrl}msMonthDropDown`, {
          headers: { 'x-api-key': appConfig.apiKey },
        });
        setMonths(monthResponse.data.data.months);
        if (!monthResponse.data.data.months.includes(currentMonth)) {
          setSelectedMonth(monthResponse.data.data.months[0]);
        }

        const yearResponse = await axios.get(`${appConfig.apiUrl}msYearDropDown`, {
          headers: { 'x-api-key': appConfig.apiKey },
        });
        setYears(yearResponse.data.data.years);
        if (!yearResponse.data.data.years.includes(currentYear)) {
          setSelectedYear(yearResponse.data.data.years[0]);
        }
      } catch (error) {
        console.error("Error fetching dropdown data:", error);
      }
    };

    fetchDropdownData();
  }, [currentMonth, currentYear]);

  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value);
  };

  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const [earnedResponse, scanResponse, orderResponse, redemptionResponse] = await Promise.all([
        axios.get(`${appConfig.apiUrl}msDashBoardTotalEarnedPoints`, {
          headers: { 'x-api-key': appConfig.apiKey },
          params: {
            monthFilter: selectedMonth,
            YearFilter: selectedYear,
            msLoggedUserId: 11,
            msLoggedRoleId: 1,
          },
        }),
        axios.get(`${appConfig.apiUrl}msDashBoardTotalScannedTransactionCount`, {
          headers: { 'x-api-key': appConfig.apiKey },
          params: {
            monthFilter: selectedMonth,
            YearFilter: selectedYear,
            msLoggedUserId: 11,
            msLoggedRoleId: 1,
          },
        }),
        axios.get(`${appConfig.apiUrl}msDashBoardTotalOrdersPlacedCount`, {
          headers: { 'x-api-key': appConfig.apiKey },
          params: {
            monthFilter: selectedMonth,
            YearFilter: selectedYear,
            msLoggedUserId: 11,
            msLoggedRoleId: 1,
          },
        }),
        axios.get(`${appConfig.apiUrl}msDashBoardTotalValueRedemption`, {
          headers: { 'x-api-key': appConfig.apiKey },
          params: {
            monthFilter: selectedMonth,
            YearFilter: selectedYear,
            msLoggedUserId: 11,
            msLoggedRoleId: 1,
          },
        }),
      ]);

      setScanedDone(earnedResponse.data.data.totalEarned);
      setScanCount(scanResponse.data.data.totalScanCount);
      setOrderPlaced(orderResponse.data.data.totalOrders);
      setRedemptionData(redemptionResponse.data.data.totalRedeemValue);
    } catch (error) {
      console.error("Error fetching report data:", error);
    }finally {
      setLoading(false);  
    }
  };

  return (
    <>
      <h2 className="heading-motul">Monthly Earn and Burn Reports</h2>
      <div className="fh-motul-filter">
        <select value={selectedMonth} onChange={handleMonthChange}>
          {months.map((month, index) => (
            <option key={index} value={month}>
              {month}
            </option>
          ))}
        </select>
        <select value={selectedYear} onChange={handleYearChange}>
          {years.map((year, index) => (
            <option key={index} value={year}>
              {year}
            </option>
          ))}
        </select>
        <Button
          variant={"submit"}
          onClick={handleSubmit}
          disabled={loading}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '0.5rem 1rem',
            backgroundColor: loading ? '#a3b9d5' : '',
          }}
        >
          {loading ? (
            <>
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                style={{
                  marginRight: '8px', // Space between spinner and text
                  color: '#003366',
                  borderWidth: '0.2em',
                }}
              />
              <span>Submit</span>
            </>
          ) : (
            "Submit"
          )}
        </Button>
      </div>

      <div className="fh-motul">
        <div className="fh-div">
          <h3>Total Scans Done</h3>
          <p>{scanedCount || 0}</p>
        </div>
        <div className="fh-div">
          <h3>Total Transaction Value (₹)</h3>
          <p>{scanedDone || 0}</p>
        </div>
        <div className="fh-div">
          <h3>Total Orders Placed</h3>
          <p>{orderPlaced || 0}</p>
        </div>
        <div className="fh-div">
          <h3>Total Value of Redemption Done(₹)</h3>
          <p>{redemptionData || 0.00}</p>
        </div>
      </div>
    </>
  );
}

export default MonthlyEarnedReports;
