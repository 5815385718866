import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import appConfig from "../AppConfig";

const initialState = {
    loading: false,
    data: [],
    error: "",
    message: "",
    status: "",
};

export const getcampaignPageData = createAsyncThunk(
    "getcampaignPageData",
    async (
        data, { rejectWithValue }
    ) => {
        const url = `${appConfig.apiUrl}msAddCampaignLuckDrawScheme`;
        
        
        const headers = {
            "X-API-Key": appConfig.apiKey,
            "Content-Type": "application/x-www-form-urlencoded",
        };
        

        try { 
            const response = await axios.post(url, data, { headers: headers });
            const result = await response.data;
            console.log("campaign result", result);
            return result;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const viewCampaignPageDetails = createSlice({
    name: "viewCampaignPageDetails",
    initialState,
    extraReducers: (builder) => {
        builder.addCase(getcampaignPageData.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getcampaignPageData.fulfilled, (state, action) => {
            state.loading = false;
            state.data.push(action.payload.data);
            state.message = action.payload.message;
            state.status = action.payload.status; 
            state.error = "";
        });
        builder.addCase(getcampaignPageData.rejected, (state, action) => {
            state.loading = false;
            state.data = [];
            state.error = action.error.message;
        });
    },
});

export default viewCampaignPageDetails.reducer;
