import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { Common } from "../../../component/common/common";
import SalesMap from "../../../assets/pdf/Sample_Sales_Mapping.xlsx";
import { useDispatch, useSelector } from "react-redux";
import { getmsMapUniqueId } from "../../../features/msMapUniqueCodeSlice";
import { uploadSalesMapping } from "../../../features/uploadSalesMappingSlice";
import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap CSS

function USM() {
  const msLoggedUserId = localStorage.getItem("userId");
  const dispatch = useDispatch();
  const uniqueCodeId = useSelector((state) => state.msMapUniqueCodeList.data);
  const data = useSelector(
    (state) => state.msMapuploadSalesMappingList.data[0]
  );
  const message = useSelector(
    (state) => state.msMapuploadSalesMappingList.message
  );
  const status = useSelector(
    (state) => state.msMapuploadSalesMappingList.status
  );
  const validCount = data && data.validCount;
  const inValidCount = data && data.invalidCount;

  const navigate = useNavigate();
  const [selectedFile, setSelectedFile] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false); // State for loading indicator

  const fileInputRef = useRef(null);

  useEffect(() => {
    dispatch(getmsMapUniqueId());
  }, [dispatch]);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const handleSubmit = async () => {
    setLoading(true); // Start loading indicator
    const userFormData = new FormData();
    userFormData.append("uniqueId", uniqueCodeId);
    userFormData.append("mappingfile", selectedFile);
    userFormData.append("uploadedBy", msLoggedUserId);
    dispatch(uploadSalesMapping(userFormData));
    setSubmitted(true);
  };

  useEffect(() => {
    if (submitted && status !== undefined && message) {
      setLoading(false); // Stop loading indicator
      if (status) {
        Swal.fire({
          title: message,
          icon: "success",
          confirmButtonText: "Okay",
        });
        setTimeout(() => {
          navigate(
            `/mapped?Id=${uniqueCodeId}&valid=${validCount}&invalid=${inValidCount}`
          );
        }, 2000);
      } else {
        Swal.fire({
          title: message,
          icon: "error",
          confirmButtonText: "Okay",
        });
      }
      setSubmitted(false);
    }
  }, [message, status, submitted]);

  return (
    <>
      <div className="container mt-5" style={{ minHeight: "81.8vh" }}>
        <div className="row">
          <div className="col-md-12">
            <h3 className="text-capitalize fs-5 fst-italic">
              Upload Sales Mapping
            </h3>
          </div>
          <div className="col-md-6">
            <div className="row">
              <div className="col-sm-12 mt-3">
                <Common.FileInput
                  label="File Upload"
                  onChange={handleFileChange}
                  inputRef={fileInputRef}
                />
              </div>
              <div className="col-sm-12 mt-3">
                <a href={SalesMap} className="text-dark">
                  Sample File
                </a>
              </div>
              <div className="col-sm-12 mt-3">
                <button
                  onClick={handleSubmit}
                  className={
                    selectedFile ? "btn btn-danger" : "btn btn-secondary"
                  }
                  disabled={selectedFile ? false : true || loading} // Disable when no file selected or during loading
                >
                  {loading && (
                    <span
                      className="spinner-border spinner-border-sm me-2"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  )}
                  Submit
                </button>
              </div>
              <div className="col-sm-12 mt-3 mb-3">
                <p className="text-danger">
                  Note: Please upload less than 5MB File
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default USM;
