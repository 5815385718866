import React from 'react';
import { Select, Space } from 'antd';
const options = [];

const MultiSelect = ({options,onChange,value, className, defaultValue}) => (
  <Space
    style={{
      width: '100%',
    }}
    direction="vertical"
  >
    <Select
      mode="multiple"
      className={className? className : ""}
      value={value}
      allowClear
      style={{
        width: '100%',
      }}
      placeholder="Please select"
      defaultValue={defaultValue ? defaultValue : ""}
      onChange={onChange}
      filterOption={(input, option) =>
        option.label.toLowerCase().includes(input.toLowerCase())
      }
      options={options}
    />
  </Space>
);
export default MultiSelect;