import React from 'react';
import { DatePicker, Space } from 'antd';
const { RangePicker } = DatePicker;
const DateRangePicker = ({value, onChange, disabledDate}) => (
  <Space direction="vertical" size={12}>
    <RangePicker value={value} onChange={onChange} disabledDate={disabledDate ? disabledDate : ""}/>
    
    
  </Space>
);
export default DateRangePicker;