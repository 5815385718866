// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Footer_footerContainer__5BAzK {
  /* height: 78px; */
  background-color: #000;
  /* display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center; */
}

.Footer_leftContentLinks__8s\\+TK {
  /* border: 1px solid #f00; */
  width: 30%;
  display: flex;
  justify-content: space-evenly;
}

.Footer_links__eApwj {
  color: #ffa500;
  text-decoration: none;
}
.Footer_rightContent__6271o {
  color: #fff;
  margin: 0px;
  padding: 0px;
}

.Footer_version__bX8EM {
  font-size: 0.5rem;
  color: #fff;
}
`, "",{"version":3,"sources":["webpack://./src/screens/common/Footer/Footer.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,sBAAsB;EACtB;;;wBAGsB;AACxB;;AAEA;EACE,4BAA4B;EAC5B,UAAU;EACV,aAAa;EACb,6BAA6B;AAC/B;;AAEA;EACE,cAAc;EACd,qBAAqB;AACvB;AACA;EACE,WAAW;EACX,WAAW;EACX,YAAY;AACd;;AAEA;EACE,iBAAiB;EACjB,WAAW;AACb","sourcesContent":[".footerContainer {\n  /* height: 78px; */\n  background-color: #000;\n  /* display: flex;\n  flex-direction: row;\n  justify-content: space-around;\n  align-items: center; */\n}\n\n.leftContentLinks {\n  /* border: 1px solid #f00; */\n  width: 30%;\n  display: flex;\n  justify-content: space-evenly;\n}\n\n.links {\n  color: #ffa500;\n  text-decoration: none;\n}\n.rightContent {\n  color: #fff;\n  margin: 0px;\n  padding: 0px;\n}\n\n.version {\n  font-size: 0.5rem;\n  color: #fff;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footerContainer": `Footer_footerContainer__5BAzK`,
	"leftContentLinks": `Footer_leftContentLinks__8s+TK`,
	"links": `Footer_links__eApwj`,
	"rightContent": `Footer_rightContent__6271o`,
	"version": `Footer_version__bX8EM`
};
export default ___CSS_LOADER_EXPORT___;
