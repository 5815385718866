import React from "react";
import styles from "./SelectInput.module.css";

const SelectInputId = ({
  label,
  options,
  value,
  onChange,
  labelKey,
  valueKey,
}) => {
  // console.log("optionsComp>>>>>>>", options);
  return (
    <div className={styles.selectInputContainer}>
      <label className={styles.label}>{label}</label>
      <select value={value} onChange={onChange} className={styles.selectInput}>
        <option value="">Choose an option</option>
        {options.map((option) => (
          <option key={option[valueKey]} value={option[valueKey]}>
            {option[labelKey]}
          </option>
        ))}
      </select>
    </div>
  );
};
export default SelectInputId;
