import { configureStore } from "@reduxjs/toolkit";
import dashboardReducer from "../features/dashboardSlice";
import stateReducer from "../features/stateSlice";
import districtReducer from "../features/districtSlice";
import groupReducer from "../features/groupSlice";
import brandReducer from "../features/brandSlice";
import subcategoryReducer from "../features/subcategorySlice";
import productReducer from "../features/productSlice";
import packsizeReducer from "../features/packsizeSlice";
import generatecodeReducer from "../features/generatecodeSlice";
import codeGeneratedReducer from "../features/codeGenerationReportSlice";
import couponReportReducer from "../features/couponReportSlice";
import viewSalesMappingReducer from "../features/viewSalesMappingSlice";
import memberListReducer from "../features/memberListSlice";
import singleUserReducer from "../features/singleUserSlice";
import redemptionReducer from "../features/redemptionSlice";
import transactionReducer from "../features/transactionSlice";
import viewSalesTeamReducer from "../features/viewSalesTeamSlice";
import updateMemberStatusReducer from "../features/updateMemberStatusSlice";
import viewKnowledgeCornerReducer from "../features/viewKnowledgeCornerSlice";
import verifyCodeReducer from "../features/verifyCodeSlice";
import addSalesMemberReducer from "../features/addSalesMemberSlice";
import uploadKnowledgeCornerReducer from "../features/uploadKnowledgeCornerSlice";
import uploadMemberReducer from "../features/uploadMemberSilce";
import uniqueIdReducer from "../features/uniqueIdSlice";
import memberValidInvalidReducer from "../features/memberValidInvalidSlice";
import msMapUniqueCodeReducer from "../features/msMapUniqueCodeSlice";
import uploadSalesMappingReducer from "../features/uploadSalesMappingSlice";
import msMappingValidInvalidReducer from "../features/msMappingValidInvalidSlice";
import updateSingleUserReducer from "../features/updateSingleUserSlice";
import loginReducer from "../features/loginSlice";
import logoutReducer from "../features/logoutSlice";
import viscosityReducer from "../features/viscositySlice";
import clientIpReducer from "../features/clientIpSlice";
import loginHistoryReducer from "../features/loginHistorySlice";
import historicTransactionReducer from "../features/historicTransactionSlice";
import historicRedemptionReducer from "../features/historicRedemptionSlice";
import downLoadSampleFileRepository from "../features/downLoadSampleFileSlice";
import msSalesUniqueCodeReducer from "../features/msSalesUniqueCodeSlice";
import bulkUploadSaleReducer from "../features/bulkUploadSalesSlice";
import salesValidInvalidReducer from "../features/salesValidInvalidSlice";
import navReducer from "../features/navSlice";
import downLoadMemberListReducer from "../features/downloadMemberListSlice";
import codeGenerationReportDownloadReducer from "../features/codeGenerationReportDownloadSlice";
import couponReportDownloadReducer from "../features/couponReportDownloadSlice";
import downloadViewSalesMappingReportsReducer from "../features/viewSalesMappingReportDownloadSlice";
import downloadRedemptionReportReducer from "../features/downloadRedemptionReportSlice";
import downloadTransactionReportReducer from "../features/downloadTransactionReportSlice";
import viewProfileReducer from "../features/viewProfileSlice";
import userPointsReducer from "../features/userPointsSlice";
import userCallLogsReducer from "../features/userCallLogsSlice";
import userTransactionReducer from "../features/userTransactionSlice";
import userRedeemedOrderReducer from "../features/userRedeemedOrderSlice";
import userCallStatusReducer from "../features/userCallStatusSlice";
import userAppLinkReducer from "../features/userAppLinkSlice";
import callUpdateReducer from "../features/callUpdateSlice";
import enquiryTypeReducer from "../features/enquiryTypeSlice";
import downloadSectionReducer from "../features/downloadSection";
import SubCatDropDownReducer from "../features/SubCatDropDownSlice";
import viewCampaignPageReducer from "../features/campaignPageSlice";
import getcampaignPageDataReducer from "../features/campaignPageSlice";
import viewCampaignLuckyDrawDetailsReducer from "../features/viewCampaignLuckDrawSchemeSlice";
import detailRowCampaignSchemeDataReducer from "../features/DetailRowCampaignSchemeSlice";
import campaignUploadUserDetailsDataReducer from "../features/CampaignUploadUserDetailsSlice";
import updateStatusDetailsReducer from "../features/UpdateStatusCampaignSlice";
import userLanguageListReducer from "../features/oneviewLanguageListSlice";


export const store = configureStore({
  reducer: {
    loginList: loginReducer,
    logoutList: logoutReducer,
    navList: navReducer,
    dashboard: dashboardReducer,
    stateList: stateReducer,
    districtList: districtReducer,
    groupList: groupReducer,
    brandList: brandReducer,
    subCategoryList: subcategoryReducer,
    productList: productReducer,
    packSizeList: packsizeReducer,
    viscosityList: viscosityReducer,
    generatedCode: generatecodeReducer,
    codeGeneratedReport: codeGeneratedReducer,
    codeGeneratedReportDownload: codeGenerationReportDownloadReducer,
    couponReport: couponReportReducer,
    couponReportDownload: couponReportDownloadReducer,
    viewSalesMapping: viewSalesMappingReducer,
    downloadViewSalesMappingReport: downloadViewSalesMappingReportsReducer,
    memberList: memberListReducer,
    downloadMemberList: downLoadMemberListReducer,
    singleUserList: singleUserReducer,
    updateSingleUserList: updateSingleUserReducer,
    redemptionlist: redemptionReducer,
    downloadRedemptionReport: downloadRedemptionReportReducer,
    historicRedemptionList: historicRedemptionReducer,
    transactionList: transactionReducer,
    downloadTransactionReport: downloadTransactionReportReducer,
    historicTransactionList: historicTransactionReducer,
    viewSalesTeamList: viewSalesTeamReducer,
    updateMemberStatus: updateMemberStatusReducer,
    viewKnowledgeCornerList: viewKnowledgeCornerReducer,
    verifyCodeList: verifyCodeReducer,
    addSalesMemberList: addSalesMemberReducer,
    uploadKnowledgeCornerList: uploadKnowledgeCornerReducer,
    uploadMemberList: uploadMemberReducer,
    uniqueIdList: uniqueIdReducer,
    memberValidInvalidList: memberValidInvalidReducer,
    msMapUniqueCodeList: msMapUniqueCodeReducer,
    msMapuploadSalesMappingList: uploadSalesMappingReducer,
    msSalesMappingValidInvalidList: msMappingValidInvalidReducer,
    clientInfoIp: clientIpReducer,
    loginHistoryList: loginHistoryReducer,
    downloadSampleList: downLoadSampleFileRepository,
    msSalesUniqueIdList: msSalesUniqueCodeReducer,
    bulkUploadSalesMemberList: bulkUploadSaleReducer,
    salesValidInvalidList: salesValidInvalidReducer,
    viewProfileList: viewProfileReducer,
    userPointsList: userPointsReducer,
    userCallLogsList: userCallLogsReducer,
    userTransactionList: userTransactionReducer,
    userRedeemedOrderList: userRedeemedOrderReducer,
    userCallStatusList: userCallStatusReducer,
    userAppLinkList: userAppLinkReducer,
    callUpdateList: callUpdateReducer,
    enquiryTypeList: enquiryTypeReducer,
    downloadSectionDataList: downloadSectionReducer,
    SubCatDropDown: SubCatDropDownReducer,
    viewCampaignPage: viewCampaignPageReducer,
    campaignPageData: getcampaignPageDataReducer,
    viewCampaignLuckyDrawDetails: viewCampaignLuckyDrawDetailsReducer,
    detailRowCampaignSchemeData: detailRowCampaignSchemeDataReducer,
    campaignUploadUserDetailsData: campaignUploadUserDetailsDataReducer,
    updateStatusDetails: updateStatusDetailsReducer,
    userLanguageList: userLanguageListReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
