import React, { useState, useEffect } from "react";
import { Common } from "../../component/common/common";
function Settings() {
  const [username, setUserName] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");

  useEffect(() => {
    const email = localStorage.getItem("email");
    const phone = localStorage.getItem("mobile");
    const name = localStorage.getItem("userName");
    setUserName(name);
    setMobile(phone);
    setEmail(email);
  }, []);

  return (
    <div className="container" style={{ minHeight: "81.8vh" }}>
      <div className="row">
        <div className="col-md-12 mt-5">
          <h2 className="text-capitalize fs-5 fst-italic">My Profile</h2>
        </div>
        <div className="col-md-4">
          <div className="row">
            <div className="col-sm-12 mt-3">
              <Common.TextInput
                label="Full Name"
                value={username}
                readOnly={true}
              />
            </div>
            <div className="col-sm-12 mt-2">
              <Common.TextInput label="Email" value={email} readOnly={true} />
            </div>
            <div className="col-sm-12 mt-2 mb-4">
              <Common.TextInput
                label="Mobile Number"
                value={mobile}
                readOnly={true}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Settings;
