import React, { useEffect, useState } from "react";
import CommonContainer from "./CommonContainer/CommonContainer";
import Common from "../../component/common/common";
import Swal from "sweetalert2";
import CommonFilter from "./CommonFilter/CommonFilter";
import CallUpdateForm from "./CallUpdateForm/CallUpdateForm";
import { useDispatch, useSelector } from "react-redux";
import { getviewProfileData } from "../../features/viewProfileSlice";
import ProfileDetails from "./ProfileDetails/ProfileDetails";
import UserPoints from "./UserPoints/UserPoints";
import { getUserPointsData } from "../../features/userPointsSlice";
import { getUserCallLogsData } from "../../features/userCallLogsSlice";
import { getUserTransactionData } from "../../features/userTransactionSlice";
import { getUserRedeemedOrderData } from "../../features/userRedeemedOrderSlice";
import CallLogsFilter from "./CallLogsFilter/CallLogsFilter";
import { getUserCallStatusData } from "../../features/userCallStatusSlice";
import PointStatementFilter from "./PointStatementFilter/PointStatementFilter";
import { userSentAppLinkData } from "../../features/userAppLinkSlice";
import { callUpdateData } from "../../features/callUpdateSlice";
import { getEnquiryTypeData } from "../../features/enquiryTypeSlice";
import { getSubCatDropDownData } from "../../features/SubCatDropDownSlice";
import { getLanguageListData } from "../../features/oneviewLanguageListSlice";
import { updateUserLanguage } from "../../features/oneviewUpdateLanguageSlice";


const userCallLogscolumns = [
  "S.No.",
  "Call Status",
  "Enquiry Type",
  "Comments",
  "Call Type",
  "Called By",
  "Called On",
];

const pointsStatementColumns = [
  "S.No.",
  "User Name",
  "User Code",
  "Group Name",
  "Sub Category",
  "Brand",
  "Product Name",
  "Coupon Code",
  "Coupon Points",
  "Bonus Points",
  "Total Points",
  "Bonus Points Remarks",
  "Check in Date And Time",
];
const orderReportColumns = [
  "S.No.",
  "Order No.",
  "Product Name",
  "Net Payable/Points Redeemed",
  "Order Tax",
  "Total Redeemed Points",
  // "Gross Burn",
  "Pan Available",
  "Order Created Date",
  "User Mobile No.",
  "Order Delivery Date",
  "Order Status",
  "Order Status Comments",
];

const callTypeList = [
  { id: 1, callTypeName: "Inbound" },
  { id: 2, callTypeName: "Outbound" },
];

const OneView = () => {
  const msLoggedUserId = localStorage.getItem("userId");
  const msLoggedRoleId = localStorage.getItem("roleId");
  const dispatch = useDispatch();

  const [selectedUser_Type, setSelectedUser_Type] = useState("");
  const [langID, setLangID] = useState("");

  console.log("language id identidied,", langID);


  useEffect(() => {
    dispatch(getLanguageListData());
  }, [dispatch]);


  const {
    data: profileData,
    status: profileStatus,
    message: profileMessage,
  } = useSelector((state) => state.viewProfileList.data);


  const { data: pointsData, status: pointsStatus } = useSelector(
    (state) => state.userPointsList.data
  );
  const {
    data: userCallLogsData,
    status: userCallLogsStatus,
    totalRecords,
    message,
  } = useSelector((state) => state.userCallLogsList.data);

  const {
    data: userTransactionData,
    status: userTransactionStatus,
    totalRecords: userTransactiontotalRecords,
    message: userTransactionmessage,
  } = useSelector((state) => state.userTransactionList.data);

  // Redeemed Order
  const {
    data: userRedeemedOrderData,
    status: userRedeemedOrderStatus,
    totalRecords: userRedeemedOrdertotalRecords,
    message: userRedeemedOrdermessage,
  } = useSelector((state) => state.userRedeemedOrderList.data);

  // userCallstatus list
  const { data: userCallStatusList } = useSelector(
    (state) => state.userCallStatusList.data
  );

  //language list
  const { data: userLanguageList } = useSelector(
    (state) => state.userLanguageList.data
  );

  // user enquiry Type
  const { data: enquiryList } = useSelector(
    (state) => state.enquiryTypeList.data
  );

  const {
    data: appLinkData,
    message: appLinkMessage,
    status: appLinkStatus,
  } = useSelector((state) => state.userAppLinkList.data);

  const { message: callUpdateMessage, status: callUpdateStatus } = useSelector(
    (state) => state.callUpdateList.data
  );

  const SubCatDropDownList = useSelector((state) => state.SubCatDropDown.data);
  console.log("SubCatDropDownList", SubCatDropDownList);
  // Modals
  const [showModal, setShowModal] = useState(false);
  const [showProfileModal, setShowProfileModal] = useState(false);
  const [formData, setFormData] = useState({
    mobileNumber: "",
    comments: "",
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [currentUserTransactionPage, setCurrentUserTransactionPage] =
    useState(1);

  const [currentUserRedeemedOrderPage, setCurrentUserRedeemedOrderPage] =
    useState(1);

  const [callStatusItemSelected, setCallStatusItemSelected] = useState("");

  const [couponCode, setCouponCode] = useState("");
  const [orderNumber, setOrderNumber] = useState("");
  const [selectSubcategory, setSubcategorytype] = useState("");


  const handleUser_TypeChange = (selectedValue) => {
    setSelectedUser_Type(selectedValue); // Directly use the selected value

    const selectedOption = userLanguageList.find(
      (option) => option.lang_name === selectedValue // Match based on the label (or value property)
    );

    if (selectedOption) {
      console.log("Selected Option ID:", selectedOption.langId); // Log or use the ID
      setLangID(selectedOption.langId);
    }

  };


  // callLogs Filter date
  const [startDate, setStartDate] = useState(() => {
    const today = new Date();
    // Subtract 7 days from the current date
    today.setDate(today.getDate() - 7);
    return today;
  });
  const [endDate, setEndDate] = useState("");

  // pointsStatementFilter
  const [startPointStatementDate, setstartPointStatementDate] = useState(() => {
    const today = new Date();
    // Subtract 7 days from the current date
    today.setDate(today.getDate() - 7);
    return today;
  });
  const [endPointStatementDate, setEndPointStatementDate] = useState("");

  // Order Report
  const [orderReportStartDate, setOrderReportStartDate] = useState(() => {
    const today = new Date();
    // Subtract 7 days from the current date
    today.setDate(today.getDate() - 7);
    return today;
  });
  const [orderReportEndDate, setOrderReportEndDate] = useState("");
  // const [showModal, setShowModal] = useState(false);

  // call Update form
  const [callStatusItemSelectedInput, setCallStatusItemSelectedInput] = useState("");
  const [callTypeItemSelected, setCallTypeItemSelected] = useState("");
  const [enquiryTypeItemSelected, setEnquiryTypeItemSelected] = useState("");
  const [isMobileNumberValid, setIsMobileNumberValid] = useState(true);
  const [isLoadingData, setIsLoadingData] = useState(false);


  const formattedStartDate = startDate
    ? startDate.toLocaleDateString("en-CA", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    })
    : "";

  const formattedEndDate = endDate
    ? endDate.toLocaleDateString("en-CA", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    })
    : "";

  // pointsStatementFilter formatted date
  const formattedStartPointStatementDate = startPointStatementDate
    ? startPointStatementDate.toLocaleDateString("en-CA", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    })
    : "";
  const formattedPointStatementEndDate = endPointStatementDate
    ? endPointStatementDate.toLocaleDateString("en-CA", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    })
    : "";

  // pointsStatementFilter formatted date
  const formattedOrderReportStartDate = orderReportStartDate
    ? orderReportStartDate.toLocaleDateString("en-CA", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    })
    : "";

  const formattedOrderReportEndDate = orderReportEndDate
    ? orderReportEndDate.toLocaleDateString("en-CA", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    })
    : "";

  //call log
  const itemsPerPage = 10;
  const totalPages = Math.ceil(totalRecords / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  // transaction
  const itemsPerPageuserTransaction = 10;
  const totalPagesUserTransaction = Math.ceil(
    userTransactiontotalRecords / itemsPerPageuserTransaction
  );
  const startIndexUserTransaction =
    (currentUserTransactionPage - 1) * itemsPerPageuserTransaction;
  const endIndexUserTransaction =
    startIndexUserTransaction + itemsPerPageuserTransaction;

  // user Redeemed order
  const itemsPerPageuserRedeemedOrder = 10;
  const totalPagesUserRedeemedOrder = Math.ceil(
    userRedeemedOrdertotalRecords / itemsPerPageuserRedeemedOrder
  );
  const startIndexUserRedeemedOrder =
    (currentUserRedeemedOrderPage - 1) * itemsPerPageuserRedeemedOrder;
  console.log("startIndexUserRedeemedOrder startIndexUserRedeemedOrder", startIndexUserRedeemedOrder);
  const endIndexUserRedeemedOrder =
    startIndexUserRedeemedOrder + itemsPerPageuserRedeemedOrder;

  const openModal = () => setShowModal(true);
  const closeModal = () => setShowModal(false);
  const openProfileModal = () => setShowProfileModal(true);
  const closeProfileModal = () => setShowProfileModal(false);
  const handleCancel = () => {
    closeModal();
  };
  const mobileNumberPattern = /^[0-9]{0,10}$/;

  // const handleInputChange = (e) => {
  //   const { name, value } = e.target;
  //   setFormData({
  //     ...formData,
  //     [name]: value,
  //   });

  // };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    // If the input field is mobileNumber, perform validation
    if (name === "mobileNumber") {
      // If value matches the pattern or is empty, update the state
      if (mobileNumberPattern.test(value) || value === "") {
        setFormData({
          ...formData,
          [name]: value,
        });
      }
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleCallStatusChange = (e) => {
    setCallStatusItemSelected(e.target.value);
  };

  const handleCallStatusInputChange = (e) => {
    setCallStatusItemSelectedInput(e.target.value);
  };

  const handleCallType = (e) => {
    setCallTypeItemSelected(e.target.value);
  };

  const handleEnquiryType = (e) => {
    setEnquiryTypeItemSelected(e.target.value);
  };


  const handleMobileSearch = () => {
    setIsLoadingData(true);
    dispatch(
      getviewProfileData({
        mobileNumber: formData.mobileNumber,
        msLoggedUserId: msLoggedUserId,
        msLoggedRoleId: msLoggedRoleId,
      })
    );


    dispatch(
      getUserPointsData({
        mobileNumber: formData.mobileNumber,
        msLoggedUserId: msLoggedUserId,
        msLoggedRoleId: msLoggedRoleId,
      })
    );
    dispatch(
      getUserCallLogsData({
        startIndex: startIndex,
        mobileNumber: formData.mobileNumber,
        msLoggedUserId: msLoggedUserId,
        msLoggedRoleId: msLoggedRoleId,
        fromDate: formattedStartDate,
        toDate: formattedEndDate,
        callstatus: callStatusItemSelected,
      })
    );

    dispatch(
      getUserTransactionData({
        startIndex: startIndex,
        mobileNumber: formData.mobileNumber,
        msLoggedUserId: msLoggedUserId,
        msLoggedRoleId: msLoggedRoleId,
        fromDate: formattedStartPointStatementDate,
        toDate: formattedPointStatementEndDate,
        couponCode: couponCode,
        subCategory: selectSubcategory,
      })
    );

    dispatch(
      getUserRedeemedOrderData({
        startIndex: startIndexUserRedeemedOrder,
        mobileNumber: formData.mobileNumber,
        msLoggedUserId: msLoggedUserId,
        msLoggedRoleId: msLoggedRoleId,
        fromDate: formattedOrderReportStartDate,
        toDate: formattedOrderReportEndDate,
        orderNumber: orderNumber,
      })
    );
    setIsLoadingData(false);
  };


  const handleUpdate_Click = () => {
    openProfileModal();
  };

  const handleUpdate_Ok = () => {
    closeProfileModal();
    dispatch(
      updateUserLanguage({
        msLoggedUserId: msLoggedUserId,
        msLoggedRoleId: msLoggedRoleId,
        mobileNo: formData.mobileNumber,
        requestFrom: 'Microsite',
        langId: langID
      })
    );
  };


  useEffect(() => {
    dispatch(
      getUserCallLogsData({
        startIndex: startIndex,
        mobileNumber: formData.mobileNumber,
        msLoggedUserId: msLoggedUserId,
        msLoggedRoleId: msLoggedRoleId,
        fromDate: formattedStartDate,
        toDate: formattedEndDate,
        callstatus: callStatusItemSelected,
      })
    );
  }, [currentPage]);

  useEffect(() => {
    dispatch(
      getUserTransactionData({
        startIndex: startIndexUserTransaction,
        mobileNumber: formData.mobileNumber,
        msLoggedUserId: msLoggedUserId,
        msLoggedRoleId: msLoggedRoleId,
        fromDate: formattedStartPointStatementDate,
        toDate: formattedPointStatementEndDate,
        couponCode: couponCode,

      })
    );
  }, [currentUserTransactionPage]);

  useEffect(() => {
    dispatch(
      getUserRedeemedOrderData({
        startIndex: startIndexUserRedeemedOrder,
        mobileNumber: formData.mobileNumber,
        msLoggedUserId: msLoggedUserId,
        msLoggedRoleId: msLoggedRoleId,
        fromDate: formattedOrderReportStartDate,
        toDate: formattedOrderReportEndDate,
        orderNumber: orderNumber,
      })
    );
  }, [currentUserRedeemedOrderPage]);

  const handleCallSearch = () => {
    dispatch(
      getUserCallLogsData({
        startIndex: startIndex,
        mobileNumber: formData.mobileNumber,
        callstatus: callStatusItemSelected,
        msLoggedUserId: msLoggedUserId,
        msLoggedRoleId: msLoggedRoleId,
        fromDate: formattedStartDate,
        toDate: formattedEndDate,
      })
    );
  };

  const handlePointStatementSearch = () => {
    dispatch(
      getUserTransactionData({
        startIndex: startIndexUserTransaction,
        mobileNumber: formData.mobileNumber,
        msLoggedUserId: msLoggedUserId,
        msLoggedRoleId: msLoggedRoleId,
        fromDate: formattedStartPointStatementDate,
        toDate: formattedPointStatementEndDate,
        couponCode: couponCode,
        subCategory: selectSubcategory,
      })
    );
  };

  useEffect(() => {
    dispatch(getSubCatDropDownData({ msLoggedUserId, msLoggedRoleId }));
  }, []);

  const handleSubcategoryChange = (e) => {
    setSubcategorytype(e.target.value);
  };

  const handleOrderReportSearch = () => {
    dispatch(
      getUserRedeemedOrderData({
        startIndex: startIndex,
        mobileNumber: formData.mobileNumber,
        msLoggedUserId: msLoggedUserId,
        msLoggedRoleId: msLoggedRoleId,
        fromDate: formattedOrderReportStartDate,
        toDate: formattedOrderReportEndDate,
        orderNumber: orderNumber,
      })
    );
  };

  const handleSendMobileAppLink = () => {
    dispatch(
      userSentAppLinkData({
        mobileNo: formData.mobileNumber,
        requestFrom: "Microsite",
      })
    ).then((result) => {
      if (result.payload && result.payload.status) {
        Swal.fire({
          title: result.payload.message,
          icon: "success",
          confirmButtonText: "Okay",
          timer: 1000,
        });
      } else {
        // Handle failure case
      }
    });
  };

  const handleCallUpdateSubmit = () => {
    closeModal();
    dispatch(
      callUpdateData({
        mobileNo: formData.mobileNumber,
        msLoggedUserId: msLoggedUserId,
        msLoggedRoleId: msLoggedRoleId,
        callStatus: callStatusItemSelectedInput,
        callType: callTypeItemSelected,
        enquiryType: enquiryTypeItemSelected,
        comments: formData.comments,
      })
    ).then((result) => {
      if (result.payload && result.payload.status) {
        Swal.fire({
          title: result.payload.message,
          icon: "success",
          confirmButtonText: "Okay",
          timer: 1000,
        });
      } else {
        Swal.fire({
          title: result.payload.message,
          icon: "error",
          confirmButtonText: "Okay",
          timer: 1000,
        });
        // Handle failure case
      }
    });
  };

  useEffect(() => {
    dispatch(getUserCallStatusData());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getEnquiryTypeData());
  }, [dispatch]);



  const subcategory_options = SubCatDropDownList.map((item, index) => ({
    value: item.subCategory,
    label: item.subCategory
  }));


  // table data
  const tabledata = userCallLogsData
    ? userCallLogsData.map((item, index) => {
      const formatDate = (date) => {
        if (!date) return "";

        const d = new Date(date);
        const year = d.getFullYear();
        const month = String(d.getMonth() + 1).padStart(2, "0");
        const day = String(d.getDate()).padStart(2, "0");

        return `${day}-${month}-${year}`;
      };
      return {
        "S.No.": (currentPage - 1) * 10 + index + 1,
        "Called On": item.called_on,
        "Call Status": item.call_status_name,
        "Enquiry Type": item.enquiryType,
        Comments: item.comments,
        "Call Type": item.lca_call_type,
        "Called By": item.commentedby,
      };
    })
    : [];

  // table transaction data
  const tabledataUserTransaction = userTransactionData
    ? userTransactionData.map((item, index) => {
      const formatDate = (date) => {
        if (!date) return "";
        const d = new Date(date);
        const year = d.getFullYear();
        const month = String(d.getMonth() + 1).padStart(2, "0");
        const day = String(d.getDate()).padStart(2, "0");

        return `${day}-${month}-${year}`;
      };
      return {
        "S.No.": startIndexUserTransaction + index + 1,
        "User Name": item.name,
        "User Code": item.userCode,
        "Group Name": item.groupName,
        "Sub Category": item.subCategory,
        Brand: item.brand,
        "Product Name": item.productName,
        "Coupon Code": item.couponCode,
        "Coupon Points": item.couponPoints,
        "Bonus Points": item.bonusPoints,
        "Total Points": item.pointEarned,
        "Bonus Points Remarks": item.bonusRemarks,
        "Check in Date And Time": item.checkInDate,
      };
    })
    : [];

  // table transaction data
  const tabledataUserRedeemedOrder = userRedeemedOrderData
    ? userRedeemedOrderData.map((item, index) => {
      const formatDate = (date) => {
        if (!date) return "";
        const d = new Date(date);
        const year = d.getFullYear();
        const month = String(d.getMonth() + 1).padStart(2, "0");
        const day = String(d.getDate()).padStart(2, "0");

        return `${day}-${month}-${year}`;
      };
      return {
        "S.No.": startIndexUserRedeemedOrder + index + 1,
        "Order No.": item.orderNumber,
        "Product Name": item.productName,
        "Net Payable/Points Redeemed": item.pointRedeemedExTax,
        "Order Tax": item.orderTaxAmount,
        "Total Redeemed Points": item.totalPointsRedemeed,
        "Pan Available": item.pan_availability,
        "Order Created Date": item.orderDate,
        "User Mobile No.": item.mobileno,
        "Order Delivery Date": item.orderDate,
        "Order Status": item.orderStatus,
        // "Order Status Comments",
      };
    })
    : [];

  return (
    <div className="container" style={{ minHeight: "81.8vh" }}>
      <div className="row">
        <div className="col-md-12">
          <div className="row">
            <div className="col-md-12 mt-5">
              <h2 className="text-capitalize fs-5 fst-italic mt-3">
                User Profile

              </h2>
            </div>
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-4">
                  <label htmlFor="basic-url" className="form-label">
                    Enter Mobile Number
                  </label>
                  <div className="input-group mb-3">
                    <input
                      name="mobileNumber"
                      onChange={handleInputChange}
                      type="text"
                      value={formData.mobileNumber}
                      className="form-control"
                      placeholder="Enter Mobile Number"
                      aria-label="Recipient's username"
                      aria-describedby="button-addon2"
                      pattern="[0-9]*"
                      maxLength="10"
                    />
                    <button
                      className="btn btn-danger"
                      type="button"
                      id="button-addon2"
                      onClick={handleMobileSearch}
                    >
                      Search
                    </button>
                  </div>
                </div>
                {msLoggedRoleId === "3" || msLoggedRoleId === "4" ? (
                  <div className="col-md-8 mt-md-3">
                    <div className="row mt-3">
                      <div className="col-md-4 mb-3">
                        <button
                          className={
                            profileStatus && formData.mobileNumber !== ""
                              ? "btn btn-success text-white"
                              : "btn btn-secondary text-white"
                          }
                          type="button"
                          id="button-addon2"
                          disabled={
                            !profileStatus || formData.mobileNumber === ""
                          }
                          onClick={() => openModal()}
                        >
                          Call Update
                          {!profileStatus && true}
                        </button>
                      </div>
                      <div className="col-md-4"></div>
                      <div className="col-md-4">
                        <div className="">
                          <button
                            type="button"
                            className={
                              profileStatus && formData.mobileNumber !== ""
                                ? "btn btn-success text-white"
                                : "btn btn-secondary text-white"
                            }
                            disabled={
                              !profileStatus || formData.mobileNumber === ""
                            }
                            onClick={handleSendMobileAppLink}
                          >
                            Send Mobile App Link
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
            <div className="col-md-6 px-sm-3 mt-3">
              <CommonContainer headerText={"Profile Details"}>
                {profileData && profileStatus ? (
                  profileData.map((item) => (
                    <ProfileDetails key={item.userId} userData={item} selectedUserType={selectedUser_Type} userTypeOptions={userLanguageList} handleUserTypeChange={handleUser_TypeChange} handleUpdateClick={handleUpdate_Click} />
                  ))
                ) : (
                  <p className="text-center">No Data Found</p>
                )}
              </CommonContainer>
            </div>
            <div className="col-md-6 px-sm-3 mt-3">
              <CommonContainer headerText={"Point Summary"}>
                {pointsData && pointsStatus ? (
                  <UserPoints userPoints={pointsData} />
                ) : (
                  <p className="text-center">No Data Found</p>
                )}
              </CommonContainer>
              <div className="mt-3">
                <CommonContainer headerText={"Call Log Reports"}>
                  <div>
                    <CallLogsFilter
                      options={userCallStatusList}
                      value={callStatusItemSelected}
                      onChange={handleCallStatusChange}
                      startDate={startDate}
                      endDate={endDate}
                      handleCallSearch={handleCallSearch}
                      setStartDate={setStartDate}
                      setEndDate={setEndDate}
                    />
                  </div>
                  <div className="mt-3">
                    <Common.CustomTable
                      columns={userCallLogscolumns}
                      data={tabledata}
                      message={message}
                      totalRecords={totalRecords}
                      itemsPerPage={itemsPerPage}
                      currentPage={currentPage}
                      setCurrentPage={setCurrentPage}
                      totalPages={totalPages}
                      startIndex={startIndex}
                      endIndex={endIndex}
                    />
                  </div>
                </CommonContainer>
              </div>
            </div>
            <div className="col-md-12  mt-3">
              <CommonContainer headerText="Point Statement">
                <div className="row">
                  <div className="col-md-12">
                    <PointStatementFilter
                      couponCode={couponCode}
                      setCouponCode={setCouponCode}
                      startDate={startPointStatementDate}
                      endDate={endPointStatementDate}
                      setStartDate={setstartPointStatementDate}
                      setEndDate={setEndPointStatementDate}
                      handlePointStatementSearch={handlePointStatementSearch}
                      subcategoryoptions={subcategory_options}
                      handleSubcategoryChange={handleSubcategoryChange}
                      selectedSubcategory={selectSubcategory}
                    />
                  </div>
                  <div className="col-md-12 mt-3">
                    <Common.CustomTable
                      columns={pointsStatementColumns}
                      data={tabledataUserTransaction}
                      message={userTransactionmessage}
                      totalRecords={userTransactiontotalRecords}
                      itemsPerPage={itemsPerPageuserTransaction}
                      currentPage={currentUserTransactionPage}
                      setCurrentPage={setCurrentUserTransactionPage}
                      totalPages={totalPagesUserTransaction}
                      startIndex={startIndexUserTransaction}
                      endIndex={endIndexUserTransaction}
                    />
                  </div>
                </div>
              </CommonContainer>
            </div>
            <div className="col-md-12 mt-3">
              <CommonContainer headerText="Order Report">
                <div className="row">
                  <div className="col-md-12">
                    <CommonFilter
                      orderNumber={orderNumber}
                      setOrderNumber={setOrderNumber}
                      startDate={orderReportStartDate}
                      endDate={orderReportEndDate}
                      setStartDate={setOrderReportStartDate}
                      setEndDate={setOrderReportEndDate}
                      handleOrderReportSearch={handleOrderReportSearch}
                    />
                  </div>
                  <div className="col-md-12 mt-3">
                    <Common.CustomTable
                      columns={orderReportColumns}
                      data={tabledataUserRedeemedOrder}
                      message={userRedeemedOrdermessage}
                      totalRecords={userRedeemedOrdertotalRecords}
                      itemsPerPage={itemsPerPageuserRedeemedOrder}
                      currentPage={currentUserRedeemedOrderPage}
                      setCurrentPage={setCurrentUserRedeemedOrderPage}
                      totalPages={totalPagesUserRedeemedOrder}
                      startIndex={startIndexUserRedeemedOrder}
                      endIndex={endIndexUserRedeemedOrder}
                    />
                  </div>
                </div>
              </CommonContainer>
            </div>
          </div>
        </div>
        <Common.Modal_Component
          title="Call Update"
          showModal={showModal}
          onClose={closeModal}
          body={
            <CallUpdateForm
              userCallStatusList={userCallStatusList}
              callTypeList={callTypeList}
              handleCallStatusInputChange={handleCallStatusInputChange}
              handleCallType={handleCallType}
              handleEnquiryType={handleEnquiryType}
              callStatusItemSelectedInput={callStatusItemSelectedInput}
              callTypeItemSelected={callTypeItemSelected}
              enquiryTypeItemSelected={enquiryTypeItemSelected}
              enquiryList={enquiryList}
              // handleCallUpdateSubmit={handleCallUpdateSubmit}
              handleInputChange={handleInputChange}
              formData={formData}
            />
          }
          onCancel={handleCancel}
          onOk={handleCallUpdateSubmit}
        ></Common.Modal_Component>
        <Common.Modal_Component
          showModal={showProfileModal}
          onClose={closeProfileModal}
          body={
            "Are you sure want to change the language?"
          }
          onCancel={handleCancel}
          onOk={handleUpdate_Ok}
        />
      </div>

    </div>
  );
};

export default OneView;
