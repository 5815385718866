import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import CustomeNavbar from "../Navbar/Navbar";
import { useDispatch } from "react-redux";
import { logoutUser } from "../../../features/logoutSlice";

function Header() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const roleId = localStorage.getItem("roleId");
  const userId = localStorage.getItem("userId");
  const status = localStorage.getItem("status");
  const userName = localStorage.getItem("userName");
  const role = localStorage.getItem("role");

  const handleLogout = () => {
    const postData = { userId: userId };
    dispatch(logoutUser(postData))
      .then(() => {
        // Clear session data upon successful logout
        localStorage.removeItem("roleId");
        localStorage.removeItem("userId");
        localStorage.removeItem("status");
        localStorage.removeItem("userName");
        localStorage.removeItem("role");
        localStorage.removeItem("mobile");
        localStorage.removeItem("email");

        // Redirect to login page
        navigate("/");
      })
      .catch((error) => {
        console.error("Logout failed:", error);
        // Handle logout failure (e.g., display error message)
      });
  };

  // Check if user is logged in on component mount
  useEffect(() => {
    if (!status || !roleId || !userId || !role) {
      navigate("/"); // Redirect to login page if session data is missing
    }
  }, [navigate, roleId, userId, status, role]);

  return (
    <div className="mb-3">
      <CustomeNavbar userName={userName} handleLogout={handleLogout} />;
    </div>
  );
}

export default Header;
