import React from "react";
import { Button, Modal as ReactModal } from "react-bootstrap";

const Modal = ({ showModal, onClose, title, body, onCancel, onOk }) => {
  return (
    <ReactModal show={showModal} onHide={onClose} centered>
      <ReactModal.Header closeButton>
        <ReactModal.Title className="h5">{title}</ReactModal.Title>
      </ReactModal.Header>
      <ReactModal.Body>{body}</ReactModal.Body>
      <ReactModal.Footer>
        <Button variant="danger" onClick={onCancel}>
          Cancel
        </Button>
        <Button onClick={onOk}>Ok</Button>
      </ReactModal.Footer>
    </ReactModal>
  );
};

export default Modal;
