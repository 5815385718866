import React, { useEffect, useState } from "react";
import CustomTable from "../../../component/common/TableComponent/TableComponent";
import Modal_Component from "../../../component/common/Modal/Modal";
import { useDispatch, useSelector } from "react-redux";
import { getviewSalesTeamData } from "../../../features/viewSalesTeamSlice";
import { updateMemberStatus } from "../../../features/updateMemberStatusSlice";
import Button from "../../../component/Button/Button";
import { downloadViewSalesTeamReports } from "../../../features/downloadSalesTeamSlice";

function ViewSalesTeam() {
  const msLoggedUserId = localStorage.getItem("userId");
  const msLoggedRoleId = localStorage.getItem("roleId");
  const dispatch = useDispatch();
  const { data, totalRecords } = useSelector(
    (state) => state.viewSalesTeamList.data
  );

  console.log("Sales totalRecords", totalRecords);
  const loading = useSelector((state) => state.viewSalesTeamList.loading);
  const [currentPage, setCurrentPage] = useState(1);
  // Modals
  const [showModal, setShowModal] = useState(false);
  const openModal = () => setShowModal(true);
  const closeModal = () => setShowModal(false);

  // saveuserStatus
  const [saveUserStatus, setSaveUserStatus] = useState("");

  // Add a state variable to store the userId to update
  const [userIdToUpdate, setUserIdToUpdate] = useState(null);

  const itemsPerPage = 10;
  const totalPages = Math.ceil(totalRecords / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  // const endIndex = startIndex + itemsPerPage;

  useEffect(() => {
    dispatch(
      getviewSalesTeamData({ startIndex, msLoggedUserId, msLoggedRoleId })
    );
  }, [dispatch, currentPage, msLoggedUserId, msLoggedRoleId]);

  const handleCancel = () => {
    closeModal();
  };

  const handleOk = async () => {
    closeModal();
    await dispatch(
      updateMemberStatus({ userIdToUpdate, saveUserStatus, msLoggedUserId })
    );
    await dispatch(
      getviewSalesTeamData({ startIndex, msLoggedUserId, msLoggedRoleId })
    );
  };

  // table columns
  const columns = [
    "S.No.",
    "Status & Action",
    "User Name",
    "User Mobile Number",
    "User Email",
    "User Role",
  ];
  // table data
  const tableData = data
    ? data.map((item, index) => ({
        "S.No.": (currentPage - 1) * 10 + index + 1,
        "Status & Action": (
          <>
            <button
              className={
                item.usr_active === "Yes"
                  ? "btn btn-primary btn-sm w-50"
                  : "btn btn-danger btn-sm w-50"
              }
              onClick={() => handleUserToggle(item.userId, item.usr_active)}
            >
              {item.usr_active === "Yes" ? "Deactivate" : "Activate"}
            </button>
          </>
        ),
        "User Name": item.name,
        "User Mobile Number": item.mobileno,
        "User Email": item.userEmail,
        "User Role": item.userRole,
      }))
    : [];

  const handleUserToggle = (userId, userActive) => {
    openModal();
    const newStatus = userActive === "Yes" ? "Inactive" : "Active";
    setSaveUserStatus(newStatus);
    setUserIdToUpdate(userId);
  };

  const handleDownload = () => {
    dispatch(downloadViewSalesTeamReports({ msLoggedUserId, msLoggedRoleId }))
      .then((response) => {
        const currentDate = new Date();
        const timestamp = currentDate.getTime();
        const formattedDate = currentDate.toISOString().slice(0, 10);
        const suggestedFileName = `view_sales_team_report_${formattedDate}_${timestamp}.csv`;
        const blob = new Blob([response.payload], { type: "text/csv" });

        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = suggestedFileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((error) => {
        console.error("Download error:", error);
      });
  };
  return (
    <div className="container" style={{ minHeight: "81.8vh" }}>
      <div className="row">
        <div className="col-md-12">
          <div className="row">
            <div className="col-md-9 mt-5">
              <h2 className="text-capitalize fs-5 fst-italic">
                View Sales Lists
              </h2>
            </div>
            <div className="col-md-3 mt-md-3 mt-sm-3 d-md-flex justify-content-md-end">
              <div className="mt-4">
                <Button variant={"download"} onClick={handleDownload}>
                  Download
                </Button>
              </div>
            </div>
          </div>
        </div>
        {loading ? (
          <div className="d-flex justify-content-center mt-4">
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        ) : (
          <div className="col-md-12 my-4">
            <CustomTable
              columns={columns}
              data={tableData}
              itemsPerPage={itemsPerPage}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              totalPages={totalPages}
            />
          </div>
        )}
        <Modal_Component
          showModal={showModal}
          onClose={closeModal}
          body={
            saveUserStatus !== "Active"
              ? "Are you sure want to deactivate the member?"
              : "Are you sure want to activate the member?"
          }
          onCancel={handleCancel}
          onOk={handleOk}
        />
      </div>
    </div>
  );
}

export default ViewSalesTeam;
