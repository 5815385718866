import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import MotulLogo from "../../assets/svg/motulLogoSvg.svg";
import styles from "./Login.module.css";
import { useDispatch } from "react-redux";
import { getClientIP } from "../../features/clientIpSlice";

const Otp_page = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [formData, setFormData] = useState({
        email: "",
        password: "",
    });

    const handleSubmit = async (e) => {
        e.preventDefault();
        navigate("/landingpage");
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    useEffect(() => {
        dispatch(getClientIP());
    }, [dispatch]);


    return (
        <>
            <div
                className="container-fluid pb-4"
                style={{ minHeight: "89.2vh" }}
                id={styles.loginContainer}
            >
                <div className="row">
                    <div className="col-md-12">
                        <div className="row justify-content-center align-items-center">
                            <div
                                className="col-sm-8 shadow-sm p-3 rounded"
                                style={{ backgroundColor: "#ed1d24" }}
                            >
                                <div className="row">
                                    <div className="col-sm-12">
                                        <img src={MotulLogo} alt="" className="w-50" />
                                    </div>
                                    <div className="col-sm-12">
                                        <form
                                            action=""
                                            method="post"
                                            autoComplete="off"
                                            onSubmit={handleSubmit}
                                        >
                                            <div className="mb-3">
                                                <label
                                                    htmlFor="username"
                                                    className="form-label text-light"
                                                >
                                                    OTP
                                                </label>
                                                <input
                                                    className={styles.inputText}
                                                    placeholder="Enter OTP"
                                                    autoComplete="off"
                                                    type="mobilenumber"
                                                    name="mobilenumber"
                                                    // value={formData.email}
                                                    onChange={handleChange}
                                                    required
                                                />
                                            </div>

                                            <div className="mb-3">
                                                <button
                                                    type="submit"
                                                    className="btn btn-light w-100 fw-bold"
                                                >
                                                    Submit
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Otp_page;
