import React from "react";

const UserPoints = ({ userPoints }) => {
  return (
    <div>
      <p>
        <strong>Total Points(Earned Points):</strong>{" "}
        {userPoints.totalPointsEarned}
      </p>
      <p>
        <strong>Redeemed Points:</strong> {userPoints.totalPointsRedeemed}
      </p>
      <p>
        <strong>Balance Points:</strong> {userPoints.totalPointsBalance}
      </p>
      <p>
        <strong>Total TDS Points:</strong> {userPoints.totalTdsPoints}
      </p>
      <hr />
      <p>
        <strong>Total Earned Points(Old/Historical):</strong>{" "}
        {userPoints.hisPointsEarned}
      </p>
      <p>
        <strong>Total Earned Redeemed Points(Old/Historical):</strong>{" "}
        {userPoints.hisPointsRedeemed}
      </p>
      <p>
        <strong>Total Balance Points(Old/Historical):</strong>{" "}
        {userPoints.hisPointsBalance}
      </p>
    </div>
  );
};

export default UserPoints;
