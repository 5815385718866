import React, { useState, useEffect } from "react";
import Button from "../../../component/Button/Button";
import { useNavigate } from "react-router-dom";
import appConfig from "../../../AppConfig";
const ProcessingData = () => {
  const navigate = useNavigate();
  const [data, setData] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${appConfig.apiUrl}msPreviousCodeGenCheck?msLoggedUserId=${localStorage.getItem('userId')}&&msLoggedRoleId=${localStorage.getItem('roleId')}`, {
          method: 'GET',
          headers: {
            'X-API-Key': appConfig.apiKey
          }
        });

        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }

        const data = await response.json();

      
          setData(data);
       

      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
    return () => {
    };

  }, []); 

  return (
    <div className="container" style={{ minHeight: "81.8vh" }}>
      <div className="row">
        <div className="col-md-6 mt-4">
          <div className="shadow p-3 mt-3">
            <p className="fs-6 fw-normal mt-3">
              <span className="fw-bold">Note*:&nbsp;</span>
              {data.message}
            </p>
            {data.status &&
            <Button onClick={() => navigate("/view-coupons")} >Click here...</Button>
}
            </div>
        </div>
        <div className="col-md-12"></div>
      </div>
    </div>
  );
};

export default ProcessingData;
