import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import appConfig from "../AppConfig";

const initialState = {
  loading: false,
  data: [],
  error: "",
};

export const getProductList = createAsyncThunk(
  "getProductList",
  async (
    { brandSelectedItem, subCategorySelectedItem, msLoggedRoleId },
    { rejectWithValue }
  ) => {
    const urls = `${appConfig.apiUrl}msProductMaster?msLoggedRoleId=${msLoggedRoleId}&brandId=${brandSelectedItem}&subId=${subCategorySelectedItem}`;
    const headers = {
      "X-API-Key": appConfig.apiKey,
      "Content-Type": "application/x-www-form-urlencoded",
    };
    const response = await axios(urls, { headers: headers });
    try {
      const result = response.data.data;
      return result;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const productList = createSlice({
  name: "productList",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getProductList.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getProductList.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.error = false;
    });
    builder.addCase(getProductList.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = action.error.message;
    });
  },
});

export default productList.reducer;
