import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";
import { addSalesMember } from "../../../features/addSalesMemberSlice";
import Common from "../../../component/common/common";


function AddSalesMember() {
  
  const msLoggedUserId = localStorage.getItem("userId");
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.addSalesMemberList);
  const [submitted, setSubmitted] = useState(false);
  const [salesRoleSelectedItem, setSalesRoleSelectedItem] = useState("");
  const [formData, setFormData] = useState({
    salesName: "",
    salesMobile: "",
    salesEmail: "",
    salesRole: "",
    createdBy: "",
  });

  const salesRoleOptions = [
    { id: 1, salesRole: "Territory Head", value: "7" },
    { id: 2, salesRole: "State Head", value: "8" },
    { id: 3, salesRole: "Regional Head", value: "9" },
  ];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSalesRoleChange = (e) => {
    setSalesRoleSelectedItem(e.target.value);
  };

  const handleSubmit = async () => {
    await dispatch(
      addSalesMember({
        salesName: formData.salesName,
        salesMobile: formData.salesMobile,
        salesEmail: formData.salesEmail,
        salesRole: salesRoleSelectedItem,
        msLoggedUserId: msLoggedUserId,
      })
    );

    setSubmitted(true);
  };

  useEffect(() => {
    if (submitted && data.status !== undefined) {
      if (data.status) {
        Swal.fire({
          title: data.message,
          icon: "success",
          confirmButtonText: "Okay",
        });
        setFormData({
          salesName: "",
          salesMobile: "",
          salesEmail: "",
          salesRole: "",
          createdBy: "",
        });
      } else {
        // Handling specific error messages
        if (data.message === "Sales name is mandatory") {
          Swal.fire({
            title: data.message,
            icon: "error",
            confirmButtonText: "Okay",
          });
        } else if (data.message === "Email already exist") {
          Swal.fire({
            title: "Email already exists",
            text: "Please provide a different email.",
            icon: "error",
            confirmButtonText: "Okay",
          });
        } else {
          // Handling generic error messages
          Swal.fire({
            title: "Error",
            text: data.message,
            icon: "error",
            confirmButtonText: "Okay",
          });
        }
      }
      setSubmitted(false);
    }
  }, [data.status, data.message, submitted]);


  return (
    <div className="container mt-5" style={{ minHeight: "81.8vh" }}>
      <div className="row">
        <div className="col-md-12">
          <h2 className="text-capitalize fs-5 fst-italic">Add Sales Member</h2>
        </div>
        <div className="col-md-4">
          <div className="row">
            <div className="col-md-12 mt-md-2">
              <Common.TextInput
                label={"Sales Name"}
                name="salesName"
                value={formData.salesName}
                onChange={handleChange}
                placeholder="Enter Name"
              />
            </div>
            <div className="col-md-12 mt-md-2">
              <Common.TextInput
                label={"Sales Mobile"}
                type="number"
                name="salesMobile"
                value={formData.salesMobile}
                onChange={handleChange}
                minLength={10}
                placeholder="Enter Mobile"
              />
            </div>
            <div className="col-md-12 mt-md-2">
              <Common.TextInput
                label="Sales Email"
                name="salesEmail"
                value={formData.salesEmail}
                onChange={handleChange}
                placeholder="Enter Email"
              />
            </div>
            <div className="col-md-12 mt-md-2">
              <Common.SelectInputId
                options={salesRoleOptions}
                label={"Sales Role"}
                labelKey="salesRole"
                valueKey="value"
                value={salesRoleSelectedItem}
                onChange={handleSalesRoleChange}
              />
            </div>
            <div className="col-md-12 mt-3 mb-4">
              <button
                onClick={handleSubmit}
                className={
                  !formData.salesName &&
                  !formData.salesEmail &&
                  !salesRoleSelectedItem &&
                  !formData.salesMobile
                    ? "btn btn-secondary btn-disabled"
                    : "btn btn-danger"
                }
                disabled={
                  formData.salesName ||
                  formData.salesEmail ||
                  salesRoleSelectedItem ||
                  formData.salesMobile
                    ? false
                    : true
                }
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddSalesMember;
