import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import appConfig from "../AppConfig";

const initialState = {
  data: [],
  loading: false,
  error: "",
  message: "",
  status: "",
};

export const getMsNavData = createAsyncThunk(
  "getMsNavData",
  async (msloggedRoleId, { rejectWithValue }) => {
    const urls = `${appConfig.apiUrl}msSubTopNav?msLoggedRoleId=${msloggedRoleId}`;
    const headers = {
      "X-API-Key": appConfig.apiKey,
    };
    const response = await axios.get(urls, { headers: headers });
    // console.log("msloggedroleId", msloggedRoleId);

    try {
      const result = await response.data;
      console.log("nav data",result );
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const msNavDetails = createSlice({
  name: "msNavDetails",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getMsNavData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getMsNavData.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.message = action.payload.message; // Update message in state
      state.status = action.payload.status; // Update status in state
      state.error = "";
    });
    builder.addCase(getMsNavData.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = action.error.message;
    });
  },
});

export default msNavDetails.reducer;
