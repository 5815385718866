// Pagination.js
import React from "react";
import { Pagination } from "react-bootstrap";

const MAX_PAGE_ITEMS = 10; // Adjust this value based on your preference
const TablePagination = ({ currentPage, totalPages, onPageChange }) => {
  const pageItems = [];

  // Calculate the range of visible pages
  const startPage = Math.max(1, currentPage - Math.floor(MAX_PAGE_ITEMS / 2));
  const endPage = Math.min(startPage + MAX_PAGE_ITEMS - 1, totalPages);

  for (let page = startPage; page <= endPage; page++) {
    pageItems.push(
      <Pagination.Item
        key={page}
        active={page === currentPage}
        onClick={() => onPageChange(page)}
      >
        {page}
      </Pagination.Item>
    );
  }

  // Show ellipsis and last page if there are more pages
  if (startPage > 1) {
    pageItems.unshift(
      <Pagination.Ellipsis
        key="ellipsis-start"
        onClick={() => onPageChange(startPage - 1)}
      />
    );
    pageItems.unshift(
      <Pagination.Item key={1} onClick={() => onPageChange(1)}>
        1
      </Pagination.Item>
    );
  }

  if (endPage < totalPages) {
    pageItems.push(
      <Pagination.Ellipsis
        key="ellipsis-end"
        onClick={() => onPageChange(endPage + 1)}
      />
    );
    pageItems.push(
      <Pagination.Item
        key={totalPages}
        onClick={() => onPageChange(totalPages)}
      >
        {totalPages}
      </Pagination.Item>
    );
  }

  return (
    <Pagination>
      <Pagination.Prev
        onClick={() => onPageChange(currentPage - 1)}
        disabled={currentPage === 1}
      />
      {pageItems}
      <Pagination.Next
        onClick={() => onPageChange(currentPage + 1)}
        disabled={currentPage === totalPages}
      />
    </Pagination>
  );
};

export default TablePagination;
