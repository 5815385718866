// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.VerifyCode_verifyCodeContainer__KAUXT {
  padding: 8px 65px;
}

.VerifyCode_title__CjtFH {
  color: #000;
  font-size: 34px;
  font-weight: 900;
  font-style: italic;
  margin-top: 40px;
}

.VerifyCode_verifyCodeContent__s\\+E7D {
  width: 50%;
  height: 51.3vh;
}

.VerifyCode_tableContainer__0bAFB {
  margin-top: 10px;
}

.VerifyCode_thead__o5VSt,
.VerifyCode_th__QFvOv,
.VerifyCode_td__4gcyy {
  border: 1px solid #fff !important;
  padding: 8px 12px;
  font-size: 14px;
}

.VerifyCode_thead__o5VSt {
  background-color: #f00;
  color: #fff;
}
`, "",{"version":3,"sources":["webpack://./src/screens/utility/VeriFyCode/VerifyCode.module.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;AACnB;;AAEA;EACE,WAAW;EACX,eAAe;EACf,gBAAgB;EAChB,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,UAAU;EACV,cAAc;AAChB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;;;EAGE,iCAAiC;EACjC,iBAAiB;EACjB,eAAe;AACjB;;AAEA;EACE,sBAAsB;EACtB,WAAW;AACb","sourcesContent":[".verifyCodeContainer {\n  padding: 8px 65px;\n}\n\n.title {\n  color: #000;\n  font-size: 34px;\n  font-weight: 900;\n  font-style: italic;\n  margin-top: 40px;\n}\n\n.verifyCodeContent {\n  width: 50%;\n  height: 51.3vh;\n}\n\n.tableContainer {\n  margin-top: 10px;\n}\n\n.thead,\n.th,\n.td {\n  border: 1px solid #fff !important;\n  padding: 8px 12px;\n  font-size: 14px;\n}\n\n.thead {\n  background-color: #f00;\n  color: #fff;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"verifyCodeContainer": `VerifyCode_verifyCodeContainer__KAUXT`,
	"title": `VerifyCode_title__CjtFH`,
	"verifyCodeContent": `VerifyCode_verifyCodeContent__s+E7D`,
	"tableContainer": `VerifyCode_tableContainer__0bAFB`,
	"thead": `VerifyCode_thead__o5VSt`,
	"th": `VerifyCode_th__QFvOv`,
	"td": `VerifyCode_td__4gcyy`
};
export default ___CSS_LOADER_EXPORT___;
