import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import appConfig from "../AppConfig";

const initialState = {
  loading: false,
  data: [],
  error: "",
};

export const getviewProfileData = createAsyncThunk(
  "getviewProfileData",
  async (
    { mobileNumber, msLoggedUserId, msLoggedRoleId },
    { rejectWithValue }
  ) => {
    const urls = `${appConfig.apiUrl}msOneViewUserProfile?mobileNo=${mobileNumber}&msLoggedUserId=${msLoggedUserId}&msLoggedRoleId=${msLoggedRoleId}`;
    const headers = {
      "X-API-Key": appConfig.apiKey,
    };
    const response = await axios.get(urls, { headers: headers });
    try {
      const result = response.data;
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const viewProfileDetails = createSlice({
  name: "viewProfileDetails",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getviewProfileData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getviewProfileData.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.error = "";
    });
    builder.addCase(getviewProfileData.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = action.error.message;
    });
  },
});

export default viewProfileDetails.reducer;
