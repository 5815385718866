import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import appConfig from "../AppConfig";

const initialState = {
  data: [],
  loading: false,
  error: "",
};
// getDashboardData
export const getSubCatDropDownData = createAsyncThunk(
  "getSubCatDropDownData",
  async (
    { msLoggedUserId, msLoggedRoleId },
    { rejectWithValue }
    
  ) => {
    console.log("fdsjdyutfwgdousfhkbwdos");
    const urls = `${appConfig.apiUrl}msSubCatDropDown?msLoggedUserId=${msLoggedUserId}&msLoggedRoleId=${msLoggedRoleId}`;
    console.log("subcategory URL:", urls);
    const headers = {
      "X-API-Key": appConfig.apiKey,
      "Cookie": "ci_session=e1m09jjr3tqvhdt2fqdrgj7ud1leqp25", 
    };
    const response = await axios.get(urls, {
      headers: headers,
    });
    try {
      const result = await response.data.data;
      console.log("Subcategory slice", result);
      return result;
      
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const SubCatDropDown = createSlice({
  name: "SubCatDropDown",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getSubCatDropDownData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getSubCatDropDownData.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.error = "";
    });
    builder.addCase(getSubCatDropDownData.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = action.error.message;
    });
  },
});

export default SubCatDropDown.reducer;
