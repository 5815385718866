import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import appConfig from "../AppConfig";

const initialState = {
  data: [],
  loading: false,
  error: "",
  message: "",
  status: "",
};

export const getSalesValidInvalid = createAsyncThunk(
  "getSalesValidInvalid",
  async (params, { rejectWithValue }) => {
    const urls = `${appConfig.apiUrl}msSalesMemberValidInvalid`;
    const headers = {
      "X-API-Key": appConfig.apiKey,
    };
    const response = await axios.get(urls, {
      params: params,
      headers: headers,
      responseType: "arraybuffer",
    });
    try {
      const result = await response.data;
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const salesValidInvalidDetails = createSlice({
  name: "salesValidInvalidDetails",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getSalesValidInvalid.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getSalesValidInvalid.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.error = "";
    });
    builder.addCase(getSalesValidInvalid.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = action.error.message;
    });
  },
});

export default salesValidInvalidDetails.reducer;
