// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.label-camp{
    font-weight: bold;
}

.apply-mt{
    margin-top: 53px;
}

.apply-mt-text{
    margin-top: 60px;
    font-weight: bold;
}

.size-font{
    font-size: 13px;
    height: 38px;
}

.sub-mit-btn{
    width: 80px;
}

.date-input {
    color: black !important; /* Set the text color */
  }`, "",{"version":3,"sources":["webpack://./src/screens/Campaign/campaignpage.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;AACrB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;IAChB,iBAAiB;AACrB;;AAEA;IACI,eAAe;IACf,YAAY;AAChB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,uBAAuB,EAAE,uBAAuB;EAClD","sourcesContent":[".label-camp{\n    font-weight: bold;\n}\n\n.apply-mt{\n    margin-top: 53px;\n}\n\n.apply-mt-text{\n    margin-top: 60px;\n    font-weight: bold;\n}\n\n.size-font{\n    font-size: 13px;\n    height: 38px;\n}\n\n.sub-mit-btn{\n    width: 80px;\n}\n\n.date-input {\n    color: black !important; /* Set the text color */\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
