import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import appConfig from "../AppConfig";

const initialState = {
  loading: false,
  data: [],
  error: "",
};

export const downloadRedemptionReports = createAsyncThunk(
  "downloadRedemptionReports",
  async (
    {
      formattedStartDate,
      formattedEndDate,
      mobileNumber,
      districtItemSelected,
      stateItemSelected,
      startIndex,
      msLoggedUserId,
      msLoggedRoleId,
    },
    { rejectWithValue }
  ) => {
    const urls = `${appConfig.apiUrl}msRedemptionReportDownload?msLoggedUserId=${msLoggedUserId}&msLoggedRoleId=${msLoggedRoleId}&fromDate=${formattedStartDate}&toDate=${formattedEndDate}&district=${districtItemSelected}&state=${stateItemSelected}&mobile=${mobileNumber}`;
    const headers = {
      "X-API-Key": appConfig.apiKey,
    };
    const response = await axios.get(urls, {
      headers: headers,
      responseType: "blob",
    });
    try {
      const result = await response.data;
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const downloadRedemptionReportDetails = createSlice({
  name: "downloadRedemptionReportDetails",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(downloadRedemptionReports.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(downloadRedemptionReports.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(downloadRedemptionReports.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = action.error.message;
    });
  },
});

export default downloadRedemptionReportDetails.reducer;
