import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import MotulLogo from "../../assets/svg/motulLogoSvg.svg";
import styles from "./Login.module.css";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
import { getClientIP } from "../../features/clientIpSlice";


const Sso_login = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleSubmit = async (e) => {
        e.preventDefault();
        Swal.fire({
            title: "Welcome to Motul India",
            icon: "success",
            confirmButtonText: "Okay",
            timer: 1000,
        });
        navigate("/");
    };

    useEffect(() => {
        dispatch(getClientIP());
    }, [dispatch]);

    return (
        <>
            <div
                className="container-fluid pb-4"
                style={{ minHeight: "89.2vh" }}
                id={styles.loginContainer}
            >
                <div className="row">
                    <div className="col-md-12 justify-content-center align-items-center" >
                        <div className="row w-100 justify-content-center">
                            <div
                                className="col-sm-2 shadow-sm p-3 mx-3 rounded"
                                style={{ backgroundColor: "#ed1d24", height: "150px" }}
                            >
                                <div className="row">
                                    <div className="col-sm-12">
                                        <img src={MotulLogo} alt="" className="w-100" />
                                    </div>
                                    <div className="col-sm-12">
                                        <div className="mb-3 mt-3">
                                            <button
                                                type="submit"
                                                className="btn btn-light w-100 fw-bold"
                                                onClick={handleSubmit}
                                            >
                                                India website
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div
                                className="col-sm-2 shadow-sm p-3 mx-3 rounded"
                                style={{ backgroundColor: "#ed1d24", height: "150px" }}
                            >
                                <div className="row">
                                    <div className="col-sm-12">
                                        <img src={MotulLogo} alt="" className="w-100" />
                                    </div>
                                    <div className="col-sm-12">
                                        <div className="mb-3 mt-3">
                                            <button
                                                type="submit"
                                                className="btn btn-light w-100 fw-bold"
                                                
                                            >
                                                Bangladesh Website
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Sso_login;
