import React from "react";
import Button from "../../../component/Button/Button";
import { useNavigate } from "react-router-dom";

const TransactionDataProcessing = () => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/download-section");
  };
  return (
    <div className="container" style={{ minHeight: "81.8vh" }}>
      <div className="row">
        <div className="col-md-6 mt-4">
          <div className="shadow p-3 mt-3">
            <p className="fs-6 fw-normal mt-3">
              <span className="fw-bold">Note*:&nbsp;</span>
              The Request is processing Please check in the generated report section
            </p>
            <Button onClick={handleClick}>Click here...</Button>
          </div>
        </div>
        <div className="col-md-12"></div>
      </div>
    </div>
  );
};

export default TransactionDataProcessing;
