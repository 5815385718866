import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import appConfig from "../AppConfig";

const initialState = {
  data: [],
  loading: false,
  error: "",
};

// getTierNameList thunk
export const getTierNameList = createAsyncThunk(
  "getTierNameList",
  async ({ token, msLoggedUserId, msLoggedRoleId }, { rejectWithValue }) => {
    console.log("Inside getTierNameList thunk");

    if (!token) {
      console.log("No token provided, returning empty list");
      return [];
    }

    const url = `${appConfig.apiUrl}msTierList?msLoggedUserId=${msLoggedUserId}&msLoggedRoleId=${msLoggedRoleId}&token=${encodeURIComponent(token)}`;
    console.log("Request URL:", url);

    const headers = {
      "X-API-Key": appConfig.apiKey,
    };

    try {
      const response = await axios.get(url, { headers: headers });
      const result = response.data.data;
      console.log("Tier Name List", result);
      return result;
    } catch (error) {
      console.log("Error in fetching tier name list:", error);
      return rejectWithValue(error);
    }
  }
);

export const TierNameListSlice = createSlice({
  name: "TierNameList",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getTierNameList.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getTierNameList.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.error = "";
    });
    builder.addCase(getTierNameList.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = action.error.message;
    });
  },
});

export default TierNameListSlice.reducer;
