import React from "react";
import Common from "../../../component/common/common";

const CallLogsFilter = ({
  options,
  value,
  onChange,
  handleCallSearch,
  startDate,
  endDate,
  setStartDate,
  setEndDate,
}) => {
  return (
    <div className="row">
      <div className="col-md-3">
        <Common.CustomDatePicker
          label={"From Date"}
          dateFormat={"dd-MM-yyyy"}
          selected={startDate}
          onChange={(date) => setStartDate(date)}
          placeholderText="dd-yy-yyyy"
        />
      </div>
      <div className="col-md-3">
        <Common.CustomDatePicker
          label="To Date"
          dateFormat={"dd-MM-yyyy"}
          selected={endDate}
          onChange={(date) => setEndDate(date)}
          placeholderText="dd-yy-yyyy"
        />
      </div>
      <div className="col-md-3">
        <Common.SelectInput
          value={value}
          onChange={onChange}
          options={options}
          label={"Call Status"}
          labelKey="call_status_name"
          valueKey="call_status_name"
        />
      </div>
      <div className="col-md-3 mt-4">
        <button className="btn btn-danger mt-2" onClick={handleCallSearch}>
          Search
        </button>
      </div>
    </div>
  );
};

export default CallLogsFilter;
