// Footer.js
import React from "react";
import { Link } from "react-router-dom";
import styles from "./Footer.module.css";

const Footer = () => {
  return (
    <footer className={`footer py-3 bg-dark`}>
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <Link to="#" className={styles.links}>
              FAQ's
            </Link>
          </div>
          <div className="col-md-6">
            <div className="row">
              <div className="col-sm-6">
                <Link to="#" className={styles.links}>
                  Terms & Condition
                </Link>
              </div>
              <div className="col-sm-6">
                <p className={styles.rightContent}>
                  &copy; 2024 Motul | All right reserved
                </p>
                <p className={styles.version}>{`V.3.3`}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
