import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import appConfig from "../AppConfig";

const initialState = {
  loading: false,
  data: [],
  error: "",
};


export const getLanguageListData = createAsyncThunk(
  "getLanguageListData",
  async (data, { rejectWithValue }) => {
    const urls = `${appConfig.apiUrl}appLangList`;
    const headers = {
      "X-API-Key": "vEWn5pjROii9Tn0AR8edPuDb3rnXfoUs",
    };
    const response = await axios.get(urls, { headers: headers });
    try {
      const result = response.data;
      console.log("language list",result);
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);


export const userLanguageListDetails = createSlice({
  name: "userLanguageListDetails",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getLanguageListData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getLanguageListData.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.error = "";
    });
    builder.addCase(getLanguageListData.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = action.error.message;
    });
  },
});


export default userLanguageListDetails.reducer;
