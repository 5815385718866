import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { Common } from "../../../component/common/common";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getGroupData } from "../../../features/groupSlice";
import { getBrandList } from "../../../features/brandSlice";
import { getSubCategoryList } from "../../../features/subcategorySlice";
import { getProductList } from "../../../features/productSlice";
import { getPackSizeList } from "../../../features/packsizeSlice";
import { generateCodeData } from "../../../features/generatecodeSlice";
import { getViscosityList } from "../../../features/viscositySlice";

const expiryMonthOptions = [
  { id: 1, expiryMonth: "12 Months", value: "12" },
  { id: 2, expiryMonth: "18 Months", value: "18" },
  { id: 3, expiryMonth: "24 Months", value: "24" },
];

function Coupons() {
  const msLoggedUserId = localStorage.getItem("userId");
  const msLoggedRoleId = localStorage.getItem("roleId");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const groupList = useSelector((state) => state.groupList.data);
  const brandList = useSelector((state) => state.brandList.data);
  const subCategoryList = useSelector((state) => state.subCategoryList.data);
  const productList = useSelector((state) => state.productList.data);
  const packSizeList = useSelector((state) => state.packSizeList.data);
  const viscosityList = useSelector((state) => state.viscosityList.data);

  // selectItems
  const [groupSelectedItem, setGroupSelectedItem] = useState("");
  const [brandSelectedItem, setBrandSelectedItem] = useState("");
  const [subCategorySelectedItem, setSubCategorySelectedItem] = useState("");
  const [productNameSelectedItem, setProductNameSelectedItem] = useState("");
  const [packSizeSelectedItem, setPackSizeSelectedItem] = useState("");
  const [viscositySelectedItem, setViscositySelectedItem] = useState("");

  const [expiryDateSelectedItem, setExpiryDateSelectedItem] = useState("");
  const [submitted, setSubmitted] = useState(false);

  const [formData, setFormData] = useState({
    pointsAssigned: 1,
    quantity: 1,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    let validatedValue = value;
    const defaultPointAssgVal = 500;
    const defaultQuantityVal = 500000;

    let numericValue = Number(validatedValue);

    // Validate "pointsAssigned" and ensure it does not exceed 500
    if (name === "pointsAssigned" && numericValue > 500) {
      validatedValue = "1"; // Set to max if over limi
      Swal.fire({
        title: "Alert",
        text: `Point Assigned cannot exceed ${defaultPointAssgVal}`,
        icon: "error",
        confirmButtonText: "Okay",
      });
    }

    // Validate "quantity" and ensure it does not exceed 500000
    if (name === "quantity" && numericValue > 500000) {
      validatedValue = "1"; // Set to max if over limit
      Swal.fire({
        title: "Alert",
        text: `Number of codes cannot exceed ${defaultQuantityVal}`,
        icon: "error",
        confirmButtonText: "Okay",
      });
    }

    // Update form data with the validated value
    setFormData({
      ...formData,
      [name]: validatedValue,
    });
  };

  useEffect(() => {
    dispatch(getGroupData(msLoggedRoleId));
  }, [dispatch, msLoggedRoleId]);

  useEffect(() => {
    dispatch(getBrandList({ groupSelectedItem, msLoggedRoleId }));
  }, [dispatch, groupSelectedItem, msLoggedRoleId]);

  useEffect(() => {
    dispatch(getSubCategoryList({ brandSelectedItem, msLoggedRoleId }));
  }, [dispatch, brandSelectedItem, msLoggedRoleId]);

  useEffect(() => {
    dispatch(
      getProductList({
        brandSelectedItem,
        subCategorySelectedItem,
        msLoggedRoleId,
      })
    );
  }, [dispatch, brandSelectedItem, subCategorySelectedItem, msLoggedRoleId]);

  useEffect(() => {
    dispatch(getPackSizeList({ productNameSelectedItem, msLoggedRoleId }));
  }, [dispatch, productNameSelectedItem, msLoggedRoleId]);

  useEffect(() => {
    dispatch(getViscosityList({ packSizeSelectedItem, msLoggedRoleId }));
  }, [dispatch, packSizeSelectedItem, msLoggedRoleId]);

  // handle
  const handleGroupChange = (e) => {
    setGroupSelectedItem(e.target.value);
  };
  const handleBrandChange = (e) => {
    setBrandSelectedItem(e.target.value);
  };
  const handleSubCategoryChange = (e) => {
    setSubCategorySelectedItem(e.target.value);
  };
  const handleProductNameChange = (e) => {
    setProductNameSelectedItem(e.target.value);
  };
  const handlePackSizeChange = (e) => {
    setPackSizeSelectedItem(e.target.value);
  };

  const handleViscosityChange = (e) => {
    setViscositySelectedItem(e.target.value);
  };

  const handleExpiryDateChange = (e) => {
    setExpiryDateSelectedItem(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const postData = {
      msLoggedUserId: msLoggedUserId,
      msLoggedRoleId: msLoggedRoleId,
      group: groupSelectedItem,
      brand: brandSelectedItem,
      subcategaory: subCategorySelectedItem,
      productname: productNameSelectedItem,
      packsize: packSizeSelectedItem,
      viscosity: viscositySelectedItem,
      pointsassigned: formData.pointsAssigned,
      qty: formData.quantity,
      expiryMonth: expiryDateSelectedItem,
    };
    setSubmitted(true);
    dispatch(generateCodeData(postData));
  };

  useEffect(() => {
    if (submitted) {
      navigate("/data-processing");
    }
  }, [navigate, submitted]);

  return (
    <div className="container" style={{ minHeight: "81.8vh" }}>
      <div className="row mt-4">
        <div className="col-md-12 mt-4">
          <h2 className="text-capitalize fs-5 fst-italic mt-3">
            Generate Code
          </h2>
        </div>
        <div className="col-md-6">
          <div className="row">
            <div className="col-sm-12 mt-2">
              <Common.SelectInputId
                options={groupList}
                label={"Select Group"}
                labelKey="group"
                valueKey="groupId"
                value={groupSelectedItem}
                onChange={handleGroupChange}
              />
            </div>
            <div className="col-sm-12 mt-2">
              <Common.SelectInputId
                options={brandList}
                label={"Select Brand"}
                labelKey="brand"
                valueKey="brandId"
                value={brandSelectedItem}
                onChange={handleBrandChange}
              />
            </div>
            <div className="col-sm-12 mt-2">
              <Common.SelectInputId
                options={subCategoryList}
                label={"Select Sub-category"}
                labelKey="subCategory"
                valueKey="subId"
                value={subCategorySelectedItem}
                onChange={handleSubCategoryChange}
              />
            </div>
            <div className="col-sm-12 mt-2">
              <Common.SelectInputId
                options={productList}
                label={"Select Product Name"}
                labelKey="productName"
                valueKey="pId"
                value={productNameSelectedItem}
                onChange={handleProductNameChange}
              />
            </div>
            <div className="col-sm-12 mt-2">
              <Common.SelectInputId
                options={packSizeList}
                label={"Select Pack Size"}
                labelKey="packSize"
                valueKey="packId"
                value={packSizeSelectedItem}
                onChange={handlePackSizeChange}
              />
            </div>
            <div className="col-sm-12 mt-2">
              <Common.SelectInputId
                options={viscosityList}
                label={"Select Viscosity"}
                labelKey="viscosity"
                valueKey="viscoId"
                value={viscositySelectedItem}
                onChange={handleViscosityChange}
              />
            </div>
            <div className="col-sm-12 mt-2">
              <Common.TextInput
                type={"number"}
                label={"Point Assigned"}
                min={1}
                max={500}
                placeholder={"Enter Point Assigned"}
                name="pointsAssigned"
                value={formData.pointsAssigned}
                onChange={handleChange}
              />
            </div>
            <div className="col-sm-12 mt-2">
              <Common.TextInput
                type={"number"}
                label={"Number of codes"}
                min={1}
                max={500000}
                placeholder={"Enter Quantity"}
                name="quantity"
                value={formData.quantity}
                onChange={handleChange}
              />
            </div>
            <div className="col-sm-12 mt-2">
              <Common.SelectInputId
                options={expiryMonthOptions}
                label={"Expiry Month"}
                labelKey="expiryMonth"
                valueKey="value"
                value={expiryDateSelectedItem}
                onChange={handleExpiryDateChange}
              />
            </div>
            <div className="col-sm-12 mt-3 mb-5">
              <button
                onClick={handleSubmit}
                className={
                  !submitted &&
                  groupSelectedItem &&
                  brandSelectedItem &&
                  subCategorySelectedItem &&
                  productNameSelectedItem &&
                  packSizeSelectedItem &&
                  viscositySelectedItem &&
                  expiryDateSelectedItem
                    ? "btn btn-danger"
                    : "btn btn-secondary"
                }
                disabled={
                  !submitted &&
                  groupSelectedItem &&
                  brandSelectedItem &&
                  subCategorySelectedItem &&
                  productNameSelectedItem &&
                  packSizeSelectedItem &&
                  viscositySelectedItem &&
                  expiryDateSelectedItem
                    ? false
                    : true
                }
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Coupons;
