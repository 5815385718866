import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import appConfig from '../AppConfig';
import { format } from "date-fns";


// Thunk to fetch tier configurations 
export const fetchTierConfigurations = createAsyncThunk(
  'tierConfiguration/fetchTierConfigurations',
  async ({ msLoggedUserId, msLoggedRoleId, token, monthYear, length,  tierSlabType, subCategory, state, district, tierStatus,startIndex }, { rejectWithValue }) => {
    // console.log("this is startDate and endDate", startDate,endDate);

    // const fromDate = startDate ? new Date(startDate).toISOString().split('T')[0] : '';
    // const toDate = endDate ? new Date(endDate).toISOString().split('T')[0] : '';
    
    // console.log("this is fromDate and toDate", fromDate, toDate);
    
    
    // console.log("this is fromDate and toDate",fromDate,toDate)
    const url = `${appConfig.apiUrl}msViewTierConfigReport?token=${token}&msLoggedUserId=${msLoggedUserId}&msLoggedRoleId=${msLoggedRoleId}&tierSlabType=${tierSlabType}&subCategory=${subCategory}&state=${state}&district=${district}&tierStatus=${tierStatus}&monthYear=${monthYear}&start=${startIndex}&length=10`;
    const headers = { 'X-API-Key': appConfig.apiKey };
    try {
      const response = await axios.get(url, { headers });

      console.log('API Response addTierConfiguration', response.data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error.message);
    }
  }
);

// Thunk to add tier configuration
export const addTierConfiguration = createAsyncThunk(
  'tierConfiguration/addTierConfiguration',
  async ({ token, msLoggedUserId, msLoggedRoleId, configurationData }, { rejectWithValue }) => {
    console.log("this is userId",msLoggedRoleId)
    console.log("this is userId",msLoggedUserId)

    console.log("this is token",token)
    console.log("this is configurationData",configurationData)
    // Prepare state and district arrays as repeated fields in the data payload
    const form_Data = new FormData();
    form_Data.append("tierName",configurationData.tiername);
    form_Data.append("subCategory",configurationData.productSubcategory);
    form_Data.append("tierSlabType",configurationData.slabType)
    form_Data.append("remarks",configurationData.remarks)
    // form_Data.append("pointScanValue",configurationData.pointValue)
    form_Data.append("bonusPercentage",configurationData.bonusPercent)
    form_Data.append("monthYear",configurationData.monthYear)
    
    
    if(configurationData.slabType === "Points"){
      form_Data.append("pointScanValue",configurationData.Points)
    }
    else if(configurationData.slabType === "Scans") {
      form_Data.append("pointScanValue",configurationData.Scans)
    }
      
      // Include token and user IDs as separate fields
      form_Data.append('token', token);
      form_Data.append('msLoggedUserId', msLoggedUserId);
      form_Data.append('msLoggedRoleId', msLoggedRoleId);

    // Add repeated state and district fields
    configurationData.state.forEach((st) => {
      form_Data.append("state[]", st);  // Append each state individually
    });
    configurationData.district.forEach((dist) => {
      form_Data.append("district[]", dist);  // Append each district individually
    });
    
    for (let pair of form_Data.entries()) {
      console.log(pair[0], pair[1]);
    }
    

    const url = `${appConfig.apiUrl}msAddTierConfiguration`;
    const headers = {
      'X-API-Key': appConfig.apiKey,
      'Content-Type': 'application/x-www-form-urlencoded'
    };
    const payload = {
      token,
      msLoggedUserId,
      msLoggedRoleId,
      form_Data
    };

    try {
      const response = await axios.post(url, form_Data, { headers });
      console.log('Post Response addTierConfiguration ', response.data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error.message);
    }
  }
);

const tierConfigurationSlice = createSlice({
  name: 'tierConfiguration',
  initialState: {
    configurations: [],
    loading: false,
    error: null,
    submissionStatus: null
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTierConfigurations.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchTierConfigurations.fulfilled, (state, action) => {
        console.log('Fetch Action Payload:', action.payload);
        state.loading = false;
        state.configurations = action.payload || [];
      })
      .addCase(fetchTierConfigurations.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(addTierConfiguration.pending, (state) => {
        state.loading = true;
        state.submissionStatus = 'pending';
      })
      .addCase(addTierConfiguration.fulfilled, (state, action) => {
        console.log('Send Action Payload:', action.payload);
        state.loading = false;
        state.submissionStatus = 'success';
      })
      .addCase(addTierConfiguration.rejected, (state, action) => {
        state.loading = false;
        state.submissionStatus = 'failed';
        state.error = action.payload;
      });
  }
});

export default tierConfigurationSlice.reducer;