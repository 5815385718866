import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import appConfig from "../AppConfig";

const initialState = {
  loading: false,
  data: [],
  error: "",
};

export const getDetailRowCampaignScheme = createAsyncThunk(
  "getDetailRowCampaignScheme",
  async (
    {
      formattedStartDate,
      formattedEndDate,
      startIndex,
      msLoggedUserId,
      msLoggedRoleId,
      token,
      schemeId
    },
    { rejectWithValue }
  ) => {
    const urls = `${appConfig.apiUrl}msDetailRowCampaignScheme?msLoggedUserId=${msLoggedUserId}&msLoggedRoleId=${msLoggedRoleId}&token=${token}&schemeId=${schemeId}&fromDate=${formattedStartDate}&toDate=${formattedEndDate}&start=${startIndex}&length=10`;
    const headers = {
      "X-API-Key": appConfig.apiKey,
    };
    const response = await axios.get(urls, { headers: headers });
    try {
      const result = response.data;
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const detailRowCampaignSchemeData = createSlice({
  name: "detailRowCampaignSchemeData",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getDetailRowCampaignScheme.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getDetailRowCampaignScheme.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.error = "";
    });
    builder.addCase(getDetailRowCampaignScheme.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = action.error.message;
    });
  },
});

export default detailRowCampaignSchemeData.reducer;
