import React, { useState } from "react";
import Common from "../common";
import Button from "../../Button/Button";

const TierConfigFilter = ({
    monthOptions,
    handleMonthChange,
    showMonthYear,
    stateItemSelected,
    handleStateChange,
    stateList,
    districtList,
    districtItemSelected,
    handleDistrictChange,
    showTierStatus,
    tierStatusOptions,
    filters,
    handleStatusChange,
    showSlab,
    slabOptions,
    handleSlabChange,
    showSubcategory,
    subcatOptions,
    handleSubcatChange,
    handleSubmit,
    handleDownload,
    msLoggedRoleId,
    filterPage,
    handleDownloadClickFilter,
}) => {
    const [downloading, setDownloading] = useState(false);

    const handleDownloadClick = async () => {
        setDownloading(true);
        setTimeout(async () => {
            try {
                await handleDownload();
            } catch (error) {
                console.error("Error downloading file:", error);
            }
            setDownloading(false);
        }, 1000);
    };

    return (
        <div className="row">
            {showMonthYear && (
                <div className="col-md-2 col-sm-6 col-12 mt-2">
                    <Common.SelectInput
                        onChange={handleMonthChange}
                        options={monthOptions}
                        label={"Month & Year"}
                        labelKey="label"
                        valueKey="value"
                    />
                </div>
            )}

            <div className="col-md-2 col-sm-6 col-12 mt-2">
                <Common.SelectInput
                    value={stateItemSelected}
                    onChange={handleStateChange}
                    options={stateList}
                    label={"State"}
                    labelKey="state"
                    valueKey="id"
                />
            </div>

            <div className="col-md-2 col-sm-6 col-12 mt-2">
                <Common.SelectInput
                    options={districtList}
                    label={"District"}
                    labelKey="district"
                    valueKey="id"
                    value={districtItemSelected}
                    onChange={handleDistrictChange}
                />
            </div>

            {showTierStatus && (
                <div className="col-md-2 col-sm-6 col-12 mt-2">
                    <Common.SelectInput
                        options={tierStatusOptions}
                        label={"Status"}
                        labelKey="label"
                        valueKey="value"
                        value={filters.tierStatus}
                        onChange={handleStatusChange}
                    />
                </div>
            )}

            {showSlab && (
                <div className="col-md-2 col-sm-6 col-12 mt-2">
                    <Common.SelectInput
                        options={slabOptions}
                        label={"Slab Type"}
                        labelKey="label"
                        valueKey="value"
                        value={filters.tierSlabType}
                        onChange={handleSlabChange}
                    />
                </div>
            )}

            {showSubcategory && (
                <div className="col-md-2 col-sm-6 col-12 mt-2">
                    <Common.SelectInput
                        options={subcatOptions}
                        label={"Subcategory"}
                        labelKey="label"
                        valueKey="value"
                        value={filters.subCategory}
                        onChange={handleSubcatChange}
                    />
                </div>
            )}

            {/* Submit Button */}
            <div className="col-md-1 col-sm-6 col-12 mt-4" >
                <Button variant="submit" onClick={handleSubmit} style={{marginTop:"0px"}}>
                    Submit
                </Button>
            </div>

            {/* Download Button */}
            {msLoggedRoleId === "1" && (
                <div className="col-md-1 col-sm-6 col-12 mt-4">
                    {filterPage !== "transaction" ? (
                        <Button
                            variant="download"
                            onClick={handleDownloadClick}
                            disabled={downloading}
                        >
                            {downloading ? "Downloading..." : "Download"}
                        </Button>
                    ) : (
                        <Button variant="download" onClick={handleDownloadClickFilter}>
                            Download
                        </Button>
                    )}
                </div>
            )}
        </div>
    );
};

export default TierConfigFilter;
