import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getHistoricTransactionData } from "../../../../features/historicTransactionSlice";
import Common from "../../../../component/common/common";
import { Spinner } from "react-bootstrap";

const HistoricTransactionReport = () => {
  const msLoggedUserId = localStorage.getItem("userId");
  const msLoggedRoleId = localStorage.getItem("roleId");
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.historicTransactionList.data);
  const loading = useSelector((state) => state.historicTransactionList.loading);
  console.log("loading>>>>", loading);
  const totalRecords = useSelector(
    (state) => state.historicTransactionList.data.totalRecords
  );
  const message = useSelector(
    (state) => state.historicTransactionList.data.message
  );

  const [currentPage, setCurrentPage] = useState(1);

  // table columns
  const columns = [
    "S.No.",
    "Mobile Number",
    "Coupon Code",
    "Points",
    "Bonus Points",
    "Transaction Date",
  ];
  // table data
  const tableData = data
    ? data.map((item, index) => {
        return {
          "S.No.": (currentPage - 1) * 10 + index + 1,
          "Mobile Number": item.mobileno,
          "Coupon Code": item.coupon_code,
          Points: item.points,
          "Bonus Points": item.bonus_points,
          "Transaction Date": item.transaction_date,
        };
      })
    : [];

  const itemsPerPage = 10;
  const totalPages = Math.ceil(totalRecords / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  useEffect(() => {
    dispatch(
      getHistoricTransactionData({
        startIndex,
        msLoggedUserId,
        msLoggedRoleId,
      })
    );
  }, [dispatch, currentPage, startIndex, msLoggedUserId, msLoggedRoleId]);

  return (
    <div className="container" style={{ minHeight: "81.8vh" }}>
      <div className="row">
        <div className="col-md-12 mt-5">
          <h2 className="text-capitalize fs-5 fst-italic text-capitalize">
            Historic Transaction Report
          </h2>
        </div>
        {!loading ? (
          <div className="col-md-12 mt-3">
            <Common.CustomTable
              columns={columns}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              data={tableData}
              itemsPerPage={itemsPerPage}
              totalPages={totalPages}
              startIndex={startIndex}
              endIndex={endIndex}
              totalRecords={totalRecords}
              message={message}
            />
          </div>
        ) : (
          <div className="col-md-12 mt-3 d-flex justify-content-center">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        )}
      </div>
    </div>
  );
};

export default HistoricTransactionReport;
