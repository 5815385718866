import React from "react";

const ProfileDetails = ({ userData }) => {
  return (
    <div>
      <p>
        <strong>User Code:</strong> {userData.userCode}
      </p>
      <p>
        <strong>User Name:</strong> {userData.name}
      </p>
      <p>
        <strong>DOB:</strong> {userData.userDob}
      </p>
      <p>
        <strong>City:</strong> {userData.userCity}
      </p>
      <p>
        <strong>State:</strong> {userData.userState}
      </p>
      <p>
        <strong>District:</strong> {userData.userDistrict}
      </p>
      <p>
        <strong>Pincode:</strong> {userData.userPincode}
      </p>
      <p>
        <strong>Mobile Number:</strong> {userData.mobileno}
      </p>
      <p>
        <strong>User Type:</strong> {userData.userRole}
      </p>
      <p>
        <strong>Preferred Language:</strong> {userData.userLangauage}
      </p>
      <p>
        <strong>User Workshop:</strong> {userData.userWorkshop}
      </p>
      <p>
        <strong>TH name:</strong> {userData.thName}
      </p>
      <p>
        <strong>TH Mobile no.:</strong> {userData.thMobile}
      </p>
      <p>
        <strong>SH name:</strong> {userData.shName}
      </p>
      <p>
        <strong>SH Mobile no.:</strong> {userData.shMobile}
      </p>
      <p>
        <strong>RH name:</strong> {userData.rhName}
      </p>
      <p>
        <strong>RH Mobile no.:</strong> {userData.rhMobile}
      </p>
    </div>
  );
};

export default ProfileDetails;
