import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import appConfig from "../AppConfig";

const initialState = {
  data: [],
  loading: false,
  error: "",
  message: "",
  status: "",
};
// getGenerateCodeData action
export const generateCodeData = createAsyncThunk(
  "generateCodeData",
  async (data, { rejectWithValue }) => {
    const urls = `${appConfig.apiUrl}msCodeGeneration`;
    const logintoken = localStorage.getItem("logintoken");
    const headers = {
      "X-API-Key": appConfig.apiKey,
      "Content-Type": "application/x-www-form-urlencoded",
    };
    const payload = {
      ...data,
      token: logintoken, 
    };
    const response = await axios.post(urls, payload, { headers: headers });
    try {
      const result = await response.data;
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const generatedCodeDetails = createSlice({
  name: "generatedCodeDetails",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(generateCodeData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(generateCodeData.fulfilled, (state, action) => {
      state.loading = false;
      state.data.push(action.payload);
      state.message = action.payload.message;
      state.status = action.payload.status;
      state.error = "";
    });
    builder.addCase(generateCodeData.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = action.error.message;
    });
  },
});

export default generatedCodeDetails.reducer;
