import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import appConfig from "../AppConfig";

const initialState = {
  data: [],
  loading: false,
  error: "",
};

export const downloadCodeGeneratedReport = createAsyncThunk(
  "downloadCodeGeneratedReport",
  async ({ msLoggedUserId, msLoggedRoleId }, { rejectWithValue }) => {
    const logintoken = localStorage.getItem("logintoken");
    const urls = `${appConfig.apiUrl}msCouponGenerateReportDownload?requestFrom=Web&msLoggedUserId=${msLoggedUserId}&msLoggedRoleId=${msLoggedRoleId}&token=${logintoken}`;
    const headers = {
      "X-API-Key": appConfig.apiKey,
    };
    const response = await axios.get(urls, {
      headers: headers,
      responseType: "blob",
    });
    try {
      const result = await response.data;
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const codeGeneratedDownloadReportDetails = createSlice({
  name: "codeGeneratedDownloadReportDetails",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(downloadCodeGeneratedReport.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(downloadCodeGeneratedReport.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(downloadCodeGeneratedReport.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = action.error.message;
    });
  },
});

export default codeGeneratedDownloadReportDetails.reducer;
