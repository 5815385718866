import React, { useState, useEffect, useRef } from 'react';
import { Form, Row, Col, Button, Container } from 'react-bootstrap';
import './campaignpage.css';
import Common from '../../component/common/common';
import { getcampaignPageData } from '../../features/campaignPageSlice';
import { useDispatch, useSelector } from "react-redux";
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { getStateData } from '../../features/stateSlice';
import { getDistrictData } from '../../features/districtSlice';
import Swal from "sweetalert2";
import { Select, Space } from 'antd';
import styles from '../Dashboard/Dashboard.module.css';
import { getGroupData } from '../../features/groupSlice';
import { getBrandList } from '../../features/brandSlice';
import { getSubCategoryList } from '../../features/subcategorySlice';
import { getProductList } from '../../features/productSlice';


const CampaignBasedContestForm = () => {

    const msLoggedUserId = localStorage.getItem("userId");
    const msLoggedRoleId = localStorage.getItem("roleId");
    const token = localStorage.getItem("logintoken");
    const dispatch = useDispatch();
    const [uniqueId, setUniqueid] = useState("");
    console.log("uniqueIddddddddd", uniqueId);
    const [stageSelected, setstageSelected] = useState("");
    const [userroleSelected, setUserroleSelected] = useState("");
    console.log("userroleSelectedddddddddd", userroleSelected);
    const [groupnameSelected, setGroupnameSelected] = useState("");
    const [brandnameSelected, setBrandnameSelected] = useState("");
    const [subcategorySelected, setSubcategorySelected] = useState("");
    const [productSelected, setProductSelected] = useState("");
    const [bonusbenefitSelected, setBonusbenefitSelected] = useState("");
    const [selectedFile, setSelectedFile] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const stateList = useSelector((state) => state.stateList.data);
    const districtList = useSelector((state) => state.districtList.data);
    const [stateItemSelected, setStateItemSelected] = useState([]);
    const [districtItemSelected, setDistrictItemSelected] = useState([]);
    const groupList = useSelector((state) => state.groupList.data);
    const brandList = useSelector((state) => state.brandList.data);
    const subCategoryList = useSelector((state) => state.subCategoryList.data);
    const productList = useSelector((state) => state.productList.data);

    console.log("selected state items", stateItemSelected);
    console.log("selected district items", districtItemSelected);


    const benefitList = [
        { id: 1, name: "Fixed" },
        { id: 2, name: "Multiplier" },
        { id: 3, name: "Percentage" },
        { id: 4, name: "Litre" },
    ];

    useEffect(() => {
        dispatch(getStateData({ msLoggedUserId, msLoggedRoleId }));
    }, []);

    useEffect(() => {
        dispatch(getGroupData(msLoggedRoleId));
    }, [dispatch, msLoggedRoleId]);

    useEffect(() => {
        if (groupnameSelected && groupList.length > 0) { // Ensure groupList has data
            const selectedGroup = groupList.find((group) => group.group === groupnameSelected);

            if (selectedGroup) {
                const groupSelectedItem = selectedGroup.groupId;
                dispatch(getBrandList({ groupSelectedItem, msLoggedRoleId }));
            }
        }
    }, [dispatch, groupnameSelected, groupList, msLoggedRoleId]);


    useEffect(() => {
        if (brandnameSelected && brandList.length > 0) { // Ensure groupList has data
            const selectedBrand = brandList.find((group) => group.brand === brandnameSelected);
            if (selectedBrand) {
                const brandSelectedItem = selectedBrand.brandId;
                dispatch(getSubCategoryList({ brandSelectedItem, msLoggedRoleId }));
            }
        }
    }, [dispatch, brandnameSelected, msLoggedRoleId]);



    useEffect(() => {

        if (brandnameSelected && brandList.length > 0 && subcategorySelected && subCategoryList.length > 0) { // Ensure groupList has data
            const selectedBrand = brandList.find((group) => group.brand === brandnameSelected);
            const selectedSubcategory = subCategoryList.find((group) => group.subCategory === subcategorySelected);

            if (selectedBrand && selectedSubcategory) {
                console.log("selectedBrand in useEffect:", selectedSubcategory);
                const brandSelectedItem = selectedBrand.brandId;
                const subCategorySelectedItem = selectedSubcategory.subId;
                console.log("subCategorySelectedItem before dispatch", subCategorySelectedItem);
                dispatch(
                    getProductList({
                        brandSelectedItem,
                        subCategorySelectedItem,
                        msLoggedRoleId,
                    })
                );
            }
        }
    }, [dispatch, brandnameSelected, subcategorySelected, msLoggedRoleId]);

    const stateOptionsWithIds = stateList.map((item) => ({
        label: item.state,
        value: item.id
    }));

    const handleStateChange = (selectedIds) => {
        const selectedStateNames = selectedIds.map(id =>
            stateList.find(state => state.id === id)?.state
        );

        setStateItemSelected(selectedStateNames);

        // Dispatch the action to get district data
        if (selectedStateNames.length > 0) {
            dispatch(getDistrictData({
                msLoggedUserId,
                msLoggedRoleId,
                selectedStates: selectedStateNames
            }));
        }
    };

    const handleDistrictChange = (selectedValues) => {
        setDistrictItemSelected(selectedValues);
    };

    const selectedStateIds = stateItemSelected.map(name =>
        stateList.find(item => item.state === name)?.id
    );

    const data = useSelector(
        (state) => state.campaignPageData.data[0]
    );
    const message = useSelector(
        (state) => state.campaignPageData.message
    );
    const status = useSelector(
        (state) => state.campaignPageData.status
    );


    const validCount = data && data.validCount;
    const inValidCount = data && data.invalidCount;


    const [formData, setFormData] = useState({
        campaignType: "",
        campaignName: "",
        benefitType: "",
        userSelection: "",
        reward: '',
        volumeFrom: "",
        volumeTo: "",
        scanFrom: "",
        scanTo: "",
        userPreference: "",
        type: "",
        limit: "",
        conditionCamp: ""
    });

    console.log("Range condition", formData.conditionCamp);

    const [startDate, setStartDate] = useState(() => {
        const today = new Date();
        today.setMonth(today.getMonth() - 1);
        return today;
    });
    console.log("start date", startDate);

    const [endDate, setEndDate] = useState("");

    const formattedStartDate = startDate
        ? startDate.toLocaleDateString("en-CA", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
        })
        : "";


    const formattedEndDate = endDate
        ? endDate.toLocaleDateString("en-CA", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
        })
        : "";

    console.log("formdata start date", formData.start_Date);


    useEffect(() => {
        if (submitted && status !== undefined && message) {
            setLoading(false); // Stop loading indicator
            if (status) {
                Swal.fire({
                    title: message,
                    icon: "success",
                    confirmButtonText: "Okay",
                });
                setTimeout(() => {
                    navigate(
                        `/campaign-valid-invalid?Id=${uniqueId}&valid=${validCount}&invalid=${inValidCount}`
                    );
                }, 2000);
            } else {
                Swal.fire({
                    title: message,
                    icon: "error",
                    confirmButtonText: "Okay",
                });
            }
            setSubmitted(false);
        }
    }, [message, status, submitted]);


    useEffect(() => {

        const fetchToken = async () => {
            try {
                const response = await axios.get('https://motulstage.firsthive.com/msCampUserUploadUniqueCode', {
                    headers: {
                        'X-API-Key': 'sZXgqa2XhspBxJO8mQT3gqoBu0urVGjw'
                    },
                });
                const uniqueId = response.data.data.uniqueId;// Assuming the token is in the response data
                setUniqueid(uniqueId);
            } catch (error) {
                console.error("Error fetching token:", error);
            }
        };

        fetchToken();
    }, []);

    const handleuserSelectionChange = (e) => {
        const { name, value } = e.target;
         // Reset all dropdown selections
         setUserroleSelected("");
         setGroupnameSelected("");
         setBrandnameSelected("");
         setSubcategorySelected("");
         setProductSelected("");
         setBonusbenefitSelected("");
         setStateItemSelected([]);
         setDistrictItemSelected([]);
 
         setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value // Dynamically update the formData based on the input's name attribute
        }));

    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        // Update user selection
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value // Dynamically update the formData based on the input's name attribute
        }));
    };


    const handleUserroleChange = (e) => {
        setUserroleSelected(e.target.value);
    };

    const handleGroupnameChange = (e) => {
        setGroupnameSelected(e.target.value);
    };

    const handleBrandnameChange = (e) => {
        setBrandnameSelected(e.target.value);
    };

    const handleSubcategoryChange = (e) => {
        setSubcategorySelected(e.target.value);
    };

    const handleProductChange = (e) => {
        setProductSelected(e.target.value);
    };

    const handleBonusbenefitChange = (e) => {
        setBonusbenefitSelected(e.target.value);
    };

    const fileInputRef = useRef(null);

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setSelectedFile(file);
    };


    const handleNumericInput = (e) => {
        const input = e.target.value.replace(/[^0-9]/g, '');
        setFormData(prevState => ({
            ...prevState,
            reward: input
        }));
    };

    const handleStageChange = () => {
        setstageSelected();
    };

    const stageList = [
        { id: 1, name: "Open" },
        { id: 2, name: "Assigned to IT" },
        { id: 3, name: "Assigned to CCU" },
        { id: 4, name: "Assigned to AMC" },
        { id: 5, name: "Assigned back(CCU)" },
    ];

    const selectuserrole = [
        { id: 1, name: "All" },
        { id: 2, name: "Mechanic" },
        { id: 3, name: "Retailer" },
        { id: 4, name: "Customer" },
    ];

    const groupname = [
        { id: 1, name: "All" },
        { id: 2, name: "Mineral" },
    ];

    const brandname = [
        { id: 1, name: "All" },
        { id: 2, name: "All Gear" },
    ];

    const subcategory = [
        { id: 1, name: "All" },
        { id: 2, name: "Gear Oil" },
    ];

    const product = [
        { id: 1, name: "All" },
        { id: 2, name: "HD 85W140 (4 X 5 LTR)" },
    ];

    const bonusbenefit = [
        { id: 1, name: "All" },
        { id: 2, name: "Fixed" },
    ];

    const selectedDistrictNames = districtItemSelected.map(id =>
        districtList.find(district => district.id === id)?.district
    );

    console.log("selectedDistrictNames", selectedDistrictNames);

    const handleDropdownChange = (e) => {
        const { value } = e.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            conditionCamp: value, // Update conditionCamp field
        }));
    };

    console.log("formData.benefitType", formData.benefitType);


    useEffect(() => {
        resetFields();
    }, []);

    const resetFields = () => {
        setUniqueid("");
        setstageSelected("");
        setUserroleSelected("");
        setGroupnameSelected("");
        setBrandnameSelected("");
        setSubcategorySelected("");
        setProductSelected("");
        setBonusbenefitSelected("");
        setStateItemSelected([]);
        setDistrictItemSelected([]);
        setFormData({
            campaignType: "",
            campaignName: "",
            benefitType: "",
            userSelection: "",
            reward: '',
            volumeFrom: "",
            volumeTo: "",
            scanFrom: "",
            scanTo: "",
            userPreference: "",
            type: "",
            limit: "",
            conditionCamp: ""
        });
        setStartDate(new Date());
        setEndDate("");
        setSelectedFile(null);
    };


    const handleSubmit = () => {
        setLoading(true);
        const form_Data = new FormData();
        form_Data.append('msLoggedUserId', msLoggedUserId);
        form_Data.append('msLoggedRoleId', msLoggedRoleId);
        form_Data.append('uniqueId', uniqueId);
        form_Data.append('token', token);
        form_Data.append('campType', formData.campaignType);
        form_Data.append('contestName', formData.campaignName);
        form_Data.append('userSel', formData.userSelection);
        form_Data.append('userFile', selectedFile); // Append the binary file
        form_Data.append('benefitType', formData.benefitType);
        form_Data.append('startDate', formattedStartDate);
        form_Data.append('endDate', formattedEndDate);
        form_Data.append('bonusbenefit', bonusbenefitSelected);
        form_Data.append('userRole', userroleSelected);
        form_Data.append('reward', formData.reward);
        form_Data.append('qtyFrom', formData.volumeFrom);
        form_Data.append('qtyTo', formData.volumeTo);
        form_Data.append('conditionCamp', formData.conditionCamp);
        form_Data.append('volFrom', formData.scanFrom);
        form_Data.append('volTo', formData.scanTo);
        form_Data.append('groupName', groupnameSelected);
        form_Data.append('brandName', brandnameSelected);
        form_Data.append('subCatName', subcategorySelected);
        form_Data.append('productName', productSelected);
        stateItemSelected.forEach((state) => {
            form_Data.append('state[]', state);
        });
        selectedDistrictNames.forEach((district) => {
            form_Data.append('district[]', district);
        });
        console.log("Dispatching with payload:", form_Data);
        dispatch(getcampaignPageData(form_Data));
        setSubmitted(true);

    };



    return (

        <div className="container mt-5" style={{ minHeight: "81.8vh", width: '1000px' }}>
            <Form >
                {/* Campaign Type */}
                <Form.Group as={Row} className="mb-3 align-items-center">
                    <Form.Label column sm={4} className='label-camp'>Campaign Type</Form.Label>
                    <Col sm={8}>
                        <Form.Check
                            inline
                            label="Campaign Contest"
                            name="campaignType"
                            type="radio"
                            id="Campaign"
                            value="Campaign"
                            onChange={handleChange}
                            checked={formData.campaignType === "Campaign"}
                        />
                        <Form.Check
                            inline
                            label="Lucky Draw"
                            name="campaignType"
                            type="radio"
                            id="Lucky Draw"
                            value="Lucky Draw"
                            onChange={handleChange}
                            checked={formData.campaignType === "Lucky Draw"}
                        />
                    </Col>
                </Form.Group>


                {/* Campaign Name */}
                <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm={4} className='label-camp'>Campaign Contest/ Lucky Draw Name:</Form.Label>
                    <Col sm={8}>
                        <Form.Control type="text" placeholder="Enter Contest Name" name="campaignName" onChange={handleChange} />
                    </Col>
                </Form.Group>

                {/* Benefit Type */}
                <Form.Group as={Row} className="mb-3 align-items-center">
                    <Form.Label column sm={4} className='label-camp'>Benefit Type</Form.Label>
                    <Col sm={8}>
                        <Form.Check
                            inline
                            label="Instant"
                            name="benefitType"
                            type="radio"
                            id="instant"
                            value="Instant"
                            onChange={handleChange}
                        />
                        <Form.Check
                            inline
                            label="Duration"
                            name="benefitType"
                            type="radio"
                            id="durationBased"
                            value="Duration"
                            onChange={handleChange}
                        />
                    </Col>
                </Form.Group>

                {/* Start Date and End Date */}
                <Row className="mb-3">
                    <Col>
                        <Common.CustomDatePicker
                            label={"From Date"}
                            dateFormat={"dd-MM-yyyy"}
                            selected={startDate}
                            onChange={(date) => setStartDate(date)}
                            placeholderText="dd-yy-yyyy"
                        />
                    </Col>
                    <Col>
                        <Common.CustomDatePicker
                            label="To Date"
                            dateFormat={"dd-MM-yyyy"}
                            selected={endDate}
                            onChange={(date) => setEndDate(date)}
                            placeholderText="dd-yy-yyyy"
                        />
                    </Col>
                </Row>


                {/* User Selection */}
                <Form.Group as={Row} className="mb-3 align-items-center">
                    <Form.Label column sm={4} className='label-camp'>User Selection</Form.Label>
                    <Col sm={8}>
                        <Form.Check
                            inline
                            label="Select User"
                            name="userSelection"
                            type="radio"
                            id="selectUser"
                            onChange={handleuserSelectionChange}
                            value="selectUser"
                            checked={formData.userSelection === "selectUser"}
                        />
                        <Form.Check
                            inline
                            label="Upload User"
                            name="userSelection"
                            type="radio"
                            id="Upload"
                            value="Upload"
                            onChange={handleChange}
                            checked={formData.userSelection === "Upload"}
                        />

                    </Col>
                </Form.Group>



                {formData.userSelection === "Upload" && (

                    <Form.Group as={Row} className="mb-4">
                        <Form.Label column sm={4} className="label-camp">
                            Select File
                        </Form.Label>
                        <Col sm={8}>
                            <Common.FileInput
                                onChange={handleFileChange}
                                inputRef={fileInputRef}
                            />
                            <div className="col-sm-12 mt-3">
                                <a
                                    href={
                                        "https://motulstage.firsthive.com/uploads/samples/Sample_Contest_Users.xlsx"
                                    }
                                    className="text-dark"
                                >
                                    Sample File
                                </a>
                            </div>
                        </Col>
                    </Form.Group>

                )}

                {formData.userSelection !== "Upload" && (

                    <>

                        {/* Dropdowns for user selection */}
                        <Form.Group as={Row} className="mb-4">
                            <Form.Label column sm={4} className='label-camp'>Select User</Form.Label>
                            <Col sm={8}>
                                <Common.SelectInput
                                    options={selectuserrole}
                                    labelKey="name"
                                    valueKey="id"
                                    value={userroleSelected}
                                    onChange={handleUserroleChange}
                                    disabled={formData.userSelection === "Upload"}
                                />
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} className="mb-4">
                            <Col sm={4}>
                                <label className={`${styles.label} form-label`}>Select State</label>
                            </Col>
                            <Col sm={8}>
                                <Space style={{ width: '100%' }} direction="vertical">
                                    <Select
                                        mode="multiple"
                                        allowClear
                                        style={{ width: '100%' }}
                                        placeholder="Please select"
                                        onChange={handleStateChange}
                                        options={stateOptionsWithIds}
                                        value={selectedStateIds}
                                        size="large"

                                    />
                                </Space>
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} className="mb-4">
                            <Col sm={4}>
                                <label className={styles.label}>Select District</label>
                            </Col>
                            <Col sm={8}>
                                <Space style={{ width: '100%', marginTop: '-5px' }} direction="vertical">
                                    <Select
                                        mode="multiple"
                                        allowClear
                                        style={{ width: '100%' }}
                                        placeholder="Please select"
                                        onChange={handleDistrictChange}
                                        options={districtList.map((item) => ({ label: item.district, value: item.id }))}
                                        value={districtItemSelected}
                                        size="large"

                                    />
                                </Space>
                            </Col>
                        </Form.Group>

                    </>

                )}


                <Form.Group as={Row} className="mb-4">
                    <Form.Label column sm={4} className='label-camp'>Group Name</Form.Label>
                    <Col sm={8}>
                        <Common.SelectInput
                            options={groupList}
                            labelKey="group"
                            valueKey="groupId"
                            value={groupnameSelected}
                            onChange={handleGroupnameChange}
                        />
                    </Col>
                </Form.Group>


                <Form.Group as={Row} className="mb-4">
                    <Form.Label column sm={4} className='label-camp'>Brand Name</Form.Label>
                    <Col sm={8}>
                        <Common.SelectInput
                            options={brandList}
                            labelKey="brand"
                            valueKey="brandId"
                            value={brandnameSelected}
                            onChange={handleBrandnameChange}
                        />
                    </Col>
                </Form.Group>


                <Form.Group as={Row} className="mb-4">
                    <Form.Label column sm={4} className='label-camp'>Subcategory Name</Form.Label>
                    <Col sm={8}>
                        <Common.SelectInput
                            options={subCategoryList}
                            labelKey="subCategory"
                            valueKey="subId"
                            value={subcategorySelected}
                            onChange={handleSubcategoryChange}
                        />
                    </Col>
                </Form.Group>


                <Form.Group as={Row} className="mb-4">
                    <Form.Label column sm={4} className='label-camp'>Product Name</Form.Label>
                    <Col sm={8}>
                        <Common.SelectInput
                            options={productList}
                            labelKey="productName"
                            valueKey="pId"
                            value={productSelected}
                            onChange={handleProductChange}
                        />
                    </Col>
                </Form.Group>



                <Form.Group as={Row} className="mb-4">
                    <Form.Label column sm={4} className='label-camp'>Bonus Benefit</Form.Label>
                    <Col sm={8}>
                        <Common.SelectInput
                            options={benefitList}
                            labelKey="name"
                            valueKey="id"
                            value={bonusbenefitSelected}
                            onChange={handleBonusbenefitChange}
                        />
                    </Col>
                </Form.Group>


                <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm={4} className='label-camp'>Reward</Form.Label>
                    <Col sm={8}>
                        <Form.Control
                            type="text"
                            placeholder="Enter Reward"
                            name="reward"
                            value={formData.reward}
                            onInput={handleNumericInput}
                            inputMode="numeric"
                        />
                    </Col>
                </Form.Group>

                {/* Condition Section */}
                <Container className="p-3 mb-5" style={{ border: '1px solid #ccc', width: '100%' }}>
                    <Form>
                        {/* First Row: Bonus Benefit Type and Volume Section */}
                        <Row className="mb-3 align-items-center">
                            <Col xs={2} className="apply-mt-text">
                                <Form.Label>Volume</Form.Label>
                            </Col>
                            <Col xs={2} className="apply-mt">
                                <Form.Select className='size-font'>
                                    <option>Greater than</option>
                                    <option>Less than</option>
                                </Form.Select>
                            </Col>
                            <Col xs={2} className="apply-mt ">
                                <Form.Control type="text" placeholder="From" className='size-font' name='volumeFrom' onChange={handleChange} />
                            </Col>
                            <Col xs={2} className="apply-mt">
                                <Form.Select className='size-font'>
                                    <option>Less than</option>
                                </Form.Select>
                            </Col>
                            <Col xs={2} className="mb-0 apply-mt">
                                <Form.Control type="text" placeholder="To" className='size-font' name='volumeTo' onChange={handleChange} />
                            </Col>
                        </Row>

                        <Row>
                            <Col xs={1} >
                            </Col>
                            <Col xs={2} >
                            </Col>
                            <Col xs={2} >
                            </Col>
                            <Col xs={2} className="apply-mt">
                                <Form.Select className='size-font' value={formData.conditionCamp} onChange={handleDropdownChange} >
                                    <option value="">None selected</option>
                                    <option value="AND">AND</option>
                                    <option value="OR">OR</option>
                                </Form.Select>
                            </Col>
                            <Col xs={2} >
                                <Form.Label className="apply-mt-text" >(and/or)</Form.Label>
                            </Col>
                            <Col xs={2}>

                            </Col>
                        </Row>
                        {/* Second Row: Scan Section */}
                        <Row className="mb-3 align-items-center">
                            <Col xs={2} className="apply-mt-text">
                                <Form.Label>Scan</Form.Label>
                            </Col>
                            <Col xs={2} className="apply-mt">
                                <Form.Select className='size-font'>
                                    <option>Greater than</option>
                                    <option>Less than</option>
                                </Form.Select>
                            </Col>
                            <Col xs={2} className="apply-mt ">
                                <Form.Control type="text" placeholder="From" className='size-font' name='scanFrom' onChange={handleChange} />
                            </Col>
                            <Col xs={2} className="apply-mt">
                                <Form.Select className='size-font'>
                                    <option>Less than</option>
                                </Form.Select>
                            </Col>
                            <Col xs={2} className="mb-0 apply-mt">
                                <Form.Control type="text" placeholder="To" className='size-font' name='scanTo' onChange={handleChange} />
                            </Col>
                        </Row>
                    </Form>
                </Container>




                {formData.campaignType === "Lucky Draw" && (

                    <Container className="p-3" style={{ border: '1px solid #ccc', width: '100%' }}>
                        <Form>
                            <Form.Group as={Row}>
                                <Col sm={4}>
                                    <Form.Label><strong>User Preference *</strong></Form.Label>
                                </Col>
                                <Col sm={4}>
                                    <Form.Check
                                        type="radio"
                                        label="First"
                                        name="userPreference"
                                        defaultChecked
                                        inline
                                        onChange={handleChange}
                                    />
                                    <Form.Check
                                        type="radio"
                                        label="Random"
                                        name="userPreference"
                                        inline
                                        onChange={handleChange}
                                    />
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row}>
                                <Col sm={4}>
                                    <Form.Label><strong>Choose the type *</strong></Form.Label>
                                </Col>
                                <Col sm={4}>
                                    <Form.Check
                                        type="radio"
                                        label="Daily"
                                        name="type"
                                        defaultChecked
                                        inline
                                        onChange={handleChange}
                                    />
                                    <Form.Check
                                        type="radio"
                                        label="One time"
                                        name="type"
                                        inline
                                        onChange={handleChange}
                                    />
                                    <span className="ml-2">(for limit)</span>
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row}>
                                <Col sm={4}>
                                    <Form.Label><strong>Limit</strong></Form.Label>
                                </Col>
                                <Col sm={4}>
                                    <Form.Control type="text" placeholder="Limit" name='limit' onChange={handleChange} />
                                </Col>
                            </Form.Group>
                        </Form>
                    </Container>

                )}

                <Row className='mb-3 mt-4 justify-content-center'>
                    <Button className='sub-mit-btn' onClick={handleSubmit} >Submit</Button>
                </Row>

            </Form>
        </div>
    );
};

export default CampaignBasedContestForm;
