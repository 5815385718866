import React from "react";
import Common from "../../../component/common/common";

const PointStatementFilter = ({
  couponCode,
  setCouponCode,
  handlePointStatementSearch,
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  subcategoryoptions,
  selectedSubcategory,
  handleSubcategoryChange
}) => {
  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <div className="row">
            <div className="col-md-2">
              <Common.CustomDatePicker
                label={"From Date"}
                dateFormat={"dd-MM-yyyy"}
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                placeholderText="dd-yy-yyyy"
              />
            </div>
            <div className="col-md-2">
              <Common.CustomDatePicker
                label="To Date"
                dateFormat={"dd-MM-yyyy"}
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                placeholderText="dd-yy-yyyy"
              />
            </div>
            <div className="col-md-2">
              <Common.TextInput
                label="Coupon Code"
                placeholder="Enter Coupon Code"
                value={couponCode}
                onChange={(e) => setCouponCode(e.target.value)}
              />
            </div>
            <div className="col-md-2">
              <Common.SelectInput
                id="user-type-dropdown"
                label="Sub Category"
                options={subcategoryoptions}
                value={selectedSubcategory}
                onChange={handleSubcategoryChange}
                labelKey="label"
                valueKey="value"
              />
            </div>
            <div className="col-md-3 mt-4">
              <button
                className="btn btn-danger mt-2"
                onClick={handlePointStatementSearch}
              >
                Search
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PointStatementFilter;
