// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.campaign-btn{
    font-size: 12px;
    border: none;
    padding: 7px;
    background-color: #198754;
    color: white;
    border-radius: 10px;
}

.camp-deta{
    width: 130px;
}

.update-deta{
    width: 100px;
}

.user-deta{
    width: 90px;
}

.actions-update{
    font-size: 12px;
    color: #d63031;
    font-weight: 800;
}

`, "",{"version":3,"sources":["webpack://./src/screens/reports/CampaignReport/campaignreports.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,YAAY;IACZ,YAAY;IACZ,yBAAyB;IACzB,YAAY;IACZ,mBAAmB;AACvB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,eAAe;IACf,cAAc;IACd,gBAAgB;AACpB","sourcesContent":[".campaign-btn{\n    font-size: 12px;\n    border: none;\n    padding: 7px;\n    background-color: #198754;\n    color: white;\n    border-radius: 10px;\n}\n\n.camp-deta{\n    width: 130px;\n}\n\n.update-deta{\n    width: 100px;\n}\n\n.user-deta{\n    width: 90px;\n}\n\n.actions-update{\n    font-size: 12px;\n    color: #d63031;\n    font-weight: 800;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
