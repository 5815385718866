import React, { useEffect, useRef, useState } from "react";
import Swal from "sweetalert2";
import { Common } from "../../../component/common/common";
import { useDispatch, useSelector } from "react-redux";
import { uploadKnowledgeCorner } from "../../../features/uploadKnowledgeCornerSlice";
import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap CSS

const options = [
  { id: 1, value: "Document", label: "Document" },
  { id: 2, value: "Image", label: "Image" },
  { id: 3, value: "Link", label: "Link" },
];

function Ukc() {
  const dispatch = useDispatch();
  const message = useSelector(
    (state) => state.uploadKnowledgeCornerList.message
  );
  const status = useSelector(
    (state) => state.uploadKnowledgeCornerList.status
  );
  const loggedUserId = localStorage.getItem("userId");
  const [selectedFile, setSelectedFile] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [selectedItem, setSelectedItem] = useState("");
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    link: "",
  });
  const fileInputRef = useRef(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSelectItemChange = (e) => {
    setSelectedItem(e.target.value);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const handleSubmit = () => {
    const userformData = new FormData();
    userformData.append("type", selectedItem);
    userformData.append("title", formData.title);
    userformData.append("description", formData.description);
    if (selectedItem === "Document") {
      userformData.append("kcfile", selectedFile);
    } else if (selectedItem === "Image") {
      userformData.append("imagefile", selectedFile);
    }
    if (selectedItem === "Link") {
      userformData.append("link", formData.link);
    }
    userformData.append("uploadedBy", loggedUserId);

    setFormData({
      title: "",
      description: "",
      link: "",
    });
    setSelectedItem("");
    setSelectedFile(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
    setSubmitted(true);
    dispatch(uploadKnowledgeCorner(userformData));
  };

  useEffect(() => {
    if (submitted && status !== undefined && message) {
      if (status) {
        Swal.fire({
          title: message,
          icon: "success",
          confirmButtonText: "Okay",
        });
      } else {
        Swal.fire({
          title: message,
          icon: "error",
          confirmButtonText: "Okay",
        });
      }
      setSubmitted(false);
    }
  }, [message, status, submitted]);

  useEffect(() => {
    if (selectedFile && formData.title === "" && formData.description === "") {
      setSelectedFile(null);
    }
  }, [selectedFile, formData.title, formData.description]);

  return (
    <div className="container mt-5" style={{ minHeight: "81.8vh" }}>
      <div className="row">
        <div className="col-md-12">
          <h2 className="text-capitalize fs-5 fst-italic">
            Upload Knowledge corner
          </h2>
        </div>
        <div className="col-md-6">
          <div className="row">
            <div className="col-sm-12 mt-2">
              <Common.SelectInput
                label="Type"
                options={options}
                onChange={handleSelectItemChange}
                labelKey="label"
                valueKey="id"
                value={selectedItem}
              />
            </div>
            <div className="col-sm-12 mt-2">
              <Common.TextInput
                label="Title"
                placeholder="Enter Title"
                name="title"
                onChange={handleChange}
                value={formData.title}
              />
            </div>
            <div className="col-sm-12 mt-2">
              <Common.TextArea
                label="Description"
                placeholder={"Enter Description"}
                onChange={handleChange}
                name="description"
                value={formData.description}
              />
            </div>
            <div className="col-sm-12 mt-2">
              {selectedItem === "Document" ? (
                <Common.FileInput
                  label="File Upload"
                  name="kcfile"
                  onChange={handleFileChange}
                  fileInputRef={fileInputRef}
                />
              ) : selectedItem === "Image" ? (
                <Common.FileInput
                  label="Image Upload"
                  name="imagefile"
                  onChange={handleFileChange}
                  fileInputRef={fileInputRef}
                />
              ) : selectedItem === "Link" ? (
                <Common.TextInput
                  label="Link"
                  placeholder="Enter Link"
                  name="link"
                  onChange={handleChange}
                  value={formData.link}
                />
              ) : null}
            </div>
            <div className="col-sm-12 mt-3">
              <button
                className={
                  (selectedFile || formData.link) && !submitted
                    ? "btn btn-danger"
                    : "btn btn-secondary"
                }
                disabled={
                  (selectedFile || formData.link) && !submitted
                    ? false
                    : true
                }
                onClick={handleSubmit}
              >
                {submitted && (
                  <span
                    className="spinner-border spinner-border-sm me-2"
                    role="status"
                    aria-hidden="true"
                  ></span>
                )}
                Submit
              </button>
            </div>
            <div className="col-sm-12 mt-3 mb-4">
              <p className="text-danger">
                Note: Please upload less than 5MB File
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Ukc;
