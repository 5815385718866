import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import appConfig from "../AppConfig";

const initialState = {
    loading: false,
    data: [],
    error: "",
    message: "",
    status: "",
};

export const getupdateStatusCampaign = createAsyncThunk(
  "getupdateStatusCampaign",
  async (
    data, { rejectWithValue }
  ) => {
    const urls = `${appConfig.apiUrl}msUpdateStatusCampaignLuckDrawScheme`;
    const headers = {
      "X-API-Key": appConfig.apiKey,
      "Content-Type": "application/x-www-form-urlencoded",
    };
    const response = await axios.post(urls, data, { headers: headers });
    try {
      const result = await response.data;
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateStatusDetails = createSlice({
  name: "updateStatusDetails",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getupdateStatusCampaign.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getupdateStatusCampaign.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload.data;
      state.message = action.payload.message;
      state.status = action.payload.status; 
      state.error = "";
    });
    builder.addCase(getupdateStatusCampaign.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = action.error.message;
    });
  },
});

export default updateStatusDetails.reducer;
