// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.TextArea_label__RAXLF {
  /* color: #fff; */
  font-size: 16px;
  display: inline-block;
  margin-bottom: 8px;
}
.TextArea_textArea__RvGsM {
  display: inline-block;
  background-color: transparent;
  width: 100%;
  height: 80px;
  resize: none;
  border-radius: 6px;
  padding: 10px 8px;
  /* color: #fff; */
}
`, "",{"version":3,"sources":["webpack://./src/component/common/TextArea/TextArea.module.css"],"names":[],"mappings":";AACA;EACE,iBAAiB;EACjB,eAAe;EACf,qBAAqB;EACrB,kBAAkB;AACpB;AACA;EACE,qBAAqB;EACrB,6BAA6B;EAC7B,WAAW;EACX,YAAY;EACZ,YAAY;EACZ,kBAAkB;EAClB,iBAAiB;EACjB,iBAAiB;AACnB","sourcesContent":["\n.label {\n  /* color: #fff; */\n  font-size: 16px;\n  display: inline-block;\n  margin-bottom: 8px;\n}\n.textArea {\n  display: inline-block;\n  background-color: transparent;\n  width: 100%;\n  height: 80px;\n  resize: none;\n  border-radius: 6px;\n  padding: 10px 8px;\n  /* color: #fff; */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"label": `TextArea_label__RAXLF`,
	"textArea": `TextArea_textArea__RvGsM`
};
export default ___CSS_LOADER_EXPORT___;
