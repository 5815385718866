// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App_appContainer__Q73Qs {
  display: flex;
  flex-direction: column;
  /* border: 1px solid #f00; */
  /* height: 100vh; */
}
.App_header__fhC8n {
  /* border: 1px solid #f00; */
  /* min-height: 9vh; */
}
.App_main__nhgh3 {
  /* min-height: 81.4vh; */
  /* border: 1px solid #0f0; */
  /* overflow-y: scroll; */
}

.App_footer__aawhi {
  /* min-height: 9vh; */
  /* border: 1px solid #00f; */
}
`, "",{"version":3,"sources":["webpack://./src/App.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,4BAA4B;EAC5B,mBAAmB;AACrB;AACA;EACE,4BAA4B;EAC5B,qBAAqB;AACvB;AACA;EACE,wBAAwB;EACxB,4BAA4B;EAC5B,wBAAwB;AAC1B;;AAEA;EACE,qBAAqB;EACrB,4BAA4B;AAC9B","sourcesContent":[".appContainer {\n  display: flex;\n  flex-direction: column;\n  /* border: 1px solid #f00; */\n  /* height: 100vh; */\n}\n.header {\n  /* border: 1px solid #f00; */\n  /* min-height: 9vh; */\n}\n.main {\n  /* min-height: 81.4vh; */\n  /* border: 1px solid #0f0; */\n  /* overflow-y: scroll; */\n}\n\n.footer {\n  /* min-height: 9vh; */\n  /* border: 1px solid #00f; */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"appContainer": `App_appContainer__Q73Qs`,
	"header": `App_header__fhC8n`,
	"main": `App_main__nhgh3`,
	"footer": `App_footer__aawhi`
};
export default ___CSS_LOADER_EXPORT___;
