import FileInput from "./FileInput/FileInput";
import TextInput from "./TextInput/TextInput";
import TextArea from "./TextArea/TextArea";
import SelectInput from "./SelectInput/SelectInput";
import SelectInputId from "./SelectInput/SelectInputId";
import CustomTable from "./TableComponent/TableComponent";
import Modal_Component from "./Modal/Modal";
import CustomDatePicker from "./DatePicker/CustomDatePicker";
export const Common = {
  FileInput,
  TextInput,
  TextArea,
  SelectInput,
  SelectInputId,
  CustomTable,
  Modal_Component,
  CustomDatePicker,
};

export default Common;
