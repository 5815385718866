import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import appConfig from "../AppConfig";

const initialState = {
  loading: false,
  data: [],
  error: "",
};

export const downloadViewSalesTeamReports = createAsyncThunk(
  "downloadViewSalesTeamReports",
  async ({ msLoggedUserId, msLoggedRoleId }, { rejectWithValue }) => {
    const urls = `${appConfig.apiUrl}msSalesMemberReportDownload?msLoggedUserId=${msLoggedUserId}&msLoggedRoleId=${msLoggedRoleId}&fromDate&toDate&district&state=&mobile`;
    const headers = {
      "X-API-Key": appConfig.apiKey,
    };
    const response = await axios.get(urls, {
      headers: headers,
      responseType: "blob",
    });
    try {
      const result = await response.data;
      // console.log("result", result);
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const downloadviewSalesTeamDetails = createSlice({
  name: "downloadviewSalesTeamDetails",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(downloadViewSalesTeamReports.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(downloadViewSalesTeamReports.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(downloadViewSalesTeamReports.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = action.error.message;
    });
  },
});

export default downloadviewSalesTeamDetails.reducer;
