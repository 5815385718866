import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import appConfig from "../AppConfig";

const initialState = {
  loading: false,
  data: [],
  error: "",
  message: "",
  status: "",
};

export const updateSingleUserRecord = createAsyncThunk(
  "updateSingleUserRecord",
  async (
    { userId, msLoggedUserId, name, userDob, userWorkshop, userEmail },
    { rejectWithValue }
  ) => {
    // console.log("msliifsdfaf>>>", msLoggedUserId);
    const urls = `${appConfig.apiUrl}msEditMember`;
    const headers = {
      "X-API-Key": appConfig.apiKey,
      "Content-Type": "application/x-www-form-urlencoded",
    };
    const data = {
      userId: userId,
      updatedBy: msLoggedUserId,
      name: name,
      userDob: userDob,
      userWorkshop: userWorkshop,
      userEmail: userEmail,
    };
    const response = await axios.post(urls, data, { headers: headers });
    try {
      const result = await response.data;
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateSingleUserDetails = createSlice({
  name: "updateSingleUserDetails",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(updateSingleUserRecord.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateSingleUserRecord.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.message = action.payload.message;
      state.status = action.payload.status;
      state.error = "";
    });
    builder.addCase(updateSingleUserRecord.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = action.error.message;
    });
  },
});

export default updateSingleUserDetails.reducer;
