import React from "react";

const CommonContainer = (props) => {
  const { headerText, children } = props;
  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12 bg-secondary rounded-top p-3">
          <h2 className="h6 text-white">{headerText}</h2>
        </div>
        <div className="col-md-12 border rounded-bottom p-3">{children}</div>
      </div>
    </div>
  );
};

export default CommonContainer;
