import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import SelectInput from "./common/SelectInput/SelectInput";
import DateRangePicker from "./common/DatePicker/DateRangePicker";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import MultiSelect from "./common/SelectInput/MultiSelect";
import { districtList, getDistrictData } from "../features/districtSlice";
import { getStateData } from "../features/stateSlice";
import { addTierConfiguration } from "../features/tierConfigurationSlice";
import { Audio, Hourglass } from "react-loader-spinner";

import "./TierConfiguration.css";
import { getSubcategoryData } from "../features/TierSubcategoryListSlice";
import { getTierNameList } from "../features/TierNameListSlice";
import Swal from "sweetalert2";
import axios from "axios";
import appConfig from "../AppConfig";
export default function TierConfiguration() {
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { errors, isSubmitted, isSubmitSuccessful },
  } = useForm({
    defaultValues: {
      monthYear: null,
      state: ["All"], // Set default selected value for state
      district: ["All"], // Set default selected value for state
    },
  });
  const token = localStorage.getItem("logintoken");
  const msLoggedUserId = localStorage.getItem("userId");
  const msLoggedRoleId = localStorage.getItem("roleId");
  const stateList = useSelector((state) => state.stateList.data); //this will get stateList when state slice gets updated in store
  const districtList = useSelector((state) => state.districtList.data); //this will get districtList when districtList sliceupdates

  const productSubcat = useSelector((state) => state.TierSubcategoryList.data);
  const TierNameList = useSelector((state) => state.TierNameList.data);

  const loading = useSelector((state) => state.tierConfiguration.loading);

  const productSubcategoryOptions = productSubcat.map((field) => {
    return { label: field.subcategory, key: field.subcategory };
  });
  const tierOptions = TierNameList.map((field) => {
    return { label: field.tierName, key: field.tierName };
  });

  const [monthfilters, setMonthFilters] = useState([]);
  console.log("this is monthfilters", monthfilters);

  const selectionRange = {
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  };
  const slabOptions = [
    { label: "Points", value: "Points" },
    { label: "Scans", value: "Scans" },
  ];

  const [showHourGlass, setShowHourGlass] = useState(false);

  const handleTiername = (e, field) => {
    const val = e.target.value;
    if (field === "tiername") {
      setValue("tiername", val);
    }
    if (field === "productSubcategory") {
      setValue("productSubcategory", val);
    }
  };
  // const handleChangeDate = (dates) => {
  //   console.log("dates", dates);
  //   setValue(
  //     "monthYear",
  //     dates ? [dates[0].toISOString(), dates[1].toISOString()] : null
  //   );
  // };
  const handleDistrictChange = (field, selectedValues) => {
    if (!selectedValues) {
      field.onChange([]);
      return;
    }

    let newValues = [...selectedValues];

    if (newValues.includes("All")) {
      if (newValues[0] === "All" && newValues.length > 1) {
        // Remove 'alldistrict' if it's first item and others are selected
        newValues = newValues.filter((val) => val !== "All");
      } else {
        // Keep only 'alldistrict' if selected with other items
        newValues = ["All"];
      }
    }

    field.onChange(newValues);
  };
  const handleSlabType = (field, e) => {
    const selectedValue = e.target.value;
    field.onChange(selectedValue);
    return;
  };
  const handleStateChange = (field, selectedValues) => {
    if (!selectedValues) {
      field.onChange([]);
      return;
    }

    let newValues = [...selectedValues];

    if (newValues.includes("All")) {
      if (newValues[0] === "All" && newValues.length > 1) {
        // Remove 'allstate' if it's the first item and others are selected
        newValues = newValues.filter((val) => val !== "All");
      } else {
        // Keep only 'allstate' if selected with other items
        newValues = ["All"];
      }
    }

    field.onChange(newValues);
  };

  const handleMonthChange = (e, field) => {
    if (e.target.value) {
      field.onChange(e.target.value);
      return;
    } else {
      field.onChange("");
    }

    // if (!selectedValues) {
    //   field.onChange([]);
    //   return;
    // }
    // field.onChange(selectedValues);
    console.log("this is e.target.value", e.target.value);
    console.log("this is field", field);
    console.log("this is monthyear", watch("monthYear"));
  };

  const selectedPoints = watch("pointslab");
  const selectedScans = watch("scanSlab");
  const selectedStatesIds = watch("state");
  const selectedDistrictIds = watch("district");
  const selectedSlabType = watch("slabType");

  console.log("this is slabtype", selectedSlabType);
  console.log("this is selectedStates", selectedStatesIds);
  console.log("these are selected district", selectedDistrictIds);
  console.log("this is TierNameList", TierNameList);
  console.log("this is product subcat", productSubcategoryOptions);
  console.log("this is stateList", stateList);

  useEffect(() => {
    dispatch(getSubcategoryData({ msLoggedRoleId, msLoggedUserId, token }));
    dispatch(getTierNameList({ msLoggedRoleId, msLoggedUserId, token }));
    dispatch(getStateData({ msLoggedUserId, msLoggedRoleId }));
  }, []);
  useEffect(() => {
    if (selectedPoints) {
      setValue("scanSlab", ""); // Clear scanSlab if pointSlab has data
    }
  }, [selectedPoints, setValue]);

  useEffect(() => {
    if (selectedScans) {
      setValue("pointSlab", ""); // Clear pointSlab if scanSlab has data
    }
  }, [selectedScans, setValue]);

  useEffect(() => {}, []);

  useEffect(() => {
    if (selectedStatesIds) {
      dispatch(
        getDistrictData({
          msLoggedUserId,
          msLoggedRoleId,
          selectedStates: selectedStatesIds,
        })
      );
    }
  }, [selectedStatesIds]);

  const onSubmit = async (data) => {
    const response = await dispatch(
      addTierConfiguration({
        msLoggedRoleId,
        msLoggedUserId,
        configurationData: data,
        token,
      })
    ).unwrap();
    console.log("this is response.status", response.status);
    if (response?.status) {
      Swal.fire({
        title: "Success!",
        text: response.message,
        icon: "success",
        confirmButtonText: "Ok",
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.href = "/tier-configuration-report"; // Change this to your desired URL
        }
      });
      setTimeout(() => {
        window.location.href = "/tier-configuration-report"; // Change this to your desired URL
      }, [5000]);
    } else {
      Swal.fire({
        title: "Error!",
        text: response.message,
        icon: "error",
        confirmButtonText: "Ok",
      });
    }
  };
  console.log("this is errors", errors);

  return (
    <>
      <div className="container">
      
        {loading && (
          <div className="hourglass">
            <Hourglass
              visible={true}
              height="80"
              width="80"
              ariaLabel="hourglass-loading"
              wrapperStyle={{}}
              wrapperClass=""
              colors={["rgb(156 49 79)", "#ff9595"]}
            />
          </div>
        )}
        <div className="tierconfiguration-form-outer">
        <h2 className="text-capitalize fs-5 fst-italic mt-5">
            Add Leader board/Tier Configuration
          </h2>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="tierconfiguration-form"
          >
            <div className="tierconfiguration-form-inner">
              <div className="form-element">
                <div className="laber-error">
                  <label className="label">
                    Tiername<span className="required">*</span>
                  </label>
                  {errors["tiername"] ? (
                    <span className="error">{errors["tiername"].message}</span>
                  ) : (
                    ""
                  )}
                </div>
                <div>
                  <Controller // Controller is a wrapper that connects custom components(here Selectbutton) to the form using control.
                    name="tiername"
                    control={control}
                    rules={{
                      required: "Tiername is required", // Validation rule for state
                    }}
                    render={({ field }) => (
                      <SelectInput
                        value={field.value} //field contains value, onChange, etc methods
                        onChange={(e) => {
                          handleTiername(e, "tiername");
                        }}
                        name={field.name}
                        options={tierOptions}
                        labelKey="label"
                        valueKey="value"
                      />
                    )}
                  />
                </div>
              </div>
              <div className="form-element">
                <div className="laber-error">
                  <label className="label">
                    Product Subcategory<span className="required">*</span>
                  </label>

                  {errors["productSubcategory"] ? (
                    <span className="error">
                      {errors["productSubcategory"].message}
                    </span>
                  ) : (
                    ""
                  )}
                </div>
                <div>
                  <Controller // Controller is a wrapper that connects custom components(here Selectbutton) to the form using control.
                    name="productSubcategory"
                    control={control}
                    rules={{
                      required: "Product Subcategory is required", // Validation rule for state
                    }}
                    render={({ field }) => (
                      <SelectInput
                        value={field.value} //field contains value, onChange, etc methods
                        onChange={(e) => {
                          handleTiername(e, "productSubcategory");
                        }}
                        name={field.name}
                        options={productSubcategoryOptions}
                        labelKey="label"
                        valueKey="value"
                      />
                    )}
                  />
                </div>
              </div>
              <div className="form-element">
                <div className="laber-error">
                  <label className="label">
                    Select Slab Type<span className="required">*</span>
                  </label>

                  {errors["slabType"] ? (
                    <span className="error">{errors["slabType"].message}</span>
                  ) : (
                    ""
                  )}
                </div>
                <div>
                  <Controller // Controller is a wrapper that connects custom components(here Selectbutton) to the form using control.
                    name="slabType"
                    control={control}
                    rules={{
                      required: "Slab Type is required", // Validation rule for state
                    }}
                    render={({ field }) => (
                      <SelectInput
                        value={field.value} //field contains value, onChange, etc methods
                        onChange={(e) => {
                          handleSlabType(field, e);
                        }}
                        name={field.name}
                        options={slabOptions}
                        labelKey="label"
                        valueKey="value"
                      />
                    )}
                  />
                </div>
              </div>

              {selectedSlabType ? (
                <div className="form-element">
                  <div className="laber-error">
                    <label className="label">
                      Enter {selectedSlabType === "Points" ? "Points" : "Scans"}{" "}
                      Slab<span className="required">*</span>
                    </label>
                    {errors["Points"] ? (
                      <span className="error">{errors["Points"].message}</span>
                    ) : errors["Scans"] ? (
                      <span className="error">{errors["Scans"].message}</span>
                    ) : (
                      ""
                    )}
                  </div>

                  <input
                    className=" tierconfiguration-form-input"
                    type="number"
                    placeholder={`Enter ${
                      selectedSlabType === "Points" ? "Points" : "Scans"
                    }`}
                    {...register(
                      `${selectedSlabType === "Points" ? "Points" : "Scans"}`,
                      {
                        required: `${
                          selectedSlabType === "Points" ? "Points" : "Scans"
                        } are required`,
                      }
                    )}
                  />
                </div>
              ) : (
                ""
              )}

              <div className="form-element">
                <div className="laber-error">
                  <label className="label">
                    Bonus % on Points/Scans<span className="required">*</span>
                  </label>
                  {errors["bonusPercent"] ? (
                    <span className="error">
                      {errors["bonusPercent"].message}
                    </span>
                  ) : (
                    ""
                  )}
                </div>
                <div>
                  <input
                    className="tierconfiguration-form-input"
                    type="number"
                    placeholder="Enter Bonus %"
                    {...register("bonusPercent", {
                      required: "Please Enter Bonus Percentage",
                    })}
                  ></input>
                </div>
              </div>
              <div className="form-element">
                <div className="laber-error">
                  <label className="label">
                    Select Month<span className="required">*</span>
                  </label>
                  {errors["monthYear"] ? (
                    <span className="error">{errors["monthYear"].message}</span>
                  ) : (
                    ""
                  )}
                </div>
                <div>
                  <Controller
                    name="monthYear"
                    control={control}
                    rules={{ required: "Month is required" }}
                    render={({ field }) => (
                      <SelectInput
                        // value={field.value} //field contains value, onChange, etc methods
                        onChange={(e) => {
                          handleMonthChange(e, field);
                        }}
                        name={field.name}
                        options={[
                          { label: "March-2025", value: "March-2025" },
                          { label: "April-2025", value: "April-2025" },
                          { label: "May-2025", value: "May-2025" },
                          { label: "June-2025", value: "June-2025" },
                          { label: "July-2025", value: "July-2025" },
                          { label: "August-2025", value: "August-2025" },
                          { label: "September-2025", value: "September-2025" },
                          { label: "October-2025", value: "October-2025" },
                          { label: "November-2025", value: "November-2025" },
                          { label: "December-2025", value: "December-2025" },
                        ]}
                        labelKey="label"
                        valueKey="value"
                      />
                    )}
                  />
                </div>
              </div>
              <div className="form-element">
                <div className="label-error">
                  <label className="label">
                    State<span className="required">*</span>
                  </label>
                  {errors["state"] ? (
                    <span className="error">{errors["state"].message}</span>
                  ) : (
                    ""
                  )}
                </div>
                <div className="form-element-inner">
                  <Controller
                    name="state"
                    control={control}
                    rules={{ required: "State is required" }}
                    render={({ field }) => (
                      <MultiSelect
                        className="tierconfiguration-form-input"
                        defaultValue="All"
                        onChange={(selectedValues) => {
                          // Handle clear action
                          handleStateChange(field, selectedValues);
                        }}
                        value={field.value || []}
                        options={[
                          { label: "All", value: "All" },
                          ...stateList.map((item) => ({
                            label: item.state,
                            value: item.state,
                          })),
                        ]}
                      />
                    )}
                  />
                </div>
              </div>
              <div className="form-element">
                <div className="laber-error">
                  <label className="label">
                    District<span className="required">*</span>
                  </label>
                  {errors["district"] ? (
                    <span className="error">{errors["district"].message}</span>
                  ) : (
                    ""
                  )}
                </div>
                <div className="form-element-inner">
                  <Controller
                    name="district"
                    control={control}
                    rules={{ required: "District is required" }}
                    render={({ field }) => (
                      <MultiSelect
                        className="tierconfiguration-form-input"
                        onChange={(selectedValues) => {
                          // Handle clear action
                          handleDistrictChange(field, selectedValues);
                        }}
                        value={field.value || []}
                        options={[
                          { label: "All", value: "All" },
                          ...districtList.map((item) => ({
                            label: item.district,
                            value: item.district,
                          })),
                        ]}
                      />
                    )}
                  />
                </div>
              </div>
              <div className="form-element">
                <div className="laber-error">
                  <label className="label">
                    Bonus Remarks<span className="required">*</span>
                  </label>
                  {errors["remarks"] ? (
                    <span className="error">{errors["remarks"].message}</span>
                  ) : (
                    ""
                  )}
                </div>
                <input
                  type="text"
                  className=" tierconfiguration-form-input"
                  placeholder="Enter Remarks"
                  {...register("remarks", {
                    required: "Please Enter Remarks",
                  })}
                ></input>
              </div>

              <div className="form-submit">
                <button type="submit" className="form-submit-button">
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
