import React from "react";
import Common from "../../../component/common/common";

const CommonFilter = ({
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  handleOrderReportSearch,
  orderNumber,
  setOrderNumber,
}) => {
  // console.log("redeemed>>>>", startDate, endDate);
  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <div className="row">
            <div className="col-md-3">
              <Common.CustomDatePicker
                label={"From Date"}
                dateFormat={"dd-MM-yyyy"}
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                placeholderText="dd-yy-yyyy"
              />
            </div>
            <div className="col-md-3">
              <Common.CustomDatePicker
                label="To Date"
                dateFormat={"dd-MM-yyyy"}
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                placeholderText="dd-yy-yyyy"
              />
            </div>
            <div className="col-md-3">
              <Common.TextInput
                label="Order No."
                placeholder="Enter Order No."
                value={orderNumber}
                onChange={(e) => setOrderNumber(e.target.value)}
              />
            </div>
            <div className="col-md-3 mt-4">
              <button
                className="btn btn-danger mt-2"
                onClick={handleOrderReportSearch}
              >
                Search
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommonFilter;
