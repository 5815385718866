import React from "react";
import { Button as CustomButton } from "react-bootstrap";
const Button = ({ variant, onClick, ...other }) => {
  return (
    <>
      <style type="text/css">
        {`
    .btn-submit {
      background-color: #ed1d24;
      color: #fff;
      margin-top: -4px
    }
    .btn-submit:hover{
      background-color: #8B0000;
      color:#fff;
    }

    .btn-xxl {
      padding: 1rem 1.5rem;
      font-size: 1.5rem;
    }
    .btn-download {
      background-color: #12446a;
      color: #fff;
    }
    .btn-download:hover{
      background-color:#124;
      color:#fff;
    }

    .btn-xxl {
      padding: 1rem 1.5rem;
      font-size: 1.5rem;
    }
    `}
      </style>
      <CustomButton
        variant={variant}
        onClick={onClick}
        {...other}
      ></CustomButton>
    </>
  );
};

export default Button;
