// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.input-row {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.input-label {
  margin-right: 10px;
  /* Space between label and input */
  white-space: nowrap;
  /* Prevent label wrapping */
  /* margin-bottom: 10px; */
  font-weight: bold;
}

.select-input {
  flex: 1 1;
  /* Allow the input field to grow as needed */
}

.dropdown-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.update-link {
  color: blue;
  text-decoration: underline;
  font-size: 14px;
  cursor: pointer;
}

.update-link:hover {
  color: darkblue;
}`, "",{"version":3,"sources":["webpack://./src/screens/oneView/ProfileDetails/profiledetails.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;EAClB,kCAAkC;EAClC,mBAAmB;EACnB,2BAA2B;EAC3B,yBAAyB;EACzB,iBAAiB;AACnB;;AAEA;EACE,SAAO;EACP,4CAA4C;AAC9C;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,SAAS;AACX;;AAEA;EACE,WAAW;EACX,0BAA0B;EAC1B,eAAe;EACf,eAAe;AACjB;;AAEA;EACE,eAAe;AACjB","sourcesContent":[".input-row {\n  display: flex;\n  align-items: center;\n  margin-bottom: 15px;\n}\n\n.input-label {\n  margin-right: 10px;\n  /* Space between label and input */\n  white-space: nowrap;\n  /* Prevent label wrapping */\n  /* margin-bottom: 10px; */\n  font-weight: bold;\n}\n\n.select-input {\n  flex: 1;\n  /* Allow the input field to grow as needed */\n}\n\n.dropdown-container {\n  display: flex;\n  align-items: center;\n  gap: 10px;\n}\n\n.update-link {\n  color: blue;\n  text-decoration: underline;\n  font-size: 14px;\n  cursor: pointer;\n}\n\n.update-link:hover {\n  color: darkblue;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
