import React, { useState } from "react";
import Common from "../common";
import Button from "../../Button/Button";


const Filters_updated = ({
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  mobileNumber,
  setMobileNumber,
  stateItemSelected,
  handleStateChange,
  stateList,
  districtList,
  districtItemSelected,
  handleDistrictChange,
  handleSubmit,
  subcategoryoptions,
  selectedSubcategory,
  handleSubcategoryChange,
 
}) => {
  

  return (
    <>
      <div className="row">
        <div className="col-md-2">
          <Common.CustomDatePicker
            label={"From Date"}
            dateFormat={"dd-MM-yyyy"}
            selected={startDate}
            onChange={(date) => setStartDate(date)}
            placeholderText="dd-yy-yyyy"
          />
        </div>
        <div className="col-md-2">
          <Common.CustomDatePicker
            label={"To Date"}
            dateFormat={"dd-MM-yyyy"}
            selected={endDate}
            onChange={(date) => setEndDate(date)}
            placeholderText="dd-yy-yyyy"
          />
        </div>
        <div className="col-md-2">
          <Common.TextInput
            label={"Mobile"}
            value={mobileNumber}
            onChange={(e) => setMobileNumber(e.target.value)}
            placeholder="Enter Mobile Number"
          />
        </div>
        <div className="col-md-2">
          <Common.SelectInput
            value={stateItemSelected}
            onChange={handleStateChange}
            options={stateList}
            label={"State"}
            labelKey="state"
            valueKey="id"
          />
        </div>
        <div className="col-md-2">
          <Common.SelectInput
            options={districtList}
            label={"District"}
            labelKey="district"
            valueKey="id"
            value={districtItemSelected}
            onChange={handleDistrictChange}
          />
        </div>
        <div className="col-md-2">
              <Common.SelectInput
                id="user-type-dropdown"
                label="Sub Category"
                options={subcategoryoptions}
                value={selectedSubcategory}
                onChange={handleSubcategoryChange}
                labelKey="label"
                valueKey="value"
              />
            </div>
        <div  className="col-md-3 mt-4">        
            <Button variant={"submit"} onClick={handleSubmit}>
              Submit
            </Button>
        </div>
        
      </div>
    </>
  );
};

export default Filters_updated;
