import React from "react";
import Common from "../../../component/common/common";

const CallUpdateForm = ({
  userCallStatusList,
  callTypeList,
  enquiryList,
  handleCallStatusInputChange,
  handleCallType,
  handleEnquiryType,
  callStatusItemSelectedInput,
  callTypeItemSelected,
  enquiryTypeItemSelected,
  handleCallUpdateSubmit,
  handleInputChange,
  formData,
}) => {
  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <form onSubmit={handleCallUpdateSubmit}>
            <div className="form-group mt-1">
              <Common.SelectInputId
                options={userCallStatusList}
                label={"Select Call Status:"}
                labelKey="call_status_name"
                valueKey="callStatusId"
                onChange={handleCallStatusInputChange}
                value={callStatusItemSelectedInput}
              />
            </div>
            <div className="form-group mt-1">
              <Common.SelectInput
                options={callTypeList}
                label={"Select Call Type:"}
                labelKey="callTypeName"
                valueKey="callTypeName"
                onChange={handleCallType}
                value={callTypeItemSelected}
              />
            </div>
            <div className="form-group mt-1">
              <Common.SelectInput
                options={enquiryList}
                label={"Select Enquiry Type:"}
                labelKey="call_type"
                valueKey="callTypeId"
                onChange={handleEnquiryType}
                value={enquiryTypeItemSelected}
              />
            </div>
            <div className="form-group mt-1">
              <Common.TextArea
                label={"Comments:"}
                onChange={handleInputChange}
                name="comments"
                value={formData.comments}
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CallUpdateForm;
