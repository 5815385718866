// TextInput.js

import React from "react";
import styles from "./TextInput.module.css";
const TextInput = ({
  label,
  value,
  onChange,
  placeholder,
  name,
  readOnly,
  type,
  min,
  max,
}) => {
  return (
    <div className={styles.textInputContainer}>
      <label className={styles.label}>{label}</label>
      <input
        type={type || "text"}
        value={value}
        onChange={onChange}
        className={styles.textInput}
        placeholder={placeholder}
        name={name}
        readOnly={readOnly}
        min={min}
        max={max}
      />
    </div>
  );
};

export default TextInput;
