import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import appConfig from "../AppConfig";

const initialState = {
  data: [],
  loading: false,
  error: "",
};

export const addSalesMember = createAsyncThunk(
  "addSalesMember",
  async (
    { salesName, salesMobile, salesEmail, salesRole, msLoggedUserId },
    { rejectWithValue }
  ) => {
    const urls = `${appConfig.apiUrl}msAddSalesTeam`;
    const headers = {
      "X-API-Key": appConfig.apiKey,
      "Content-Type": "application/x-www-form-urlencoded",
    };

    const postData = {
      salesName,
      salesMobile,
      salesEmail,
      salesRole,
      createdBy: msLoggedUserId,
    };

    const response = await axios.post(urls, postData, { headers: headers });
    try {
      const result = await response.data;
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const addSalesMemberDetails = createSlice({
  name: "addSalesMemberDetails",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(addSalesMember.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addSalesMember.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.error = "";
    });
    builder.addCase(addSalesMember.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = action.error.message;
    });
  },
});

export default addSalesMemberDetails.reducer;
