import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import appConfig from "../AppConfig";

const initialState = {
  loading: false,
  data: [],
  error: "",
};
export const getPackSizeList = createAsyncThunk(
  "getPackSizeList",
  async ({ productNameSelectedItem, msLoggedRoleId }, { rejectWithValue }) => {
    const urls = `${appConfig.apiUrl}msPackSize?msLoggedRoleId=${msLoggedRoleId}&pId=${productNameSelectedItem}`;
    const headers = {
      "X-API-Key": appConfig.apiKey,
      "Content-Type": "application/x-www-form-urlencoded",
    };
    const response = await axios.get(urls, { headers: headers });
    try {
      const result = response.data.data;
      return result;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const packSizeList = createSlice({
  name: "packSizeList",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getPackSizeList.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getPackSizeList.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.error = false;
    });
    builder.addCase(getPackSizeList.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = action.error.message;
    });
  },
});

export default packSizeList.reducer;
