import React, { useState } from "react";
import Common from "../common";
import Button from "../../Button/Button";

const Filters = ({
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  mobileNumber,
  setMobileNumber,
  stateItemSelected,
  handleStateChange,
  stateList,
  districtList,
  districtItemSelected,
  handleDistrictChange,
  handleSubmit,
  handleDownload,
  msLoggedRoleId,
  filterPage,
  handleDownloadClickFilter
}) => {
  const [downloading, setDownloading] = useState(false);

  const handleDownloadClick = async () => {
    setDownloading(true);

    // Introducing a 1-second delay using setTimeout
    setTimeout(async () => {
      try {
        await handleDownload();
      } catch (error) {
        console.error("Error downloading file:", error);
      }
      setDownloading(false);
    }, 1000); // 1000 milliseconds = 1 second
  };

  return (
    <>
      <div className="row">
        <div className="col-md-2 col-sm-6 col-12 mt-2">
          <Common.CustomDatePicker
            label={"From Date"}
            dateFormat={"dd-MM-yyyy"}
            selected={startDate}
            onChange={(date) => setStartDate(date)}
            placeholderText="dd-yy-yyyy"
          />
        </div>
        <div className="col-md-2 col-sm-6 col-12 mt-2">
          <Common.CustomDatePicker
            label={"To Date"}
            dateFormat={"dd-MM-yyyy"}
            selected={endDate}
            onChange={(date) => setEndDate(date)}
            placeholderText="dd-yy-yyyy"
          />
        </div>
        <div className="col-md-2 col-sm-6 col-12 mt-2">
          <Common.TextInput
            label={"Mobile"}
            value={mobileNumber}
            onChange={(e) => setMobileNumber(e.target.value)}
            placeholder="Enter Mobile Number"
          />
        </div>
        <div className="col-md-2 col-sm-6 col-12 mt-2">
          <Common.SelectInput
            value={stateItemSelected}
            onChange={handleStateChange}
            options={stateList}
            label={"State"}
            labelKey="state"
            valueKey="id"
          />
        </div>
        <div className="col-md-2 col-sm-6 col-12 mt-2">
          <Common.SelectInput
            options={districtList}
            label={"District"}
            labelKey="district"
            valueKey="id"
            value={districtItemSelected}
            onChange={handleDistrictChange}
          />
        </div>
        <div className="col-md-1 col-sm-6 col-12 mt-4">
          <div className="mt-md-3">
            <Button variant={"submit"} onClick={handleSubmit}>
              Submit
            </Button>
          </div>
        </div>
        {msLoggedRoleId === "1" || msLoggedRoleId === "3" || msLoggedRoleId === "7" || msLoggedRoleId === "8" || msLoggedRoleId === "9" ? (
          <div className="col-md-1 col-sm-6 col-12 mt-4 mt-4">
            <div className="mt-3 ms-2">

            </div>

            {filterPage != 'transaction' &&
              <>
                {downloading ? (
                  <Button variant={"download"} disabled>
                    Downloading...
                  </Button>
                ) : (
                  <Button variant={"download"} onClick={handleDownloadClick}>
                    Download
                  </Button>
                )}
              </>
            }
            {filterPage == 'transaction' &&
              <>

                <Button variant={"download"} onClick={handleDownloadClickFilter}>
                  Download
                </Button>

              </>
            }


          </div>
        ) : null}


      </div>
    </>
  );
};

export default Filters;
