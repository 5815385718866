import { createContext, useContext } from "react";

const MsLoggedUserInfoContext = createContext();

export const MsLoggedUserInfoProvider = ({ children }) => {
  const msLoggedUserId = localStorage.getItem("userId");
  const msLoggedRoleId = localStorage.getItem("roleId");
  const msLoggedStatus = localStorage.getItem("status");

  return (
    <MsLoggedUserInfoContext.Provider
      value={{ msLoggedUserId, msLoggedRoleId, msLoggedStatus }}
    >
      {children}
    </MsLoggedUserInfoContext.Provider>
  );
};

export const useMsLoggedUserInfo = () => useContext(MsLoggedUserInfoContext);
