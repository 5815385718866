import React, { useState, useEffect } from "react";
import { Common } from "../../../component/common/common";
import { getviewCampaignLuckyDrawData } from "../../../features/viewCampaignLuckDrawSchemeSlice";
import { useDispatch, useSelector } from "react-redux";
import { getDetailRowCampaignScheme } from "../../../features/DetailRowCampaignSchemeSlice";
import { getCampaignUploadUserDetails } from "../../../features/CampaignUploadUserDetailsSlice";
import Modal_Component from "../../../component/common/Modal/Modal";
import '../../../component/common/TableComponent/CustomTable.module.css';
import { getupdateStatusCampaign } from "../../../features/UpdateStatusCampaignSlice";
import Campaign_Filters from "../../../component/common/Filters/campaignfilters";
import './campaignreports.css';



const columns = [
  "S.No.",
  "Scheme ID",
  "Campaign Type",
  "Contest Name",
  "Benefit Type",
  "User Selection Type",
  "Start Date",
  "End Date",
  "User Role",
  "State",
  "District",
  "Group Category",
  "Brand",
  "Sub Category",
  "Product",
  "User Preference",
  "Contest Run Type",
  "User Limit",
  "Created Date",
  "Status",
  "Details",
  "View",
  "Actions"
];


function CampaignReport() {
  const msLoggedUserId = localStorage.getItem("userId");
  const msLoggedRoleId = localStorage.getItem("roleId");
  const token = localStorage.getItem("logintoken");
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [modalBody, setModalBody] = useState(null);
  const [selectedSchemeId, setSelectedSchemeId] = useState(null);
  const [statusData, setStatusData] = useState({});
  const { data, totalRecords, message } = useSelector(
    (state) => state.viewCampaignLuckyDrawDetails.data
  );

  const [inactiveStatusMap, setInactiveStatusMap] = useState({});
  console.log("inactiveStatusMap usestate", inactiveStatusMap);

  console.log("totalRecordstotalRecords", totalRecords);

  const { status } = useSelector((state) => state.updateStatusDetails);

  console.log("status", status);


  const [stateItemSelected, setStateItemSelected] = useState("");
  const [districtItemSelected, setDistrictItemSelected] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [actionType, setActionType] = useState(null);

  const formatDate = (date) => {
    if (!date) return "";
    const d = new Date(date);
    const year = d.getFullYear();
    const month = String(d.getMonth() + 1).padStart(2, "0");
    const day = String(d.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`; // Return in YYYY-MM-DD format
  };

  const formattedStartDate = startDate ? formatDate(startDate) : "";
  const formattedEndDate = endDate ? formatDate(endDate) : "";

  const itemsPerPage = 10;
  // const totalRecords = data ? data.length : 0;
  const totalPages = Math.ceil(totalRecords / itemsPerPage);
  console.log("totalPages", totalPages);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  console.log("endIndex", endIndex);

  useEffect(() => {
    if (formattedStartDate && formattedEndDate) {
      dispatch(
        getviewCampaignLuckyDrawData({
          formattedStartDate,
          formattedEndDate,
          startIndex,
          msLoggedUserId,
          msLoggedRoleId,
          token,
        })
      );
    }
  }, [dispatch, currentPage, formattedStartDate, formattedEndDate, msLoggedUserId, msLoggedRoleId, token]);

  useEffect(() => {
    dispatch(
      getviewCampaignLuckyDrawData({
        formattedStartDate,
        formattedEndDate,
        startIndex,
        msLoggedUserId,
        msLoggedRoleId,
        token,
      })
    );
  }, [statusData, startIndex, inactiveStatusMap]);

  const handleViewUserDetails = (schemeId) => {
    // Fetch user details by calling the `getCampaignUploadUserDetails` action
    dispatch(getCampaignUploadUserDetails({
      msLoggedUserId,
      msLoggedRoleId,
      token,
      schemeId,
      formattedStartDate,
      formattedEndDate,
      startIndex
    }))
      .then((response) => {
        const userDetails = response.payload.data[0]; // Get the user details from the response

        console.log("userDetails", userDetails);

        // Create the modal content for the user details
        const modalContent = (
          <div>
            <div className="table-responsive">
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th><strong>Username</strong></th>
                    <th><strong>Mobile</strong></th>
                    <th><strong>Scheme Date</strong></th>
                    <th><strong>Role</strong></th>
                    <th><strong>Contest Name</strong></th>
                    <th><strong>Contest Type</strong></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{userDetails.userName}</td>
                    <td>{userDetails.userMobile}</td>
                    <td>{userDetails.schemeDate}</td>
                    <td>{userDetails.roleName}</td>
                    <td>{userDetails.contestName}</td>
                    <td>{userDetails.contestType}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        );

        setModalBody(modalContent); // Set the modal content for the user details
        setShowModal(true); // Show the modal with the user details
      })
      .catch((error) => {
        console.error("Error fetching user details:", error);
        setModalBody(<p>Failed to load user details.</p>);
        setShowModal(true);
      });
  };


  const handleViewDetails = (schemeId) => {
    setSelectedSchemeId(schemeId);  // Make sure this is setting the correct scheme ID
    console.log("Selected Scheme ID:", schemeId); // Debugging log

    dispatch(getDetailRowCampaignScheme({
      msLoggedUserId,
      msLoggedRoleId,
      token,
      schemeId,
      formattedStartDate: "",
      formattedEndDate: "",
      startIndex
    }))
      .then((response) => {
        const campaignDetails = response.payload.data[0]; // Access the first item in the data array

        // Create the modal content in tabular format
        const modalContent = (
          <div>
            <div className="table-responsive">
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th><strong>Benefit Type</strong></th>
                    <th><strong>Scan From</strong></th>
                    <th><strong>Scan To</strong></th>
                    <th><strong>Range Condition</strong></th>
                    <th><strong>Volume From</strong></th>
                    <th><strong>Volume To</strong></th>
                    <th><strong>Points</strong></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{campaignDetails.benifitType}</td>
                    <td>{campaignDetails.scanFrom}</td>
                    <td>{campaignDetails.scanTo}</td>
                    <td>{campaignDetails.rangeCondition}</td>
                    <td>{campaignDetails.volumeFrom}</td>
                    <td>{campaignDetails.volumeTo}</td>
                    <td>{campaignDetails.points}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        );

        setModalBody(modalContent); // Set the modal content
        setShowModal(true); // Show the modal
      })
      .catch((error) => {
        console.error("Error fetching campaign details:", error);
        setModalBody(<p>Failed to load campaign details.</p>);
        setShowModal(true);
      });
  };


  const handleUpdateStatus = (schemeId) => {
    console.log("schemeId", schemeId)
    setSelectedSchemeId(schemeId); // Store schemeId to use in handleCloseModal
    setModalBody(<p>Are you sure you want to deactivate the configuration?</p>);
    setActionType("updateStatus");
    setShowModal(true);
  };


  const handleOk = () => {
    if (actionType === "updateStatus" && selectedSchemeId) {
      const item = data.find((item) => item.schemeId === selectedSchemeId);

      if (item && item.status === "Active") {
        // Immediately update inactiveStatusMap to show the status as "Inactive"
        setInactiveStatusMap((prevMap) => ({
          ...prevMap,
          [selectedSchemeId]: true,
        }));

        const campStatus = "Inactive";

        dispatch(
          getupdateStatusCampaign({
            msLoggedUserId,
            msLoggedRoleId,
            token,
            campStatus,
            schemeId: selectedSchemeId,
          })
        )
          .then((response) => {
            if (response.payload?.status !== true) {
              // Revert the change if the API response indicates a failure
              setInactiveStatusMap((prevMap) => {
                const updatedMap = { ...prevMap };
                delete updatedMap[selectedSchemeId];
                return updatedMap;
              });
              console.error("API response indicates failure:", response.payload?.message);
            } else {
              // Fetch updated data from the server to ensure consistency
              dispatch(
                getviewCampaignLuckyDrawData({
                  formattedStartDate,
                  formattedEndDate,
                  startIndex,
                  msLoggedUserId,
                  msLoggedRoleId,
                  token,
                })
              );
            }
          })
          .catch((error) => {
            // Revert change in case of an error
            setInactiveStatusMap((prevMap) => {
              const updatedMap = { ...prevMap };
              delete updatedMap[selectedSchemeId];
              return updatedMap;
            });
            console.error("Error updating campaign status:", error);
          });
      }
    }
    // Reset modal state
    setShowModal(false);
    setSelectedSchemeId(null);
    setModalBody(null);
    setActionType(null);
  };


  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedSchemeId(null);
    setModalBody(null);
    setActionType(null);
  };


  const tabledata = data
    ? data.map((item, index) => {
      const isStatusInactive = inactiveStatusMap[item.schemeId] || item.status === "Inactive";
      console.log("isStatusInactive tabledata", isStatusInactive);
      return {
        "S.No.": (currentPage - 1) * itemsPerPage + index + 1,
        "Scheme ID": item.schemeId,
        "Campaign Type": item.campaign_type,
        "Contest Name": item.contest_name,
        "Benefit Type": item.benefit_type,
        "User Selection Type": item.user_selection,
        "Start Date": formatDate(item.start_date),
        "End Date": formatDate(item.end_date),
        "User Role": item.user_role,
        State: item.state,
        District: item.district,
        "Group Category": item.group,
        Brand: item.brand,
        "Sub Category": item.sub_category,
        Product: item.product,
        "User Preference": item.user_preference,
        "Contest Run Type": item.contest_runtype,
        "User Limit": item.user_limit,
        "Created Date": formatDate(item.created_date),
        Status: isStatusInactive ? "Inactive" : "Active",
        Details: (
          <button
            onClick={() => handleViewDetails(item.schemeId)}
            className="campaign-btn camp-deta "
          >
            Campaign Details
          </button>
        ),
        View: (
          <button
            onClick={() => handleViewUserDetails(item.schemeId)}
            className="campaign-btn user-deta"
          >
            User Details
          </button>
        ),
        Actions: isStatusInactive ? (
          <p className="actions-update">
            Status Updated
          </p>
        ) : (
          <button
            onClick={() => handleUpdateStatus(item.schemeId)}
            className="campaign-btn update-deta"
          >
            Update Status
          </button>
        ),
      };
    })
    : [];


  const handleSubmit = () => {
    dispatch(
      getviewCampaignLuckyDrawData({
        formattedStartDate,
        formattedEndDate,
        startIndex,
        msLoggedUserId,
        msLoggedRoleId,
        token,
      })
    );
  };


  return (
    <div className="container" style={{ minHeight: "81.8vh" }}>
      <div className="row">
        <div className="col-md-12 mt-5">
          <h2 className="text-capitalize fs-5 fst-italic">View Campaign/Contest Report</h2>
        </div>
        <div className="col-md-12">
          <Campaign_Filters
            startDate={startDate}
            endDate={endDate}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            stateItemSelected={stateItemSelected}
            handleStateChange={(e) => setStateItemSelected(e.target.value)}
            districtItemSelected={districtItemSelected}
            handleDistrictChange={(e) => setDistrictItemSelected(e.target.value)}
            handleSubmit={handleSubmit}
          />
        </div>
        <div className="col-md-12 my-4">
          <Common.CustomTable
            columns={columns}
            data={tabledata}
            message={message}
            totalRecords={totalRecords}
            itemsPerPage={itemsPerPage}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            totalPages={totalPages}
            startIndex={startIndex}
            endIndex={endIndex}
            handleViewDetails={handleViewDetails}
          />
        </div>
      </div>
      {/* Modal for viewing details */}
      <Modal_Component
        showModal={showModal}
        onClose={handleCloseModal}
        title="Campaign Scheme Details"
        body={modalBody}
        onCancel={handleCloseModal}
        onOk={handleOk}
      />

    </div>
  );
}

export default CampaignReport;
