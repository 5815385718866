import React, { useEffect, useState } from "react";
import { CiFilter } from "react-icons/ci";
import { Common } from "../../component/common/common";
import styles from "./Dashboard.module.css";
import CanvasJSReact from "@canvasjs/react-charts";
import { useDispatch, useSelector } from "react-redux";
import { getDashboardData, mcoOilData, mcoOilData2, getCategoryData } from "../../features/dashboardSlice";
import { getStateData } from "../../features/stateSlice";
import { getDistrictData } from "../../features/districtSlice";
import Button from "../../component/Button/Button";
import MonthlyEarnedReports from "./MonthlyEarnedBurnedReports";
import axios from "axios";
import appConfig from "../../AppConfig";
import Spinner from 'react-bootstrap/Spinner';
import { Select, Space } from 'antd';



const CanvasJSChart = CanvasJSReact.CanvasJSChart;

const userTypeOptions = [
  { label: "Mechanic", value: "mechanic" },
  { label: "Retailer", value: "retailer" },
  { label: "Customer", value: "customer" },
  { label: "All", value: "all" }
];


function Dashboard() {
  
  const dispatch = useDispatch();
  const msLoggedUserId = localStorage.getItem("userId");
  const msLoggedRoleId = localStorage.getItem("roleId");
  const { categoryData, data, oilData, oilData2, loading, loadingOilData, loadingcategory } = useSelector((state) => state.dashboard);
  const stateList = useSelector((state) => state.stateList.data);
  console.log("stateList", stateList);
  const districtList = useSelector((state) => state.districtList.data);
  console.log("districtList", districtList);
  const [filter, setFilter] = useState("All");
  const [displayFilter, setDisplayFilter] = useState(false);
  //
  const [stateItemSelected, setStateItemSelected] = useState([]);
  const [districtItemSelected, setDistrictItemSelected] = useState([]);

  const [stateItemSelected_two, setStateItemSelected_two] = useState([]);
  const [districtItemSelected_two, setDistrictItemSelected_two] = useState([]);

  const [stateItemSelected_category, setStateItemSelected_category] = useState([]);
  const [districtItemSelected_category, setDistrictItemSelected_category] = useState([]);

  const [months, setMonths] = useState([]);
  const [years, setYears] = useState([]);
  const currentDate = new Date();
  const currentMonth = currentDate.toLocaleString('default', { month: 'long' });
  const currentYear = currentDate.getFullYear();
  const [selectedMonth, setSelectedMonth] = useState(currentMonth);
  const [selectedUserType, setSelectedUserType] = useState("All");
  const [selectedYear, setSelectedYear] = useState(currentYear);

  // date
  const [startDate, setStartDate] = useState(() => {
    const today = new Date();
    today.setMonth(today.getMonth() - 1);
    return today;
  });

  const [endDate, setEndDate] = useState("");

  const formattedStartDate = startDate
    ? startDate.toLocaleDateString("en-CA", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    })
    : "";
  const formattedEndDate = endDate
    ? endDate.toLocaleDateString("en-CA", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    })
    : "";

  const options = {
    animationEnabled: true,
    exportEnabled: true,
    theme: "light2",
    title: {
      text: "Groups",
    },
    data: [
      {
        type: "pie",
        startAngle: -90,
        toolTipContent: "<b>{label}</b>: {z} Lt",
        indexLabel: "{label}: {y}%",
        dataPoints:
        categoryData.groupcnt && categoryData.groupcnt.length > 1
            ? categoryData.groupcnt.map((item, index) => ({
              y: item.percentage === null ? 0 : parseFloat(item.percentage),
              z: item.volume === null ? 0 : parseFloat(item.volume),
              label: item.groupName,
              color: getRandomColorGroup(index),
            }))
            : [
              {
                y: 100,
                label: "No Data Found",
                color: "grey",
              },
            ],
      },
    ],

  };

  const options1 = {
    animationEnabled: true,
    exportEnabled: true,
    theme: "light2",
    title: {
      text: "Sub-Categories",
    },
    data: [
      {
        type: "pie",
        startAngle: -90,
        toolTipContent: "<b>{label}</b>: {z}L",
        indexLabel: "{label}: {y}%",
        dataPoints:
        categoryData.subcategorycnt && categoryData.subcategorycnt.length > 0
            ? categoryData.subcategorycnt.map((item, index) => ({
              y: item.percentage === null ? 0 : parseFloat(item.percentage),
              z: item.volume === null ? 0 : parseFloat(item.volume),
              label: item.subCategory,
              color: getRandomColor(index),
            }))
            : [
              {
                y: 100,
                label: "No Data Found",
                color: "grey",
              },
            ],
      },
    ],
  };
  // Function to generate random colors
  function getRandomColor(index) {
    const colors = [
      "#FF0000",
      "#808080",
      "#FFCE56",
      "#4BC0C0",
      "#9966FF",
      "#FF8C9B",
      "#77DD77",
      "#FFD700",
    ];
    return colors[index % colors.length];
  }

  
  function getRandomColorGroup(index) {
    const colors = [
      "#ED9A00",
      "#008001",
      "#FFCE56",
      "#4BC0C0",
      "#9966FF",
      "#FF8C9B",
      "#77DD77",
      "#FFD700",
    ];
    return colors[index % colors.length];
  }

  // month and year
  useEffect(() => {
    const fetchDropdownData = async () => {
      try {
        const monthResponse = await axios.get(`${appConfig.apiUrl}msMonthDropDown`, {
          headers: { 'x-api-key': appConfig.apiKey },
        });
        setMonths(monthResponse.data.data.months);
        if (!monthResponse.data.data.months.includes(currentMonth)) {
          setSelectedMonth(monthResponse.data.data.months[0]);
        }

        const yearResponse = await axios.get(`${appConfig.apiUrl}msYearDropDown`, {
          headers: { 'x-api-key': appConfig.apiKey },
        });
        setYears(yearResponse.data.data.years);
        if (!yearResponse.data.data.years.includes(currentYear)) {
          setSelectedYear(yearResponse.data.data.years[0]);
        }
      } catch (error) {
        console.error("Error fetching dropdown data:", error);
      }
    };

    fetchDropdownData();
  }, [currentMonth, currentYear]);


  const handleMonthChange = (e) => {
    setSelectedMonth(e.target.value);
  };

  const handleUserTypeChange = (e) => {
    setSelectedUserType(e.target.value);
  };

  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
  };

  useEffect(() => {
    dispatch(
      getDashboardData({
        msLoggedUserId: msLoggedUserId,
        msLoggedRoleId: msLoggedRoleId,
        role: filter,
        fromDate: formattedStartDate,
        toDate: formattedEndDate,
        district: districtItemSelected,
        state: stateItemSelected,
      })
    );
  }, [dispatch, filter]);


  const selectedDistrictNames = districtItemSelected_category.map(id =>
    districtList.find(district => district.id === id)?.district
  );


  useEffect(() => {
    dispatch(
      mcoOilData({
        msLoggedUserId: msLoggedUserId,
        msLoggedRoleId: msLoggedRoleId,
        role: filter,
        district: districtItemSelected_two,
        monthFilter: selectedMonth,
        YearFilter: selectedYear,
        state: stateItemSelected_two,
      })
    );
  }, [dispatch, filter]);

 

  useEffect(() => {
    dispatch(
      getCategoryData({
        msLoggedUserId: msLoggedUserId,
        msLoggedRoleId: msLoggedRoleId,
        role: selectedUserType,
        district: selectedDistrictNames,
        state: stateItemSelected_category,
      })
    );
  }, [dispatch]);


  useEffect(() => {
    dispatch(
      mcoOilData2({
        msLoggedUserId: msLoggedUserId,
        msLoggedRoleId: msLoggedRoleId,
        role: filter,
        monthFilter: selectedMonth,
        YearFilter: selectedYear,
        district: districtItemSelected_two,
        state: stateItemSelected_two,
      })
    );
  }, [dispatch, filter]);

  useEffect(() => {
    dispatch(getStateData({ msLoggedUserId, msLoggedRoleId }));
  }, []);

  useEffect(() => {
    const selectedStates = [...(stateItemSelected || []), ...(stateItemSelected_two || [])];

    if (selectedStates.length > 0) {
      dispatch(
        getDistrictData({
          msLoggedUserId,
          msLoggedRoleId,
          selectedStates
        })
      );
    }
  }, [stateItemSelected, stateItemSelected_two, dispatch, msLoggedUserId, msLoggedRoleId]);

  const handleCheckboxChange = (event) => {
    const selectedFilter = event.target.value;
    setFilter(selectedFilter);
  };

  //
  const handleStateChange = (selectedIds) => {
    const selectedStateNames = selectedIds.map(id =>
      stateList.find(state => state.id === id)?.state
    );

    setStateItemSelected(selectedStateNames);

    // Dispatch the action to get district data
    if (selectedStateNames.length > 0) {
      dispatch(getDistrictData({
        msLoggedUserId,
        msLoggedRoleId,
        selectedStates: selectedStateNames
      }));
    }
  };

  const handleDistrictChange = (selectedValues) => {
    setDistrictItemSelected(selectedValues);
  };

  const stateOptionsWithIds = stateList.map((item) => ({
    label: item.state,
    value: item.id
  }));

  const selectedStateIds_two = stateItemSelected_two.map(name =>
    stateList.find(item => item.state === name)?.id
  );

  const selectedStateIds = stateItemSelected.map(name =>
    stateList.find(item => item.state === name)?.id
  );

  const selectedCategory_stateIds = stateItemSelected_category.map(name =>
    stateList.find(item => item.state === name)?.id
  );

  const handleStateChange_two = (selectedIds) => {
    // Convert selected IDs to state names
    const selectedStateNames = selectedIds.map(id =>
      stateList.find(state => state.id === id)?.state
    );

    setStateItemSelected_two(selectedStateNames);

    // Dispatch the action to get district data
    if (selectedStateNames.length > 0) {
      dispatch(getDistrictData({
        msLoggedUserId,
        msLoggedRoleId,
        selectedStates: selectedStateNames
      }));
    }
  };

  const handleStateChange_category = (selectedIds) => {
    const selectedStateNames = selectedIds.map(id =>
      stateList.find(state => state.id === id)?.state
    );

    setStateItemSelected_category(selectedStateNames);

    // Dispatch the action to get district data
    if (selectedStateNames.length > 0) {
      dispatch(getDistrictData({
        msLoggedUserId,
        msLoggedRoleId,
        selectedStates: selectedStateNames
      }));
    }
 
  };

  const handleDistrictChange_category = (selectedValues) => {
    setDistrictItemSelected_category(selectedValues);
  };

  const handleDistrictChange_two = (selectedValues) => {
    setDistrictItemSelected_two(selectedValues);
  };

  const handleCategorysubmit = () => {

    dispatch(
      getCategoryData({
        msLoggedUserId: msLoggedUserId,
        msLoggedRoleId: msLoggedRoleId,
        role: selectedUserType,
        district: selectedDistrictNames,
        state: stateItemSelected_category,
      })
    );

  };

  const handleSecondsubmit = () => {

    const selectedDistrictNames = districtItemSelected_two.map(id =>
      districtList.find(district => district.id === id)?.district
    );

    dispatch(
      mcoOilData({
        msLoggedUserId: msLoggedUserId,
        msLoggedRoleId: msLoggedRoleId,
        monthFilter: selectedMonth,
        YearFilter: selectedYear,
        district: selectedDistrictNames,
        state: stateItemSelected_two,
      })
    );
    dispatch(
      mcoOilData2({
        msLoggedUserId: msLoggedUserId,
        msLoggedRoleId: msLoggedRoleId,
        monthFilter: selectedMonth,
        YearFilter: selectedYear,
        district: selectedDistrictNames,
        state: stateItemSelected_two,
      })
    );
  };

  const handleSubmit = () => {
    dispatch(
      getDashboardData({
        msLoggedUserId: msLoggedUserId,
        msLoggedRoleId: msLoggedRoleId,
        role: filter,
        fromDate: formattedStartDate,
        toDate: formattedEndDate,
        district: districtItemSelected,
        state: stateItemSelected,
      })
    );
  };

  const toggleFilter = () => {
    setDisplayFilter(!displayFilter);
  };

  return (
    <div className="container" style={{ minHeight: "81.8vh" }}>
      <div className="row">
        <div className="col-md-12">
          <div className="row">
            <div className="col-md-9 mt-5">
              <h2 className="text-capitalize fs-5 fst-italic mt-3">
                Dashboard
              </h2>
            </div>
            <div className="col-md-3 px-sm-3 mt-md-5 d-md-flex justify-content-md-end">
              <div>
                <button className="btn btn-secondary" onClick={toggleFilter}>
                  <CiFilter /> Filter
                </button>
              </div>
            </div>
          </div>
        </div>
        {displayFilter && (
          <>
            <div className="col-md-12 mt-3">
              <div className="row">
                <div className="col-md-8">
                  <div className="row">
                    <div className="col-md-2">
                      <input
                        type="checkbox"
                        value="Mechanic"
                        onChange={handleCheckboxChange}
                        checked={filter === "Mechanic"}
                      />
                      <span className={styles.checkboxLabel}>Mechanic</span>
                    </div>
                    <div className="col-md-2">
                      <input
                        type="checkbox"
                        value="Retailer"
                        onChange={handleCheckboxChange}
                        checked={filter === "Retailer"}
                      />
                      <span className={styles.checkboxLabel}>Retailer</span>
                    </div>
                    <div className="col-md-2">
                      <input
                        type="checkbox"
                        value="Customer"
                        onChange={handleCheckboxChange}
                        checked={filter === "Customer"}
                      />
                      <span className={styles.checkboxLabel}>Customer</span>
                    </div>
                    <div className="col-md-2">
                      <input
                        type="checkbox"
                        value="All"
                        onChange={handleCheckboxChange}
                        checked={filter === "All"}
                      />
                      <span className={styles.checkboxLabel}>All</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12 mt-3">
              <div className="row">
                <div className="col-md-2">
                  <Common.CustomDatePicker
                    label={"From Date"}
                    dateFormat={"dd-MM-yyyy"}
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    placeholderText="dd-yy-yyyy"
                  />
                </div>
                <div className="col-md-2">
                  <Common.CustomDatePicker
                    label="To Date"
                    dateFormat={"dd-MM-yyyy"}
                    selected={endDate}
                    onChange={(date) => setEndDate(date)}
                    placeholderText="dd-yy-yyyy"
                  />
                </div>
                <div className="col-md-2">
                  <label className={styles.label}>Select State</label>
                  <Space style={{ width: '100%', marginTop: '-5px' }} direction="vertical">
                    <Select
                      mode="multiple"
                      allowClear
                      style={{ width: '100%', }}
                      placeholder="Please select"
                      onChange={handleStateChange}
                      options={stateOptionsWithIds}
                      value={selectedStateIds}
                      size="large"
                    />
                  </Space>
                </div>
                <div className="col-md-2">
                  <label className={styles.label}>Select District</label>
                  <Space style={{ width: '100%', marginTop: '-5px' }} direction="vertical">
                    <Select
                      mode="multiple"
                      allowClear
                      style={{ width: '100%' }}
                      placeholder="Please select"
                      onChange={handleDistrictChange}
                      options={districtList.map((item) => ({ label: item.district, value: item.id }))}
                      value={districtItemSelected}
                      size="large"
                    />
                  </Space>
                </div>
                <div className="col-md-2 mt-4">
                  <div className="mt-2"></div>
                  <Button
                    variant={"submit"}
                    onClick={handleSubmit}
                    disabled={loading}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      padding: '0.5rem 1rem',
                      backgroundColor: loading ? '#a3b9d5' : '',
                    }}
                  >
                    {loading ? (
                      <>
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                          style={{
                            marginRight: '8px', // Space between spinner and text
                            color: '#003366',
                            borderWidth: '0.2em',
                          }}
                        />
                        <span>Submit</span>
                      </>
                    ) : (
                      "Submit"
                    )}
                  </Button>
                </div>
              </div>
            </div>
          </>
        )}
        {loading ? (
          <div className="col-md-12 mt-5">
            <h1 className="text-center">Loading...</h1>
          </div>
        ) : (
          <>
            <div className="col-md-12 mt-md-3">
              <div className="row mt-3">
                {Object.entries(data)
                  .filter(
                    ([key]) =>
                      key !== "role" &&
                      key !== "subcategorycnt" &&
                      key !== "groupcnt"
                  )
                  .map(([key, value]) => (
                    <div
                      key={key}
                      className={`col-md-4 border border-5 border-light ${styles.cardItems}`}
                    >
                      <h2>{value}</h2>
                      <h4>
                        {key === "totalcnt"
                          ? "Total Members"
                          : key === "activecnt"
                            ? "Active Members"
                            : key === "newuserscnt"
                              ? "New Registered"
                              : key === "groupcnt"
                                ? "Total Group"
                                : key === "subcategorycnt"
                                  ? "Total Sub-Group"
                                  : null}
                      </h4>
                    </div>
                  ))}
              </div>
            </div>
          </>
        )}
        <div className="col-md-12 mt-5 mb-5">
          <div className="row">
          <h2 className="heading-motul" id="title-dash" >Product volume dashboard</h2>
            <div className="col-md-12 mt-3">
              <div className="row" id="category_section_dash">
                
                <div className="col-md-2">
                  <Common.SelectInput
                    id="user-type-dropdown"
                    label="Select User Type"
                    options={userTypeOptions}
                    value={selectedUserType}
                    onChange={handleUserTypeChange}
                    labelKey="label"
                    valueKey="value"
                  />
                </div>

                <div className="col-md-2">
                  <label className={styles.label}>Select State</label>
                  <Space style={{ width: '100%', marginTop: '-5px' }} direction="vertical">
                    <Select
                      mode="multiple"
                      allowClear
                      style={{ width: '100%', }}
                      placeholder="Please select"
                      onChange={handleStateChange_category}
                      options={stateOptionsWithIds}
                      value={selectedCategory_stateIds}
                      size="large"
                    />
                  </Space>
                </div>
                <div className="col-md-2">
                  <label className={styles.label}>Select District</label>
                  <Space style={{ width: '100%', marginTop: '-5px' }} direction="vertical">
                    <Select
                      mode="multiple"
                      allowClear
                      style={{ width: '100%' }}
                      placeholder="Please select"
                      onChange={handleDistrictChange_category}
                      options={districtList.map((item) => ({ label: item.district, value: item.id }))}
                      value={districtItemSelected_category}
                      size="large"
                    />
                  </Space>
                </div>
                <div className="col-md-2 mt-4">
                  <div className="mt-2"></div>
                  <Button
                    variant={"submit"}
                    onClick={handleCategorysubmit}
                    disabled={loadingcategory}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      padding: '0.5rem 1rem',
                      backgroundColor: loadingcategory ? '#a3b9d5' : '',
                    }}
                  >
                    {loadingcategory ? (
                      <>
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                          style={{
                            marginRight: '8px', // Space between spinner and text
                            color: '#003366',
                            borderWidth: '0.2em',
                          }}
                        />
                        <span>Submit</span>
                      </>
                    ) : (
                      "Submit"
                    )}
                  </Button>
                </div>
              </div>
            </div>
            <div className="col-md-6">

              <div className="shadow">
                <CanvasJSChart options={options} />
              </div>
            </div>
            <div className="col-md-6">
              <div className="shadow">
                <CanvasJSChart options={options1} />
              </div>
            </div>
          </div>
        </div>
        <div className="fh-motul-two">
          <h2 className="heading-motul">Product Subcategory Performance Dashboard</h2>
          <div className="col-md-12 mt-3">
            <div className="row">

              <div className="col-md-2">
                <Common.SelectInput
                  id="month-dropdown"
                  label="Select Month"
                  options={months.map((month) => ({ label: month, value: month }))}
                  value={selectedMonth}
                  onChange={handleMonthChange}
                  labelKey="label"
                  valueKey="value"
                />
              </div>

              <div className="col-md-2">
                <Common.SelectInput
                  id="year-dropdown"
                  label="Select Year"
                  options={years.map((year) => ({ label: year, value: year }))}
                  value={selectedYear}
                  onChange={handleYearChange}
                  labelKey="label"
                  valueKey="value"
                />
              </div>

              <div className="col-md-2">
                <label className={styles.label}>Select State</label>
                <Space style={{ width: '100%', marginTop: '-4px' }} direction="vertical">
                  <Select
                    mode="multiple"
                    allowClear
                    style={{ width: '100%', }}
                    placeholder="Please select"
                    onChange={handleStateChange_two}
                    options={stateOptionsWithIds}
                    value={selectedStateIds_two}
                    size="large"
                    className="custom-multi-select"
                  />
                </Space>
              </div>
              <div className="col-md-2">
                <label className={styles.label}>Select District</label>
                <Space style={{ width: '100%', marginTop: '-4px' }} direction="vertical">
                  <Select
                    mode="multiple"
                    allowClear
                    style={{ width: '100%' }}
                    placeholder="Please select"
                    onChange={handleDistrictChange_two}
                    options={districtList.map((item) => ({ label: item.district, value: item.id }))}
                    value={districtItemSelected_two}
                    size="large"

                  />
                </Space>
              </div>
              <div className="col-md-2 mt-4">
                <div className="mt-2"></div>
                <Button
                  variant={"submit"}
                  onClick={handleSecondsubmit}
                  disabled={loadingOilData}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: '0.5rem 1rem',
                    backgroundColor: loadingOilData ? '#a3b9d5' : '',
                  }}
                >
                  {loadingOilData ? (
                    <>
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        style={{
                          marginRight: '8px', // Space between spinner and text
                          color: '#003366',
                          borderWidth: '0.2em',
                        }}
                      />
                      <span>Submit</span>
                    </>
                  ) : (
                    "Submit"
                  )}
                </Button>
              </div>
            </div>
          </div>
          <div className="fh-motul-3">
            <div className="fh-div oil">
              <h2>MCO Engine Oil</h2>
              <h3>No. of scans done</h3>
              <p>{oilData?.mcoCount || 0}</p>
              <h3>Volume sold (L)</h3>
              <p>{oilData2?.mcoVolume || 0}</p>
            </div>
            <div className="fh-div oil">
              <h2>PCMO Engine Oil</h2>
              <h3>No. of scans done</h3>
              <p>{oilData?.pcmoCount || 0}</p>
              <h3>Volume sold (L)</h3>
              <p>{oilData2?.pcmoVolume || 0}</p>
            </div>
            <div className="fh-div oil">
              <h2>HDDO Engine Oil</h2>
              <h3>No. of scans done</h3>
              <p>{oilData?.hddoCount || 0}</p>
              <h3>Volume sold (L)</h3>
              <p>{oilData2?.hddoVolume || 0}</p>
            </div>
            <div className="fh-div oil">
              <h2>Others</h2>
              <h3>No. of scans done</h3>
              <p>{oilData?.othersCount || 0}</p>
              <h3>Volume sold (L)</h3>
              <p>{oilData2?.othersVolume || 0}</p>
            </div>
          </div>
        </div>
      </div>

      <MonthlyEarnedReports />

    </div>
  );
}

export default Dashboard;
