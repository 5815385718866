import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import appConfig from "../AppConfig";
import { stringify } from 'qs';

const initialState = {
  data: [],
  categoryData: [],
  oilData: {},
  oilData2: {},
  loading: false,
  loadingcategory: false,
  loadingOilData: false,
  error: "",
};

// getDashboardData
export const getDashboardData = createAsyncThunk(
  "dashboard/getDashboardData",
  async (data, { rejectWithValue }) => {
    const urls = `${appConfig.apiUrl}msDashboard`;
    const headers = {
      "X-API-Key": appConfig.apiKey,
      "Content-Type": "application/x-www-form-urlencoded",
    };
    try {
      const response = await axios.post(urls, data, { headers });
      const result = await response.data.data;
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getCategoryData = createAsyncThunk(
  "dashboard/getCategoryData",
  async (data, { rejectWithValue }) => {
    const urls = `${appConfig.apiUrl}msDashboard`;
    const headers = {
      "X-API-Key": appConfig.apiKey,
      "Content-Type": "application/x-www-form-urlencoded",
    };
    try {
      const response = await axios.post(urls, data, { headers });
      const result = await response.data.data;
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const mcoOilData = createAsyncThunk(
  "dashboard/mcoOilData",
  async (params, { rejectWithValue }) => {
    const apiUrl = `${appConfig.apiUrl}msSubCategoryDataCount`;
    const headers = {
      "x-api-key": appConfig.apiKey,
    };

    try {
      const baseParams = new URLSearchParams({
        msLoggedUserId: params.msLoggedUserId,
        msLoggedRoleId: params.msLoggedRoleId,
        monthFilter: params.monthFilter,
        YearFilter: params.YearFilter,
      }).toString();

      const districts = params.district.map(d => `district[]=${encodeURIComponent(d)}`).join('&');
      const states = params.state.map(s => `state[]=${encodeURIComponent(s)}`).join('&');

      const url = `${apiUrl}?${baseParams}&${districts}&${states}`;

      const response = await axios.get(url, {
        headers: headers,
      });
      const result = response.data.data;
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const mcoOilData2 = createAsyncThunk(
  "dashboard/mcoOilData2",
  async (params, { rejectWithValue }) => {
    const apiUrl = `${appConfig.apiUrl}msSubCategoryDataVolume`;
    const headers = {
      "x-api-key": appConfig.apiKey,
    };

    try {

      const baseParams = new URLSearchParams({
        msLoggedUserId: params.msLoggedUserId,
        msLoggedRoleId: params.msLoggedRoleId,
        monthFilter: params.monthFilter,
        YearFilter: params.YearFilter,
      }).toString();

      const districts = params.district.map(d => `district[]=${encodeURIComponent(d)}`).join('&');
      const states = params.state.map(s => `state[]=${encodeURIComponent(s)}`).join('&');

      const url = `${apiUrl}?${baseParams}&${districts}&${states}`;


      const response = await axios.get(url, {
        headers: headers,
      });
      const result = response.data.data;
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const dashboardDetails = createSlice({
  name: "dashboardDetails",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getDashboardData.pending, (state) => {
        state.loading = true;
      })
      .addCase(getDashboardData.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
        state.error = "";
      })
      .addCase(getDashboardData.rejected, (state, action) => {
        state.loading = false;
        state.data = [];
        state.error = action.error.message;
      })
      .addCase(getCategoryData.pending, (state) => {
        state.loadingcategory = true;
      })
      .addCase(getCategoryData.fulfilled, (state, action) => {
        state.loadingcategory = false;
        state.categoryData = action.payload;
        state.error = "";
      })
      .addCase(getCategoryData.rejected, (state, action) => {
        state.loadingcategory = false;
        state.categoryData = [];
        state.error = action.error.message;
      })
      .addCase(mcoOilData.pending, (state) => {
        state.loadingOilData = true;
      })
      .addCase(mcoOilData.fulfilled, (state, action) => {
        state.loadingOilData = false;
        state.oilData = action.payload;
        state.error = "";
      })
      .addCase(mcoOilData.rejected, (state, action) => {
        state.loadingOilData = false;
        state.oilData = {};
        state.error = action.error.message;
      })
      .addCase(mcoOilData2.pending, (state) => {
        state.loadingOilData = true;
      })
      .addCase(mcoOilData2.fulfilled, (state, action) => {
        state.loadingOilData = false;
        state.oilData2 = action.payload;
        state.error = "";
      })
      .addCase(mcoOilData2.rejected, (state, action) => {
        state.loadingOilData = false;
        state.oilData2 = {};
        state.error = action.error.message;
      });
  },
});

export default dashboardDetails.reducer;
