import { Link } from "react-router-dom";
import React, { useEffect } from "react";
import NavDropdown from "react-bootstrap/NavDropdown";
import { Container, Nav, Navbar } from "react-bootstrap";
import Logo from "../../../assets/svg/motulLogoSvg.svg";
import { useDispatch, useSelector } from "react-redux";
import { getMsNavData } from "../../../features/navSlice";

const CustomNavbar = ({ userName, handleLogout }) => {

  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.navList.data);
  const msloggedRoleId = localStorage.getItem("roleId");

  useEffect(() => {
    dispatch(getMsNavData(msloggedRoleId));
  }, [dispatch, msloggedRoleId]);

  const convertedData =
    data &&
    data.map((navItem) => {
      const items = navItem.data.map((subNavItem) => {
        return {
          id: subNavItem.subNavSequence || 1,
          itemTitle: subNavItem.subtopnavName || navItem.topnavName,
          itemurl: subNavItem.pageUrl || navItem.topPageUrl,
        };
      });
      return {
        id: navItem.topNavId,
        title: navItem.topnavName,
        url: navItem.topPageUrl,
        items: items,
      };
    });
    
  return (
    <>
      <Navbar
        expand="lg"
        className="text-white"
        fixed="top"
        style={{ backgroundColor: "#ed1d24" }}
      >
        <Container>
          <Navbar.Brand>
            <Link to={"/dashboard"}>
              <img src={Logo} alt="" className="w-50" />
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll">
            <Nav
              className="me-auto my-2 my-lg-0"
              style={{ maxHeight: "250px" }}
              navbarScroll
            >
              {convertedData &&
                convertedData.map((navItem) => (
                  <NavDropdown
                    key={navItem.id}
                    title={navItem.title}
                    id="navbarScrollingDropdown"
                  >
                    {navItem.items.map((subItem) => (
                      <NavDropdown.Item
                        as={Link}
                        key={subItem.id}
                        to={subItem.itemurl}
                      >
                        {subItem.itemTitle}
                      </NavDropdown.Item>
                    ))}
                  </NavDropdown>
                ))}
            </Nav>
            <Nav className="">
              <NavDropdown
                title={userName}
                style={{ color: "#fff" }}
                id="navbarScrollingDropdown"
              >
                <NavDropdown.Item as={Link} to={"/settings"}>
                  My Profile
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to={"/"}>
                  <button
                    className={`btn text-light w-100`}
                    style={{ backgroundColor: "#ed1d24" }}
                    onClick={handleLogout}
                  >
                    Logout
                  </button>
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default CustomNavbar;
