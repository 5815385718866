import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Filters from "./common/Filters/Filters";
import Common from "./common/common";
import { fetchTierConfigurations } from "../features/tierConfigurationSlice";
import { fetchStateList, getStateData } from "../features/stateSlice";
import { fetchDistrictList, getDistrictData } from "../features/districtSlice";
import { downloadFile } from "../features/DownloadFileSlice";
import axios from "axios";
import appConfig from "../AppConfig";
import Swal from "sweetalert2";
import { Result } from "antd";
import { getSubcategoryData } from "../features/TierSubcategoryListSlice";
import TierConfigFilter from "./common/Filters/TierConfigFilter";



export default function TierConfigurationReport() {
  const dispatch = useDispatch();
  const msLoggedUserId = localStorage.getItem("userId");
  const msLoggedRoleId = localStorage.getItem("roleId");
  // const msLoggedRoleId = "4";

  const token = localStorage.getItem("logintoken");

  const stateList = useSelector((state) => state.stateList.data);
  const districtList = useSelector((state) => state.districtList.data);
  // const data = useSelector((state) => state.tierConfiguration.configurations);
  const { data, totalRecords } = useSelector((state) => state.tierConfiguration.configurations);



  console.log("data TierConfigurationReport ", data)
  const productSubcat = useSelector((state) => state.TierSubcategoryList.data);
  const [monthOptions, setMonthOptions] = useState([]);

  const productSubcategoryOptions = productSubcat.map((field) => {
    return { label: field.subcategory, key: field.subcategory };
  });


  const slabOptions = [
    { label: "Points", value: "Points" },
    { label: "Scans", value: "Scans" },
  ];


  // Initialize filters state
  const [filters, setFilters] = useState({
    stateItemSelected: "",
    districtItemSelected: "",
    startDate: "",
    endDate: "",
    tierStatus: "",
    tierSlabType: "",
    subCategory: "",
    monthYear: "",
  });

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  // const totalRecords = data.length;
  const totalPages = Math.ceil(totalRecords / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;

  const tierStatusOptions = [{ label: "Active", value: "active" }, { label: "Deactivated", value: "deactivated" }]

  const handleMonthChange = (e) => {
    setFilters((prev) => ({
      ...prev,
      monthYear: e.target.value,
    }));
  }
  // Initial data fetch for states, districts, and configurations
  const handleReFetchData = () => {
    dispatch(
      fetchTierConfigurations({
        msLoggedUserId,
        msLoggedRoleId,
        token,
        state: "",
        district: "",
        // fromDate: filters.startDate,
        // toDate: filters.endDate,
        monthYear: "",
        startIndex
      })
    );
    dispatch(getSubcategoryData({ msLoggedRoleId, msLoggedUserId, token }));
  };
  const getMonthOptions = async () => {
    const res = await axios.get(`${appConfig.apiUrl}msMonthFilter?token=${token}&msLoggedUserId=${msLoggedUserId}&msLoggedRoleId=${msLoggedRoleId}`,
      {
        headers: {
          'X-API-Key': appConfig.apiKey,
          'Content-Type': 'application/json'
        }
      }
    )
    const data = await res.data.data.months;
    console.log("data", data)
    const formattedMonths = data.map((month) => ({
      label: month,
      value: month
    }));
    setMonthOptions(formattedMonths);
    console.log("monthfilter data", res.data.data)
  }
  console.log("this is monthoptions", monthOptions)
  useEffect(() => {
    getMonthOptions();
  }, [])


  useEffect(() => {
    // Fetch state list
    dispatch(getStateData({ msLoggedRoleId, msLoggedUserId }));

    // Fetch initial tier configurations
    handleReFetchData();
  }, [dispatch, msLoggedUserId, msLoggedRoleId, token]);
  console.log("this is filters now", filters);

  // Fetch districts when state changes
  useEffect(() => {
    console.log("finding district list");
    console.log(filters.stateItemSelected);

    if (filters.stateItemSelected) {
      dispatch(
        getDistrictData({
          selectedStates: filters.stateItemSelected,
          msLoggedUserId,
          msLoggedRoleId,
        })
      );
    }
  }, [filters.stateItemSelected, dispatch]);

  // Handle filter changes without triggering API calls
  const handleFilterChange = (name, e) => {
    //in case of date inputs, e will directly give value of dates, while in select inputs, e gives event

    console.log("this is name and e", name, e);

    // console.log("this is name and value", name, e.target.value)

    if (name === "stateItemSelected") {
      setFilters((prev) => ({
        ...prev,
        stateItemSelected: e.target.value,
        districtItemSelected: "", // Reset district when state changes
      }));
    } else if (name === "districtItemSelected") {
      setFilters((prev) => ({
        ...prev,
        districtItemSelected: e.target.value,
      }));
    }
    else if (name === "tierStatus") {
      setFilters((prev) => ({
        ...prev,
        tierStatus: e.target.value,
      }));
    }
    else if (name === "tierSlabType") {
      setFilters((prev) => ({
        ...prev,
        tierSlabType: e.target.value,
      }))
    }
    else if (name === "subCategory") {
      setFilters((prev) => ({
        ...prev,
        subCategory: e.target.value,
      }))
    }
    else {
      setFilters((prev) => {
        const updatedFilters = { ...prev, [name]: e };

        // Ensure startDate is not greater than endDate
        if (
          name === "startDate" &&
          updatedFilters.endDate &&
          updatedFilters.startDate > updatedFilters.endDate
        ) {
          updatedFilters.endDate = ""; // Set startDate to endDate if startDate is greater
        }

        // Ensure endDate is not before startDate
        if (
          name === "endDate" &&
          updatedFilters.startDate &&
          updatedFilters.endDate < updatedFilters.startDate
        ) {
          updatedFilters.startDate = ""; // Set endDate to startDate if endDate is earlier
        }

        return updatedFilters;
      });
    }

    // setCurrentPage(1); // Reset to first page when filters change
  };
  const handleDeactivate = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You are about to deactivate this tier.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const res = await axios.post(
            `${appConfig.apiUrl}msUpdateTierConfiguration`,
            {
              msLoggedUserId,
              msLoggedRoleId,
              tierStatus: "Inactive",
              tierId: id,
              token: token,
            },
            {
              headers: {
                "X-API-Key": appConfig.apiKey,
                "Content-Type": "application/x-www-form-urlencoded",
              },
            }
          );
          console.log("response", res);
          if (res.data.status === true) {
            Swal.fire({
              title: "Success!",
              text: "Deactivated Successfully",
              icon: "success",
              confirmButtonText: "Ok",
            }).then((result) => {
              if (result.isConfirmed) {
                handleReFetchData();
              }
            });
          } else {
            Swal.fire({
              title: "Error!",
              text: "Something went wrong",
              icon: "error",
              confirmButtonText: "Ok",
            });
          }
        } catch (err) {
          Swal.fire({
            title: "Error!",
            text: "Internal Server Error",
            icon: "error",
            confirmButtonText: "Ok",
          });
        }
      }
    })
  };

  // Handle filter submit - only place where API is called
  const handleSubmit = () => {
    console.log("submitting");
    dispatch(
      fetchTierConfigurations({
        msLoggedUserId,
        msLoggedRoleId,
        token,
        state: filters.stateItemSelected,
        district: filters.districtItemSelected,
        // startDate: filters.startDate,
        // endDate: filters.endDate,
        monthYear: filters.monthYear,
        tierStatus: filters.tierStatus,
        tierSlabType: filters.tierSlabType,
        subCategory: filters.subCategory,
        startIndex: startIndex
      })
    );
  };

  useEffect(() => {
    dispatch(
      fetchTierConfigurations({
        msLoggedUserId,
        msLoggedRoleId,
        token,
        state: filters.stateItemSelected,
        district: filters.districtItemSelected,
        // startDate: filters.startDate,
        // endDate: filters.endDate,
        monthYear: filters.monthYear,
        tierStatus: filters.tierStatus,
        tierSlabType: filters.tierSlabType,
        subCategory: filters.subCategory,
        startIndex: startIndex
      })
    );
  }, [dispatch, currentPage]);

  useEffect(() => {
    dispatch(
      fetchTierConfigurations({
        msLoggedUserId,
        msLoggedRoleId,
        token,
        state: filters.stateItemSelected,
        district: filters.districtItemSelected,
        // startDate: filters.startDate,
        // endDate: filters.endDate,
        monthYear: filters.monthYear,
        tierStatus: filters.tierStatus,
        tierSlabType: filters.tierSlabType,
        subCategory: filters.subCategory,
        startIndex: startIndex
      })
    );
  }, []);

  const handleDownload = () => {
    dispatch(
      downloadFile({
        url: "msTierConfigReportDownload",
        msLoggedUserId,
        msLoggedRoleId,
        token,
      })
    );
  };

  // Transform data for table display
  const tableData = (data && Array.isArray(data))
    ? data.map((item, index) => ({
      "S.No.": (currentPage - 1) * 10 + index + 1, // Dynamic serial number
      SubCategory: item.subCategory,
      Tier: item.tierName,
      "No. of scans": item.scanValue || "N/A",
      "Points needed": item.pointValue || "N/A",
      "Bonus Percentage (%)": `${item.bonusPercentage}%`,
      "Month & Year": item.monthYear,
      "Start date": item.startdate,
      "End date": item.enddate,
      State: item.state,
      District: item.district,
      "Created Date": item.createdDate,
      "Modified Date": item.modifiedDate,
      "Bonus Remarks": item.bonusRemarks,
      "Action/Status": (() => {
        const status = item.status;
        return (
          <button
            className={
              status === "Inactive"
                ? "deactivated-button"
                : status === "Completed"
                  ? "completed-button"
                  : "active-button"
            }
            onClick={() => {
              if (status === "Active") handleDeactivate(item.tierId);
            }}
          >
            {status === "Inactive"
              ? "Deactivated"
              : status === "Completed"
                ? "Completed"
                : "Deactivate"}
          </button>
        );
      })(),
      Status: item.status,
    }))
    : [];



  const validStartIndex = Math.max(0, Math.min(startIndex, tableData.length - 1));
  const displayedData = tableData.slice(validStartIndex, validStartIndex + itemsPerPage);


  console.log("displayedData displayedData", displayedData);

  return (
    <div className="container" style={{ minHeight: "81.8vh" }}>
      <h2 className="text-capitalize fs-5 fst-italic mt-5">
        View Leaderboard/Tier Configuration
      </h2>
      {/* <div className=" my-4">
        <Filters
          startDate={filters.startDate}
          monthOptions={monthOptions}
          handleMonthChange={handleMonthChange}
          showEndDate={false}
          showStartDate={false}
          showMonthYear={true}
          endDate={filters.endDate}
          setStartDate={(value) => handleFilterChange("startDate", value)}
          setEndDate={(value) => handleFilterChange("endDate", value)}
          stateItemSelected={filters.stateItemSelected}
          handleStateChange={(value) =>
            handleFilterChange("stateItemSelected", value)
          }
          districtItemSelected={filters.districtItemSelected}
          handleDistrictChange={(value) =>
            handleFilterChange("districtItemSelected", value)
          }
          stateList={stateList}
          districtList={districtList}
          handleSubmit={handleSubmit}
          msLoggedRoleId={msLoggedRoleId}
          filterPage="tierConfiguration"
          handleDownload={handleDownload}
          showTierStatus={true}
          tierStatusOptions={tierStatusOptions}
          filters={filters}
          handleStatusChange={(value) =>
            handleFilterChange("tierStatus", value)
          }
          showSlab={true}
          slabOptions={slabOptions}
          handleSlabChange={(value) => {
            handleFilterChange("tierSlabType", value)
          }}
          showSubcategory={true}
          subcatOptions={productSubcategoryOptions}
          handleSubcatChange={(value) => {
            handleFilterChange("subCategory", value)
          }}
        />

      </div> */}
      <div className="my-4">
        <TierConfigFilter
          monthOptions={monthOptions}
          handleMonthChange={handleMonthChange}
          showMonthYear={true}
          stateItemSelected={filters.stateItemSelected}
          handleStateChange={(value) => handleFilterChange("stateItemSelected", value)}
          stateList={stateList}
          districtList={districtList}
          districtItemSelected={filters.districtItemSelected}
          handleDistrictChange={(value) => handleFilterChange("districtItemSelected", value)}
          showTierStatus={true}
          tierStatusOptions={tierStatusOptions}
          filters={filters}
          handleStatusChange={(value) => handleFilterChange("tierStatus", value)}
          showSlab={true}
          slabOptions={slabOptions}
          handleSlabChange={(value) => handleFilterChange("tierSlabType", value)}
          showSubcategory={true}
          subcatOptions={productSubcategoryOptions}
          handleSubcatChange={(value) => handleFilterChange("subCategory", value)}
          handleSubmit={handleSubmit}
          handleDownload={handleDownload}
          msLoggedRoleId={msLoggedRoleId}
          filterPage="tierConfiguration"
        />

      </div>
      <Common.CustomTable
        data={tableData}
        columns={[
          "SubCategory",
          "Tier",
          "No. of scans",
          "Points needed",
          "Bonus Percentage (%)",
          "Month & Year",
          "Start date",
          "End date",
          "State",
          "District",
          "Created Date",
          "Modified Date",
          "Bonus Remarks",
          ...(msLoggedRoleId === "1" ? ["Action/Status"] : []),
          ...((msLoggedRoleId === "3" || msLoggedRoleId === "4") ? ["Status"] : []),
        ]}
        itemsPerPage={itemsPerPage}
        totalPages={totalPages}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        totalRecords={totalRecords}
      />
    </div>
  );
}
