// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CommonUI_container__h0Drj {
  /* border: 10px solid #f00; */
  /* padding: 8px 65px; */
}
`, "",{"version":3,"sources":["webpack://./src/screens/common/CommonUI/CommonUI.module.css"],"names":[],"mappings":"AAAA;EACE,6BAA6B;EAC7B,uBAAuB;AACzB","sourcesContent":[".container {\n  /* border: 10px solid #f00; */\n  /* padding: 8px 65px; */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `CommonUI_container__h0Drj`
};
export default ___CSS_LOADER_EXPORT___;
