// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

.CustomTable_table__QUAAM > thead {
  vertical-align: middle !important;
}
thead > th {
  display: inline-block;
  width: 10px;
}
.CustomTable_tableHead__fEc\\+V {
  background-color: #d63031 !important;
  color: #fff !important;
  font-weight: 500;
  font-size: 0.875rem;
}

.CustomTable_tablePaginationContainer__XOLid {
  margin-top: 22px;
  display: flex;
  justify-content: flex-end;
}

.CustomTable_tableData__WpBjS {
  font-size: 0.875rem;
}
p#CustomTable_inprogress__ggjpK {
  background: grey;
  padding: 3px;
  color: white;
  border-radius: 5px;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 11px;
}`, "",{"version":3,"sources":["webpack://./src/component/common/TableComponent/CustomTable.module.css"],"names":[],"mappings":";;AAEA;EACE,iCAAiC;AACnC;AACA;EACE,qBAAqB;EACrB,WAAW;AACb;AACA;EACE,oCAAoC;EACpC,sBAAsB;EACtB,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;EAChB,aAAa;EACb,yBAAyB;AAC3B;;AAEA;EACE,mBAAmB;AACrB;AACA;EACE,gBAAgB;EAChB,YAAY;EACZ,YAAY;EACZ,kBAAkB;EAClB,kBAAkB;EAClB,mBAAmB;EACnB,eAAe;AACjB","sourcesContent":["\n\n.table > thead {\n  vertical-align: middle !important;\n}\nthead > th {\n  display: inline-block;\n  width: 10px;\n}\n.tableHead {\n  background-color: #d63031 !important;\n  color: #fff !important;\n  font-weight: 500;\n  font-size: 0.875rem;\n}\n\n.tablePaginationContainer {\n  margin-top: 22px;\n  display: flex;\n  justify-content: flex-end;\n}\n\n.tableData {\n  font-size: 0.875rem;\n}\np#inprogress {\n  background: grey;\n  padding: 3px;\n  color: white;\n  border-radius: 5px;\n  padding-left: 10px;\n  padding-right: 10px;\n  font-size: 11px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"table": `CustomTable_table__QUAAM`,
	"tableHead": `CustomTable_tableHead__fEc+V`,
	"tablePaginationContainer": `CustomTable_tablePaginationContainer__XOLid`,
	"tableData": `CustomTable_tableData__WpBjS`,
	"inprogress": `CustomTable_inprogress__ggjpK`
};
export default ___CSS_LOADER_EXPORT___;
