import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import appConfig from "../AppConfig";

const initialState = {
  data: [],
  loading: false,
  error: "",
};
// getDashboardData
export const getDistrictData = createAsyncThunk(
  "getDistrictData",
  async (
    { selectedStates, msLoggedUserId, msLoggedRoleId },
    { rejectWithValue }
  ) => {
    // console.log("this is inside getDistrict thunk")
    if (selectedStates.length === 0) {
      return []; 
    }

    const statesParam = Array.isArray(selectedStates)
    ? selectedStates.map(state => `state[]=${encodeURIComponent(state)}`).join("&")
    : `state[]=${encodeURIComponent(selectedStates)}`;


      console.log("this is statesParam",statesParam)
    const urls = `${appConfig.apiUrl}msDistrictList?msLoggedUserId=${msLoggedUserId}&msLoggedRoleId=${msLoggedRoleId}&${statesParam}`;
    console.log("Request URL:", urls);
    const headers = {
      "X-API-Key": appConfig.apiKey,
    };
    
    try {
      const response = await axios.get(urls, {
        headers: headers,
      });
      const result = await response.data.data;
      console.log("district slice", result);
      return result;
      
    } catch (error) {
      console.log("falling in catch")
      return rejectWithValue(error);
    }
  }
);

export const districtList = createSlice({
  name: "districtList",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getDistrictData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getDistrictData.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.error = "";
    });
    builder.addCase(getDistrictData.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = action.error.message;
    });
  },
});

export default districtList.reducer;
