const SessionLogout = () => {
 
  const updateLastActivity = () => {
    localStorage.setItem("lastActivity", Date.now());
  };


  document.addEventListener("click", updateLastActivity);
  document.addEventListener("keypress", updateLastActivity);

  
  const checkInactivity = () => {
    const lastActivity = localStorage.getItem("lastActivity");
    if (lastActivity && Date.now() - lastActivity > 900000) {
      localStorage.removeItem("lastActivity");
      localStorage.clear();
      window.location.href = "/";
    }
  };

 
  const inactivityTimer = setInterval(checkInactivity, 1000); 

  return () => {
    clearInterval(inactivityTimer);
    document.removeEventListener("click", updateLastActivity);
    document.removeEventListener("keypress", updateLastActivity);
  };
};

export default SessionLogout;
