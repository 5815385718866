import React, { useEffect, useState } from "react";
import { Common } from "../../../component/common/common";
import styles from "./VerifyCode.module.css";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";
import { getVerifyCodeData } from "../../../features/verifyCodeSlice";

function VerifyCode() {
  const msLoggedRoleId = localStorage.getItem("roleId");
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { data, message, status } = useSelector(
    (state) => state.verifyCodeList.data
  );
  const [submitted, setSubmitted] = useState(false);
  const [formData, setFormData] = useState({
    title: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async () => {
    setLoading(true); // Start loader when submitting
    await dispatch(
      getVerifyCodeData({ code: formData.title.trim(), msLoggedRoleId })
    );
    setSubmitted(true);
    setFormData({
      title: "",
    });
  };

  useEffect(() => {
    if (submitted && status !== undefined) {
      if (status) {
        Swal.fire({
          title: message,
          icon: "success",
          confirmButtonText: "Okay",
        });
      } else {
        Swal.fire({
          title: message,
          icon: "error",
          confirmButtonText: "Okay",
        });
      }
      setLoading(false); // Stop loader when response received
      setSubmitted(false);
    }
  }, [status, message, submitted]);

  return (
    <div className="container mt-5" style={{ minHeight: "81.8vh" }}>
      <div className="row">
        <div className="col-md-12">
          <h2 className="text-capitalize fs-5 fst-italic">Verify Code</h2>
        </div>
        <div className="col-md-6 mt-2">
          <div className="row">
            <div className="col-sm-12">
              <Common.TextInput
                name={"title"}
                value={formData.title}
                onChange={handleChange}
                label={"Code"}
                placeholder={"Enter code"}
              />
            </div>
            <div className="col-sm-12 mt-3 mb-4">
              <button
                className={
                  formData.title ? "btn btn-danger" : "btn btn-secondary"
                }
                disabled={!formData.title || loading} // Disable button when loading
                onClick={handleSubmit}
              >
                Verify
              </button>
            </div>
            {loading && (
              <div className="col-sm-12 mt-4 mb-3">
                {/* Loader component */}
                <div className="spinner-border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            )}
            {status && (
              <div className="col-sm-12 mt-4 mb-3">
                <table className={styles.table}>
                  <thead className={styles.thead}>
                    <tr>
                      <th className={styles.th}>Coupon Code</th>
                      <th className={styles.th}>Points</th>
                      <th className={styles.th}>Message</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className={styles.td}>{data.code}</td>
                      <td className={styles.td}>{data.points}</td>
                      <td className={styles.td}>{message}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default VerifyCode;
