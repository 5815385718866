// TableComponent.js

import React from "react";
import { Table } from "react-bootstrap";
import styles from "./CustomTable.module.css";
import TablePagination from "../TablePagination/TablePagination";

const CustomTable = ({
  columns,
  data,
  currentPage,
  setCurrentPage,
  totalPages,
  totalRecords,
  message,
}) => {
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  console.log("this is columns in customtable", columns)
  console.log("this is data in customtable", data)

  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <Table bordered responsive className={styles.table}>
            <thead>
              <tr>
                {columns.map((column) => (
                  <th key={column} className={styles.tableHead}>
                    {column}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {totalRecords !== 0 ? (
                data.map((row, index) => (
                  <tr key={index}>
                    {columns.map((column) => (
                      <td key={column} className={styles.tableData}>
                        {row[column]}
                      </td>
                    ))}
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={columns.length} style={{ textAlign: "center" }}>
                    {message}.
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
        <div className={styles.recordspagination}>
          <div>Total Records : {totalRecords ? totalRecords : "0"}</div>
          <div>
            <TablePagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePageChange}
            />
          </div>
        </div>
      </div>

      <div className={styles.tablePaginationContainer}></div>
    </>
  );
};

export default CustomTable;
