import React, { useEffect, useState } from "react";
import { Common } from "../../../component/common/common";
import { useDispatch, useSelector } from "react-redux";
import { getRedemptionData } from "../../../features/redemptionSlice";
import { getStateData } from "../../../features/stateSlice";
import { getDistrictData } from "../../../features/districtSlice";
import Filters from "../../../component/common/Filters/Filters";
import { useNavigate } from "react-router-dom";
import { downloadRedemptionReports } from "../../../features/downloadRedemptionReportSlice";



function ViewRedemtion() {

  const msLoggedUserId = localStorage.getItem("userId");
  const msLoggedRoleId = localStorage.getItem("roleId");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { data, totalRecords, message } = useSelector(
    (state) => state.redemptionlist.data
  );

  const loading = useSelector((state) => state.redemptionlist.loading);
  const reportdownload_loading = useSelector((state) => state.downloadRedemptionReport.loading);

  const stateList = useSelector((state) => state.stateList.data);
  const districtList = useSelector((state) => state.districtList.data);
  //
  const [stateItemSelected, setStateItemSelected] = useState("");
  const [districtItemSelected, setDistrictItemSelected] = useState("");

  const [mobileNumber, setMobileNumber] = useState("");

  const [currentPage, setCurrentPage] = useState(1);
  const [startDate, setStartDate] = useState(() => {
    const today = new Date();
    today.setDate(today.getDate() - 1); // Subtract 1 day
    return today;
  });
  const [endDate, setEndDate] = useState("");
  const formattedStartDate = startDate
    ? startDate.toLocaleDateString("en-CA", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    })
    : "";
  const formattedEndDate = endDate
    ? endDate.toLocaleDateString("en-CA", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    })
    : "";

  const itemsPerPage = 10;
  const totalPages = Math.ceil(totalRecords / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  // const endIndex = startIndex + itemsPerPage;
  // console.log("Data>>>>", data);
  useEffect(() => {
    dispatch(
      getRedemptionData({
        formattedStartDate,
        formattedEndDate,
        mobileNumber,
        districtItemSelected,
        stateItemSelected,
        startIndex,
        msLoggedUserId,
        msLoggedRoleId,
      })
    );
  }, [dispatch, currentPage, msLoggedUserId, msLoggedRoleId]);
  useEffect(() => {
    dispatch(getStateData({ msLoggedUserId, msLoggedRoleId }));
  }, []);
  useEffect(() => {
    dispatch(
      getDistrictData({
        msLoggedUserId,
        msLoggedRoleId,
        selectedState: stateItemSelected,
      })
    );
  }, [stateItemSelected]);

  const handleSubmit = () => {
    dispatch(
      getRedemptionData({
        formattedStartDate,
        formattedEndDate,
        mobileNumber,
        districtItemSelected,
        stateItemSelected,
        startIndex,
        msLoggedUserId,
        msLoggedRoleId,
      })
    );
  };

  const handleClick = () => {
    navigate("/view-historic-redemption-report");
  };

  const handleStateChange = (e) => {
    setStateItemSelected(e.target.value);
  };
  const handleDistrictChange = (e) => {
    setDistrictItemSelected(e.target.value);
  };
  // table columns
  const columns = [
    "S.No.",
    "User Name",
    "User Code",
    "User Mobile Number",
    "User Pincode",
    "User District",
    "User State",
    "User Role",
    "User Creation Date",
    "Order Number",
    "Product Name",
    "Total Points Redemeed",
    "User Pan Availability",
    "Total Pts. excluding tax",
    "Tax ( TDS ) deducted",
    "Order Date",
    "Order Date & Time",
    "Month Year",
    "Order Status",
  ];
  // table data
  const tableData = data
    ? data.map((item, index) => {
      const formatDOB = (dob) => {
        const parts = dob.split("-");
        return `${parts[2]}-${parts[1]}-${parts[0]}`;
      };
      const formatDate = (date) => {
        if (!date) return "";

        const d = new Date(date);
        const year = d.getFullYear();
        const month = String(d.getMonth() + 1).padStart(2, "0");
        const day = String(d.getDate()).padStart(2, "0");

        return `${day}-${month}-${year}`;
      };
      return {
        "S.No.": (currentPage - 1) * 10 + index + 1,
        "User Name": item.name,
        "User Code": item.userCode,
        "User Mobile Number": item.mobileno,
        "User DOB": formatDOB(item.userDob),
        "User Email": item.userEmail,
        "User Pincode": item.userPincode,
        "User Address": item.userAddress,
        "User District": item.userDistrict,
        "User City": item.userCity,
        "User State": item.userState,
        "User Workshop": item.userWorkshop,
        "User Role": item.userRole,
        "User Creation Date": formatDate(item.userCreatedOn),
        "Th Name": item.thName,
        "Th Mobile Number": item.thMobile,
        "Th Email": item.thEmail,
        "Sh Name": item.shName,
        "Sh Mobile Number": item.shMobile,
        "Sh Email": item.shEmail,
        "Rh Name": item.rhName,
        "Rh Mobile Number": item.rhMobile,
        "Rh Email": item.rhEmail,
        "Order Number": item.orderNumber,
        "Product Name": item.productName,
        "Total Points Redemeed": item.totalPointsRedemeed,
        "User Pan Availability": item.pan_availability,
        // "Point Redemeed Ex Points": item.pointRedeemedExTax,
        "Total Pts. excluding tax": item.pointRedeemedExTax,
        "Tax ( TDS ) deducted": item.orderTaxAmount,
        "Order Date": formatDate(item.orderDate),
        "Order Date & Time": item.orderDate,
        "Month Year": item.monthYear,
        "Order Status": item.orderStatus,
      };
    })
    : [];

  const handleDownload = () => {
    dispatch(
      downloadRedemptionReports({
        formattedStartDate,
        formattedEndDate,
        mobileNumber,
        districtItemSelected,
        stateItemSelected,
        startIndex,
        msLoggedUserId,
        msLoggedRoleId,
      })
    )
      .then((response) => {
        const currentDate = new Date();
        const timestamp = currentDate.getTime();
        const formattedDate = currentDate.toISOString().slice(0, 10);
        const suggestedFileName = `view_redemption_report_${formattedDate}_${timestamp}.csv`;
        const blob = new Blob([response.payload], { type: "text/csv" });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = suggestedFileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((error) => {
        console.error("Download error:", error);
      });
  };
  return (
    <div className="container" style={{ minHeight: "81.8vh" }}>
      <div className="row">
        <div className="col-md-12 mt-5">
          <div className="row">
            <div className="col-sm-9">
              <h2 className="text-capitalize fs-5 fst-italic">
                View Redemption Report
              </h2>
            </div>
            {msLoggedRoleId === "1" ? (
              <div className="col-sm-3 d-md-flex justify-content-md-end">
                <button
                  className="btn btn-outline-danger text-capitalize"
                  style={{
                    "--bs-btn-padding-y": ".25rem",
                    "--bs-btn-padding-x": ".5rem",
                    "--bs-btn-font-size": ".75rem",
                  }}
                  onClick={handleClick}
                >
                  Historic redemption report
                </button>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="col-md-12">
          <Filters
            startDate={startDate}
            endDate={endDate}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            mobileNumber={mobileNumber}
            setMobileNumber={setMobileNumber}
            stateItemSelected={stateItemSelected}
            handleStateChange={handleStateChange}
            stateList={stateList}
            districtList={districtList}
            districtItemSelected={districtItemSelected}
            handleDistrictChange={handleDistrictChange}
            handleSubmit={handleSubmit}
            handleDownload={handleDownload}
            msLoggedRoleId={msLoggedRoleId}
          />
        </div>
        {loading ? (
          <div className="d-flex justify-content-center mt-4">
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        ) : (
          <div className="col-md-12 my-4">
            <Common.CustomTable
              data={tableData}
              columns={columns}
              itemsPerPage={itemsPerPage}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              totalPages={totalPages}
              totalRecords={totalRecords}
              message={message}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default ViewRedemtion;
