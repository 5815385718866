import React, { useState, useEffect } from "react";
import Common from "../../../component/common/common";
import './profiledetails.css';


const ProfileDetails = ({ userData, userTypeOptions, selectedUserType, handleUserTypeChange, handleUpdateClick }) => {

  const [selectedCommLang, setSelectedCommLang] = useState(userData.userCommLang ?? selectedUserType);

  console.log("selectedCommLanggggggg", selectedCommLang);

  const handleCommLangChange = (event) => {
    console.log("Event Target Value:", event.target.value);
    const selectedValue = event.target.value; // Extract the value from the event
    setSelectedCommLang(selectedValue); // Update local state
    handleUserTypeChange(selectedValue); // Notify parent about the change
  };

  useEffect(() => {
    if (userData.userCommLang) {
      setSelectedCommLang(userData.userCommLang);
    }
  }, [userData.userCommLang]);

  return (
    <div>
      <p>
        <strong>User Code:</strong> {userData.userCode}
      </p>
      <p>
        <strong>User Name:</strong> {userData.name}
      </p>
      <p>
        <strong>DOB:</strong> {userData.userDob}
      </p>
      <p>
        <strong>City:</strong> {userData.userCity}
      </p>
      <p>
        <strong>State:</strong> {userData.userState}
      </p>
      <p>
        <strong>District:</strong> {userData.userDistrict}
      </p>
      <p>
        <strong>Pincode:</strong> {userData.userPincode}
      </p>
      <p>
        <strong>Mobile Number:</strong> {userData.mobileno}
      </p>
      <p>
        <strong>User Type:</strong> {userData.userRole}
      </p>
      <p>
        <strong>Mobile App Language:</strong> {userData.userLangauage}
      </p>
      <div className="input-row">
        <label htmlFor="preferred-language" className="input-label">
          Preferred Communication Language:
        </label>
        <div className="dropdown-container">
          <Common.SelectInput
            id="preferred-language"
            options={userTypeOptions}
            value={selectedCommLang}
            onChange={handleCommLangChange}
            labelKey="lang_name"
            valueKey="langId"
          />
          <a
            href="#"
            className="update-link"
            onClick={handleUpdateClick}
          >
            Update
          </a>
        </div>
      </div>
      <p>
        <strong>User Workshop:</strong> {userData.userWorkshop}
      </p>
      <p>
        <strong>TH name:</strong> {userData.thName}
      </p>
      <p>
        <strong>TH Mobile no.:</strong> {userData.thMobile}
      </p>
      <p>
        <strong>SH name:</strong> {userData.shName}
      </p>
      <p>
        <strong>SH Mobile no.:</strong> {userData.shMobile}
      </p>
      <p>
        <strong>RH name:</strong> {userData.rhName}
      </p>
      <p>
        <strong>RH Mobile no.:</strong> {userData.rhMobile}
      </p>
    </div>
  );
};

export default ProfileDetails;
