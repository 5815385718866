import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import appConfig from "../AppConfig";

const initialState = {
  data: [],
  loading: false,
  error: "",
  message: "",
  status: "",
};


export const getmsCampaignValidInvalid = createAsyncThunk(
  "getmsCampaignValidInvalid",
  async (params, { rejectWithValue }) => {
    const urls = `${appConfig.apiUrl}msCampUserValidInvalid`;
    const headers = {
      "X-API-Key": appConfig.apiKey,
    };
    const response = await axios.get(urls, {
      params: params,
      headers: headers,
      responseType: "arraybuffer",
    });
    try {
      const result = await response.data;
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);


export const msCampaignValidInvalidDetails = createSlice({
  name: "msCampaignValidInvalidDetails",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getmsCampaignValidInvalid.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getmsCampaignValidInvalid.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.error = "";
    });
    builder.addCase(getmsCampaignValidInvalid.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = action.error.message;
    });
  },
});

export default msCampaignValidInvalidDetails.reducer;
