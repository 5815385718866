// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FileInput_label__-J3G2 {
  /* color: #fff; */
  font-size: 16px;
  display: inline-block;
  margin-bottom: 8px;
}
.FileInput_fileInput__rsQoU {
  width: 100%;
  border-radius: 6px;
  border: 1px solid #5d5d5d;
  padding: 8px 0px 8px 8px;
}
`, "",{"version":3,"sources":["webpack://./src/component/common/FileInput/FileInput.module.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,eAAe;EACf,qBAAqB;EACrB,kBAAkB;AACpB;AACA;EACE,WAAW;EACX,kBAAkB;EAClB,yBAAyB;EACzB,wBAAwB;AAC1B","sourcesContent":[".label {\n  /* color: #fff; */\n  font-size: 16px;\n  display: inline-block;\n  margin-bottom: 8px;\n}\n.fileInput {\n  width: 100%;\n  border-radius: 6px;\n  border: 1px solid #5d5d5d;\n  padding: 8px 0px 8px 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"label": `FileInput_label__-J3G2`,
	"fileInput": `FileInput_fileInput__rsQoU`
};
export default ___CSS_LOADER_EXPORT___;
