import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import appConfig from "../AppConfig";

const initialState = {
  loading: false,
  error: "",
};

export const downloadFile = createAsyncThunk(
  "download/file",
  async ({ url, msLoggedUserId, msLoggedRoleId, token }, { rejectWithValue }) => {
    try {
      console.log("this is token",token)
      const fullUrl = `${appConfig.apiUrl}${url}?msLoggedUserId=${msLoggedUserId}&msLoggedRoleId=${msLoggedRoleId}&token=${token}`;
      const headers = {
        "X-API-Key": appConfig.apiKey,
      };
      const response = await axios.get(fullUrl, {
        headers: headers,
        responseType: "blob",
      });

      // Creating a downloadable link
      const blob = new Blob([response.data], { type: response.headers["content-type"] });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.setAttribute("download", "report.csv"); // Explicitly setting .csv      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      console.log("download successful")
      return "Download successful";
    } catch (error) {
      return rejectWithValue(error.message || "Download failed");
    }
  }
);

const downloadSlice = createSlice({
  name: "download",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(downloadFile.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(downloadFile.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(downloadFile.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export default downloadSlice.reducer;
