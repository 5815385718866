import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { Common } from "../../../component/common/common";
import { useDispatch, useSelector } from "react-redux";
import { getMsSalesUniqueId } from "../../../features/msSalesUniqueCodeSlice";
import { bulkUploadSalesMember } from "../../../features/bulkUploadSalesSlice";
import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap CSS

function BulkUploadSalesMember() {
  const dispatch = useDispatch();
  const msLoggedUserId = localStorage.getItem("userId");
  const uniqueCodeId = useSelector((state) => state.msSalesUniqueIdList.data);

  const data = useSelector((state) => state.bulkUploadSalesMemberList.data[0]);
  const message = useSelector(
    (state) => state.bulkUploadSalesMemberList.message
  );
  const status = useSelector((state) => state.bulkUploadSalesMemberList.status);
  const validCount = data && data.validCount;
  const inValidCount = data && data.invalidCount;

  const navigate = useNavigate();
  const [selectedFile, setSelectedFile] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false); // State for loading indicator

  const fileInputRef = useRef(null);

  useEffect(() => {
    dispatch(getMsSalesUniqueId());
  }, [dispatch]);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const handleSubmit = async () => {
    setLoading(true); // Start loading indicator
    const userFormData = new FormData();
    userFormData.append("uniqueId", uniqueCodeId);
    userFormData.append("salesfile", selectedFile);
    userFormData.append("uploadedBy", msLoggedUserId);
    dispatch(bulkUploadSalesMember(userFormData));
    setSubmitted(true);
  };

  useEffect(() => {
    if (submitted && status !== undefined && message) {
      setLoading(false); // Stop loading indicator
      if (status) {
        Swal.fire({
          title: message,
          icon: "success",
          confirmButtonText: "Okay",
        });
        setTimeout(() => {
          navigate(
            `/msSalesMemberValidInvalid?Id=${uniqueCodeId}&valid=${validCount}&invalid=${inValidCount}`
          );
        }, 2000);
      } else {
        Swal.fire({
          title: message,
          icon: "error",
          confirmButtonText: "Okay",
        });
      }
      setSubmitted(false);
    }
  }, [message, status, submitted]);

  return (
    <div className="container mt-5" style={{ minHeight: "81.8vh" }}>
      <div className="row">
        <div className="col-md-12">
          <h3 className="text-capitalize fs-5 fst-italic">
            Upload Bulk Sales Members
          </h3>
        </div>
        <div className="col-md-6">
          <div className="row">
            <div className="col-sm-12 mt-3">
              <Common.FileInput
                label="File Upload"
                onChange={handleFileChange}
                inputRef={fileInputRef}
              />
            </div>
            <div className="col-sm-12 mt-3">
              <a
                href={
                  "https://motulstage.firsthive.com/uploads/samples/Sample_SalesMember_Bulk.xlsx"
                }
                className="text-dark"
              >
                Sample File
              </a>
            </div>
            <div className="col-sm-12 mt-3">
              <button
                onClick={handleSubmit}
                className={
                  selectedFile ? "btn btn-danger" : "btn btn-secondary"
                }
                disabled={selectedFile ? false : true || loading}
              >
                Submit
                {loading && (
                  <span className="spinner-border spinner-border-sm ms-2" role="status" aria-hidden="true"></span>
                )}
              </button>
            </div>
            <div className="col-sm-12 mt-3 mb-3">
              <p className="text-danger">
                Note: Please upload less than 5MB File
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BulkUploadSalesMember;
