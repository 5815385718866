// Import necessary modules and components
import React, { useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import Login from "./screens/auth/Login";
import Dashboard from "./screens/Dashboard/Dashboard";
import Settings from "./screens/Dashboard/Settings";
import NotFound from "./screens/Dashboard/NotFound";
import Coupons from "./screens/couponCode/CodeGeneration/Coupons";
import MemberList from "./screens/reports/MemberList/MemberList";
import UKC from "./screens/utility/UploadKnowledgeCorner/Ukc";
import ViewCoupons from "./screens/couponCode/CodeGenerationReport/CodeGenerationReport";
import ViewKnowledeCorner from "./screens/utility/ViewKnowledgeCorner/ViewKnowledgeCorner";
import UpdateUser from "./screens/reports/MemberList/EditMember/updateUser";
import VerifyCode from "./screens/utility/VeriFyCode/VerifyCode";
import AddSalesMember from "./screens/utility/AddSalesTeam/AddSalesMember";
import Header from "./screens/common/Header/Header";
import USM from "./screens/utility/UploadSalesMapping/uploadSalesMapping";
import Mapped from "./screens/Dashboard/mappedData";
import UploadMember from "./screens/utility/UploadMembers/UploadMembers";
import ViewSalesMapping from "./screens/reports/HierarchyReport/ViewSalesMapping";
import MemberMapped from "./screens/Dashboard/memberValidInvalid";
import Footer from "./screens/common/Footer/Footer";
import styles from "./App.module.css";
import CommonUI from "./screens/common/CommonUI/CommonUI";
import ViewCouponsReport from "./screens/couponCode/CodeGenerationReport/ViewCouponReport";
import ViewRedemtion from "./screens/reports/RedemptionReport/ViewRedemption";
import ViewTransaction from "./screens/reports/TransactionReport/ViewTransaction";
import ViewSalesTeam from "./screens/reports/ViewSalesTeam/ViewSalesTeam";
import HistoricTransactionReport from "./screens/reports/TransactionReport/HistoricTransactionReport/HistoricTransactionReport";
import HistoricRedemptionReport from "./screens/reports/RedemptionReport/HistoricRedemptionReport/HistoricRedemptionReport";
import BulkUploadSalesMember from "./screens/utility/BulkUploadSalesMember/BulkUploadSalesMember";
import SalesMemberValidInvalid from "./screens/utility/BulkUploadSalesMember/SalesValidInvalidData/SalesValidInvalidData";
import SessionLogout from "./screens/auth/SessonLogout";
import ProcessingData from "./screens/couponCode/ProcessingData/ProcessingData";
import TrasactionDataProcessing from './screens/reports/TransactionReport/DataProcessing'
import OneView from "./screens/oneView/OneView";
import TransactionReportTable from "./screens/reports/TransactionReport/Table";
import '../src/assets/css/desktop.css';
function App() {
  const location = useLocation();

  useEffect(() => {
    SessionLogout();
  }, []);

  return (
    <>
      <div className={styles.appContainer}>
        <div className={location.pathname === "/" ? "" : styles.header}>
          {location.pathname === "/" ? "" : <Header />}
        </div>
        <div className={styles.main}>
          <CommonUI>
            <Routes>
              <Route path="/" element={<Login />} />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/settings" element={<Settings />} />
              <Route
                path="/upload-sales-members"
                element={<BulkUploadSalesMember />}
              />
              <Route
                path="/msSalesMemberValidInvalid"
                element={<SalesMemberValidInvalid />}
              />

              <Route path="/upload-members" element={<UploadMember />} />
              <Route path="/mapped" element={<Mapped />} />
              <Route path="/download-section" element={<TransactionReportTable />} />
              <Route
                path="/view-transaction-report"
                element={<ViewTransaction />}
              />
              <Route
                path="/view-historic-transaction-report"
                element={<HistoricTransactionReport />}
              />
              <Route
                path="/view-redemption-report"
                element={<ViewRedemtion />}
              />
              <Route
                path="/view-historic-redemption-report"
                element={<HistoricRedemptionReport />}
              />
              <Route
                path="/view-coupons-report"
                element={<ViewCouponsReport />}
              />
              <Route path="/member-mapped" element={<MemberMapped />} />
              <Route path="/generate-coupons" element={<Coupons />} />
              <Route path="/view-coupons" element={<ViewCoupons />} />
              <Route path="/data-processing" element={<ProcessingData />} />
              <Route path="/transaction-data-processing" element={<TrasactionDataProcessing />} />
              <Route path="/member-list" element={<MemberList />} />
              <Route path="/view-sales-member" element={<ViewSalesTeam />} />
              <Route
                path="/view-sales-mapping"
                element={<ViewSalesMapping />}
              />
              <Route path="/upload-knowledge-corner" element={<UKC />} />
              <Route path="/upload-sales-mapping" element={<USM />} />
              <Route
                path="/view-knowledge-corner"
                element={<ViewKnowledeCorner />}
              />
              <Route path="/add-sales-member" element={<AddSalesMember />} />
              <Route path="/verify-code" element={<VerifyCode />} />
              <Route path="/member/:id" element={<UpdateUser />} />
              <Route path="*" element={<NotFound />} />
              <Route path="/one-view" element={<OneView />} />
            </Routes>
          </CommonUI>
        </div>
        <div className={location.pathname === "/" ? "mt-0" : "mt-4"}>
          <Footer />
        </div>
      </div>
    </>
  );
}

export default App;
