import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchCartonTransactionReport } from "../features/CartonTransactionSlice";
import Common from "./common/common";
import { getStateData } from "../features/stateSlice";
import { getDistrictData } from "../features/districtSlice";
import Filters from "./common/Filters/Filters";
import { downloadFile } from "../features/DownloadFileSlice";
import { getSubCatDropDownData } from "../features/SubCatDropDownSlice";
import { Audio, Hourglass } from "react-loader-spinner";


export default function CartonTransactionReport() {
  const dispatch = useDispatch();
  const msLoggedUserId = localStorage.getItem("userId");
  const msLoggedRoleId = localStorage.getItem("roleId");
  const token = localStorage.getItem("logintoken");

  //   const cartonTransactionReport = useSelector(
  //     (state) => state.cartonTransactionReport
  //   );
  const { data, totalRecords, loading } = useSelector(
    (state) => state.cartonTransactionReport.reports
  );


  console.log("this is my cartonTransactionReport", data);
  const stateList = useSelector((state) => state.stateList.data);
  const districtList = useSelector((state) => state.districtList.data);
  const productSubcat = useSelector((state) => state.TierSubcategoryList.data);
  const SubCatDropDownList = useSelector((state) => state.SubCatDropDown.data);

  const subcategory_options = SubCatDropDownList.map((item, index) => ({
    value: item.subCategory,
    label: item.subCategory,
  }));

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  // const totalRecords = modifiedReportData ? modifiedReportData.length : "";
  const totalPages = Math.ceil(totalRecords / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  // Initialize filters state
  const [filters, setFilters] = useState({
    stateItemSelected: "",
    districtItemSelected: "",
    startDate: "",
    endDate: "",
    mobileNumber: "",
    subCategory: "",
  });

  const fetchStateData = () => {
    dispatch(getStateData({ msLoggedRoleId, msLoggedUserId }));
  };
  const fetchDistrictData = (stateId) => {
    dispatch(
      getDistrictData({
        selectedStates: filters.stateItemSelected,
        msLoggedUserId,
        msLoggedRoleId,
      })
    );
  };
  const fetchReportData = () => {
    dispatch(
      fetchCartonTransactionReport({
        msLoggedUserId: 1,
        msLoggedRoleId: 2,
        startDate: "2022-06-20",
        endDate: "2022-06-20",
        stateItemSelected: "",
        districtItemSelected: "",
        startDate: "",
        endDate: "",
        mobileNumber: "",
        subCategory: "",
        startIndex: startIndex
      })
    );
  };

  useEffect(() => {
    dispatch(
      fetchCartonTransactionReport({
        msLoggedUserId: 1,
        msLoggedRoleId: 2,
        startDate: "2022-06-20",
        endDate: "2022-06-20",
        stateItemSelected: "",
        districtItemSelected: "",
        startDate: "",
        endDate: "",
        mobileNumber: "",
        subCategory: "",
        startIndex: startIndex
      })
    );
  }, [dispatch, currentPage]);


  console.log("Carton Transaction Report Data:", data);


  // Pagination state

  

  const modifiedReportData = data
    ? data.map((item, index) => ({
      "S.No.": (currentPage - 1) * itemsPerPage + index + 1, 
      "User Name": item.name,
      "User Code": item.userCode,
      "User Mobile Number": item.mobileno,
      "User Pincode": item.userPincode,
      "User District": item.userDistrict,
      "User State": item.userState,
      "User Role": item.userRole,
      "User Workshop": item.userWorkshop,
      "Th Name": item.thName,
      "Th Mobile Number": item.thMobile,
      "Sh Name": item.shName,
      "Sh Mobile Number": item.shMobile,
      "Rh Name": item.rhName,
      "Rh Mobile Number": item.rhMobile,
      "Group Name": item.groupName,
      "Sub Category": item.subCategory,
      "Brand Name": item.brand,
      "Coupon Code": item.couponCode,
      "Coupon Points": item.couponPoints,
      "Earned Source": item.earnedSource,
      "Check In Date & Time": item.checkInDate,
      "Transaction Type": item.transactionType,
      "Scheme Name": item.schemeName,
    }))
    : [];



  const displayedData = modifiedReportData.slice(
    startIndex, startIndex + itemsPerPage);

  const headers = [
    "User Name",
    "User Code",
    "User Mobile Number",
    "User Pincode",
    "User District",
    "User State",
    "User Role",
    "User Workshop",
    "Th Name",
    "Th Mobile Number",
    "Sh Name",
    "Sh Mobile Number",
    "Rh Name",
    "Rh Mobile Number",
    "Group Name",
    "Sub Category",
    "Brand Name",
    "Coupon Code",
    "Coupon Points",
    "Earned Source",
    "Check In Date & Time",
    "Transaction Type",
    "Scheme Name",
  ];

  const handleSubmit = () => {
    console.log("submitting");
    dispatch(
      //dispatching after applying filters
      fetchCartonTransactionReport({
        msLoggedUserId,
        msLoggedRoleId,
        token,
        state: filters.stateItemSelected,
        district: filters.districtItemSelected,
        startDate: filters.startDate,
        endDate: filters.endDate,
        mobileNumber: filters.mobileNumber,
        subCategory: filters.subCategory,
        startIndex: startIndex
      })
    );
  };
  console.log("this is filters", filters);
  const handleDownload = () => {
    dispatch(
      downloadFile({
        url: "msCartonTransactionReportDownload", //this URL is hardcoded for now
        msLoggedUserId,
        msLoggedRoleId,
        token,
      })
    );
  };

  useEffect(() => {
    fetchReportData();
    fetchStateData();
    dispatch(getSubCatDropDownData({ msLoggedUserId, msLoggedRoleId }));
  }, []);
  useEffect(() => {
    if (filters.stateItemSelected) {
      fetchDistrictData();
    }
  }, [filters.stateItemSelected]);

  useEffect(() => {
    if (filters.stateItemSelected) {
      fetchDistrictData(filters);
    }
  }, [filters.stateItemSelected, dispatch]);

  // Handle filter changes without triggering API calls
  const handleFilterChange = (name, e) => {
    //in case of date inputs, e will directly give value of dates, while in select inputs, e gives event

    console.log("this is name and e", name, e);

    // console.log("this is name and value", name, e.target.value)

    if (name === "stateItemSelected") {
      setFilters((prev) => ({
        ...prev,
        stateItemSelected: e.target.value,
        districtItemSelected: "", // Reset district when state changes
      }));
    } else if (name === "districtItemSelected") {
      setFilters((prev) => ({
        ...prev,
        districtItemSelected: e.target.value,
      }));
    } else if (name === "subCategory") {
      setFilters((prev) => ({
        ...prev,
        subCategory: e.target.value,
      }));
    } else if (name === "mobileNumber") {
      console.log("this is mobileNumber", e.target.value);
      setFilters((prev) => ({
        ...prev,
        mobileNumber: e.target.value,
      }));
    } else {
      setFilters((prev) => {
        const updatedFilters = { ...prev, [name]: e };

        // Ensure startDate is not greater than endDate
        if (
          name === "startDate" &&
          updatedFilters.endDate &&
          updatedFilters.startDate > updatedFilters.endDate
        ) {
          updatedFilters.endDate = ""; // Set startDate to endDate if startDate is greater
        }

        // Ensure endDate is not before startDate
        if (
          name === "endDate" &&
          updatedFilters.startDate &&
          updatedFilters.endDate < updatedFilters.startDate
        ) {
          updatedFilters.startDate = ""; // Set endDate to startDate if endDate is earlier
        }

        return updatedFilters;
      });
    }

    // setCurrentPage(1); // Reset to first page when filters change
  };

  return (
    <div className="container" style={{ minHeight: "81.8vh" }}>
      {loading ? (
        <div className="hourglass">
          <Hourglass
            visible={true}
            height="80"
            width="80"
            ariaLabel="hourglass-loading"
            wrapperStyle={{}}
            wrapperClass=""
            colors={["rgb(156 49 79)", "#ff9595"]}
          />
        </div>
      ) : (
        <>
          <h2 className="text-capitalize fs-5 fst-italic mt-5">
            Carton Transaction Report
          </h2>
          <div className=" my-4">
            <Filters
              startDate={filters.startDate}
              endDate={filters.endDate}
              setStartDate={(value) => handleFilterChange("startDate", value)}
              setEndDate={(value) => handleFilterChange("endDate", value)}
              stateItemSelected={filters.stateItemSelected}
              handleStateChange={(value) =>
                handleFilterChange("stateItemSelected", value)
              }
              districtItemSelected={filters.districtItemSelected}
              handleDistrictChange={(value) =>
                handleFilterChange("districtItemSelected", value)
              }
              stateList={stateList}
              districtList={districtList}
              handleSubmit={handleSubmit}
              msLoggedRoleId={msLoggedRoleId}
              handleDownload={handleDownload}
              filters={filters}
              showSubcategory={true}
              subcatOptions={subcategory_options}
              handleSubcatChange={(value) => {
                handleFilterChange("subCategory", value);
              }}
              showMobileNumber={true}
              handleMobileNumberChange={(value) => {
                handleFilterChange("mobileNumber", value);
              }}
            />
          </div>
          <Common.CustomTable
            data={modifiedReportData}
            columns={headers}
            itemsPerPage={itemsPerPage}
            totalPages={totalPages}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            totalRecords={totalRecords}
          />
        </>
      )}
    </div>
  );
}
