import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { Common } from "../../../../component/common/common";
import { useDispatch, useSelector } from "react-redux";
import { getSingleUser } from "../../../../features/singleUserSlice";
import { updateSingleUserRecord } from "../../../../features/updateSingleUserSlice";

function UpdateUser() {
  const msLoggedUserId = localStorage.getItem("userId");
  const msLoggedRoleId = localStorage.getItem("roleId");
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.singleUserList.data);
  const message = useSelector((state) => state.updateSingleUserList.message);
  const status = useSelector((state) => state.updateSingleUserList.status);
  // console.log(message, status);

  const navigate = useNavigate();
  const { id } = useParams();
  const [formData, setFormData] = useState({
    name: "",
    userWorkshop: "",
    userEmail: "",
  });
  const [userDob, setUserDob] = useState(null);
  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    dispatch(getSingleUser({ id, msLoggedUserId, msLoggedRoleId }));
  }, [dispatch, id]);

  useEffect(() => {
    if (data) {
      setFormData({
        name: (data.name && data.name) || "",
        userWorkshop: (data.userWorkshop && data.userWorkshop) || "",
        userEmail: (data.userEmail && data.userEmail) || "",
      });
      // Parse the userDob from data and set it
      if (data.userDob) {
        const dob = new Date(data.userDob);
        setUserDob(dob);
      }
    }
  }, [data]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleUpdateRecords = async () => {
    await dispatch(
      updateSingleUserRecord({
        userId: id,
        msLoggedUserId: msLoggedUserId,
        name: formData.name,
        userDob: userDob,
        userWorkshop: formData.userWorkshop,
        userEmail: formData.userEmail,
      })
    );
    setSubmitted(true);
  };
  useEffect(() => {
    if (submitted && status !== undefined && message) {
      if (status) {
        Swal.fire({
          title: message,
          icon: "success",
          confirmButtonText: "Okay",
        });
        setTimeout(() => {
          navigate("/member-list");
        }, 2000);
      } else {
        Swal.fire({
          title: message,
          icon: "error",
          confirmButtonText: "Okay",
        });
      }
      setSubmitted(false);
    }
  }, [message, status, submitted]);

  return (
    <>
      <div className="container" style={{ minHeight: "81.8vh" }}>
        <div className="row">
          <div className="col-md-12 mt-5">
            <h2 className="text-capitalize fs-5 fst-italic">
              Edit Member Details
            </h2>
          </div>
          <div className="col-md-4 mt-3">
            <div className="row">
              <div className="col-sm-12">
                <Common.TextInput
                  label={"Full Name"}
                  type={"text"}
                  placeholder={"Enter Full Name"}
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                />
              </div>
              <div className="col-sm-12 mt-2">
                <Common.TextInput
                  label={"Workshop Name"}
                  type={"text"}
                  placeholder={"Enter Workshop Name"}
                  name="userWorkshop"
                  value={formData.userWorkshop}
                  onChange={handleChange}
                />
              </div>
              <div className="col-sm-12 mt-2">
                <Common.TextInput
                  label={"Email Address"}
                  type={"email"}
                  placeholder={"Enter Email Address"}
                  name="userEmail"
                  value={formData.userEmail}
                  onChange={handleChange}
                />
              </div>
              <div className="col-sm-12 mt-2">
                <Common.CustomDatePicker
                  label={"Date Of Birth"}
                  dateFormat={"dd/MM/yyyy"}
                  selected={userDob}
                  onChange={(date) => setUserDob(date)}
                  placeholderText="dd/yy/yyyy"
                  datePickerWidth={"420px"}
                />
              </div>
              <div className="col-sm-12 mt-4 mb-4">
                <button
                  onClick={handleUpdateRecords}
                  className="btn btn-danger"
                >
                  Update
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default UpdateUser;
