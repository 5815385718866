import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AiOutlineEyeInvisible, AiOutlineEye } from "react-icons/ai";
import MotulLogo from "../../assets/svg/motulLogoSvg.svg";
import styles from "./Login.module.css";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import { getClientIP } from "../../features/clientIpSlice";
import { loginHistoryInfo } from "../../features/loginHistorySlice";
import platform from "platform";
import axios from "axios";
import appConfig from "../../AppConfig";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const clientIp = useSelector((state) => state.clientInfoIp.data.ip);
  const info = platform.parse(window.navigator.userAgent);
  const osInfo = info.os.family + info.os.version;
  const browserInfo = info.name + info.version;
  const [passwordShown, setPasswordShown] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const togglePasswordVisibility = () => {
    setPasswordShown(!passwordShown);
  };

  const handleSubmit = async (e) => {
    
    const urls = `${appConfig.apiUrl}/msLogin`;
    e.preventDefault();
    const data = {
      userName: formData.email,
      passWord: formData.password,
    };
    await axios
      .post(urls, data, {
        headers: {
          "X-API-Key": appConfig.apiKey,
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
      .then((res) => {
        if (res.data.status === true) {
          localStorage.setItem("roleId", res.data.data.roleId);
          localStorage.setItem("userId", res.data.data.userId);
          localStorage.setItem("role", res.data.data.role);
          localStorage.setItem("status", res.data.status);
          localStorage.setItem("userName", res.data.data.name);
          localStorage.setItem("email", res.data.data.emailId);
          localStorage.setItem("mobile", res.data.data.mobileNo);
          localStorage.setItem("logintoken", res.data.data.token);

         
          dispatch(
            loginHistoryInfo({
              userId: localStorage.getItem("userId"),
              log_ip: clientIp,
              log_os: osInfo,
              log_browser: browserInfo,
              log_source: "Microsite",
            })
          );
          Swal.fire({
            title: res.data.message,
            icon: "success",
            confirmButtonText: "Okay",
            timer: 1000,
          });
          navigate("/dashboard");
        }
        if (res.data.status === false) {
          Swal.fire({
            title: res.data.message,
            icon: "error",
            confirmButtonText: "Okay",
            timer: 1000,
          });
        }
      })
      .catch((err) => {
        console.log("Error: ", err);
      });
  };

  

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  useEffect(() => {
    dispatch(getClientIP());
  }, [dispatch]);

  useEffect(() => {
    const roleId = localStorage.getItem("roleId");
    const userId = localStorage.getItem("userId");
    const status = localStorage.getItem("status");
    const role = localStorage.getItem("role");
    if (status && roleId && userId && role) {
      navigate("/dashboard");
    }
  }, [navigate]);

  return (
    <>
      <div
        className="container-fluid pb-4"
        style={{ minHeight: "89.2vh" }}
        id={styles.loginContainer}
      >
        <div className="row">
          <div className="col-md-12">
            <div className="row justify-content-center align-items-center">
              <div
                className="col-sm-8 shadow-sm p-3 rounded"
                style={{ backgroundColor: "#ed1d24" }}
              >
                <div className="row">
                  <div className="col-sm-12">
                    <img src={MotulLogo} alt="" className="w-50" />
                  </div>
                  <div className="col-sm-12">
                    <form
                      action=""
                      method="post"
                      autoComplete="off"
                      onSubmit={handleSubmit}
                    >
                      <div className="mb-3">
                        <label
                          htmlFor="username"
                          className="form-label text-light"
                        >
                          Email Address
                        </label>
                        <input
                          className={styles.inputText}
                          placeholder="name@example.com"
                          autoComplete="off"
                          type="email"
                          name="email"
                          value={formData.email}
                          onChange={handleChange}
                          required
                        />
                      </div>
                      <div className="mb-3">
                        <label
                          htmlFor="username"
                          className="form-label text-light"
                        >
                          Password
                        </label>
                        <div className={styles.passWordContainer}>
                          <input
                            className={styles.inputText}
                            type={passwordShown ? "text" : "password"}
                            placeholder="******"
                            autoComplete="new-password"
                            name="password"
                            value={formData.password}
                            onChange={handleChange}
                            required
                          />
                          <span
                            className={styles.passwordIcon}
                            onClick={togglePasswordVisibility}
                          >
                            {passwordShown ? (
                              <AiOutlineEye className={styles.eyeviewer} />
                            ) : (
                              <AiOutlineEyeInvisible
                                className={styles.eyeviewer}
                              />
                            )}
                          </span>
                        </div>
                      </div>
                      <div className="mb-3">
                        <button
                          type="submit"
                          className="btn btn-light w-100 fw-bold"
                        >
                          Login
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Login;
