import React, { useEffect, useState } from "react";
import { Common } from "../../../component/common/common";
import { useDispatch, useSelector } from "react-redux";
import { getCouponReportsData } from "../../../features/couponReportSlice";
import Button from "../../../component/Button/Button";
import { downloadCouponReports } from "../../../features/couponReportDownloadSlice";
function ViewCouponsReport() {
  const msLoggedUserId = localStorage.getItem("userId");
  const msLoggedRoleId = localStorage.getItem("roleId");
  const dispatch = useDispatch();
  const { data, totalRecords } = useSelector(
    (state) => state.couponReport.data
  );
  const loading = useSelector((state) => state.couponReport.loading);
  const params = new URLSearchParams(window.location.search);
  const codeId = params.get("codeId");
  const [currentPage, setCurrentPage] = useState(1);

  // table columns
  const columns = [
    "S.No.",
    "Coupon Code",
    "Group",
    "Brand",
    "Sub Category",
    "Product Name",
    "Pack Size",
    "Viscosity",
    "Points",
    "Active",
    "Created Date & Time",
    "Expiry Date",
  ];
  // table data
  const tableData = data
    ? data.map((item, index) => {
        const formatExp = (expDate) => {
          const parts = expDate.split("-");
          return `${parts[2]}-${parts[1]}-${parts[0]}`;
        };
        const formatDate = (date) => {
          if (!date) return "";

          const d = new Date(date);
          const year = d.getFullYear();
          const month = String(d.getMonth() + 1).padStart(2, "0");
          const day = String(d.getDate()).padStart(2, "0");
          let hours = d.getHours();
          const minutes = String(d.getMinutes()).padStart(2, "0");
          const seconds = String(d.getSeconds()).padStart(2, "0");
          const ampm = hours >= 12 ? "PM" : "AM";
          hours = hours % 12;
          hours = hours ? hours : 12; // 0 should be converted to 12

          return `${day}-${month}-${year} ${hours}:${minutes}:${seconds} ${ampm}`;
        };
        return {
          "S.No.": (currentPage - 1) * 10 + index + 1,
          "Coupon Code": item.coupon_code,
          Group: item.group_name,
          Brand: item.brand_name,
          "Sub Category": item.subCategory,
          "Product Name": item.product_name,
          "Pack Size": item.pack_size,
          Viscosity: item.viscosity,
          Points: item.points,
          Active: item.is_active,
          "Created Date & Time": formatDate(item.created_on),
          "Expiry Date": formatExp(item.exp_date),
        };
      })
    : [];

  const itemsPerPage = 10;
  const totalPages = Math.ceil(totalRecords / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  // const endIndex = startIndex + itemsPerPage;

  useEffect(() => {
    dispatch(
      getCouponReportsData({
        codeId,
        startIndex,
        msLoggedUserId,
        msLoggedRoleId,
      })
    );
  }, [
    dispatch,
    currentPage,
    codeId,
    startIndex,
    msLoggedUserId,
    msLoggedRoleId,
  ]);

  const handleDownload = () => {
    dispatch(
      downloadCouponReports({ codeId, msLoggedUserId, msLoggedRoleId })
      // downloadCouponReports({ codeId, msLoggedUserId, msLoggedRoleId })
    )
      .then((response) => {
        const currentDate = new Date();
        const timestamp = currentDate.getTime();
        const formattedDate = currentDate.toISOString().slice(0, 10);
        const suggestedFileName = `coupons_report_${formattedDate}_${timestamp}.csv`;
        const blob = new Blob([response.payload], { type: "text/csv" });

        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = suggestedFileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((error) => {
        console.error("Download error:", error);
      });
  };

  return (
    <div className="container" style={{ minHeight: "81.8vh" }}>
      <div className="row mt-3">
        <div className="col-12">
          <div className="row align-items-center">
            <div className="col-sm-9 mt-5">
              <h2 className="text-capitalize fs-5 fst-italic">
                Coupons Report
              </h2>
            </div>
            {msLoggedRoleId === "1" || msLoggedRoleId === "3" ? (
              <div className="col-sm-3 mt-md-4 d-md-flex justify-content-md-end">
                <Button variant={"download"} onClick={handleDownload}>
                  Download
                </Button>
              </div>
            ) : null}
          </div>
        </div>
        {loading ? (
          <div className="d-flex justify-content-center">
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        ) : (
          <div className="col-12 mt-3">
            <Common.CustomTable
              columns={columns}
              data={tableData}
              itemsPerPage={itemsPerPage}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              totalPages={totalPages}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default ViewCouponsReport;
