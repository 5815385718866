// FileInput.js

import React from "react";
import styles from "./FileInput.module.css";
const FileInput = ({ label, onChange, accept, name, fileInputRef }) => {
  return (
    <div className={styles.fileInputContainer}>
      <label className={styles.label}>{label}</label>
      <input
        type="file"
        onChange={onChange}
        accept={accept}
        className={styles.fileInput}
        name={name}
        ref={fileInputRef}
      />
    </div>
  );
};

export default FileInput;
