import config from "./config.json";

const getEnvironment = () => {
  switch (process.env.REACT_APP_ENV) {
    case "development":
      return "development";
    case "production":
      return "production";
    default:
      return "localhost";
  }
};

const environment = getEnvironment();
const appConfig = config[environment];

export default appConfig;
