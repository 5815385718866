import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import appConfig from '../AppConfig';

// Async thunk to fetch bonus point report
export const fetchBonusPointReport = createAsyncThunk(
  'bonusPointReport/fetchBonusPointReport',
  async ({ msLoggedUserId, msLoggedRoleId, startDate, endDate, token, state, district, mobileNumber,startIndex }, { rejectWithValue }) => {
     // If startDate and endDate are native Date objects
     const fromDate = startDate ? new Date(startDate).toISOString().split('T')[0] : '';
     const toDate = endDate ? new Date(endDate).toISOString().split('T')[0] : '';
     
    const url = `${appConfig.apiUrl}msViewTierUserBonusPointReport?token=${token}&msLoggedUserId=${msLoggedUserId}&msLoggedRoleId=${msLoggedRoleId}&fromDate=${fromDate}&toDate=${toDate}&state=${state}&district=${district}&userMobile=${mobileNumber}&start=${startIndex}&length=10`;
    const headers = { "X-API-Key": appConfig.apiKey };
    try {
      const response = await axios.get(url, { headers });
      console.log("Bonus Point Report API Response:", response.data);
      return response.data; // Return only data array
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error.message);
    }
  }
);

// Slice to handle bonus point report state
const bonusPointReportSlice = createSlice({
  name: 'bonusPointReport',
  initialState: {
    reports: [],
    loading: false,
    error: null
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchBonusPointReport.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchBonusPointReport.fulfilled, (state, action) => {
        console.log("Bonus Point Report State Update:", action.payload);
        state.loading = false;
        state.reports = action.payload || [];
      })
      .addCase(fetchBonusPointReport.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  }
});

export default bonusPointReportSlice.reducer;