import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import appConfig from "../AppConfig";


const initialState = {
  loading: false,
  data: [],
  error: "",
};


export const getviewSalesTeamData = createAsyncThunk(
  "getviewSalesTeamData",
  async (
    { startIndex, msLoggedUserId, msLoggedRoleId },
    { rejectWithValue }
  ) => {
    const urls = `${appConfig.apiUrl}msViewSalesTeam?msLoggedUserId=${msLoggedUserId}&msLoggedRoleId=${msLoggedRoleId}&fromDate&toDate&district&state&mobile&start=${startIndex}&length=10`;
    const headers = {
      "X-API-Key": appConfig.apiKey,
    };
    const response = await axios.get(urls, { headers: headers });
    try {
      const result = response.data;
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);


export const viewSalesTeamDetails = createSlice({
  name: "viewSalesTeamDetails",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getviewSalesTeamData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getviewSalesTeamData.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.error = "";
    });
    builder.addCase(getviewSalesTeamData.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = action.error.message;
    });
  },
});

export default viewSalesTeamDetails.reducer;
