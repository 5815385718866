import React, { useEffect, useState } from "react";
import { Common } from "../../../component/common/common";
import { useDispatch, useSelector } from "react-redux";
import { getviewSalesMappingData } from "../../../features/viewSalesMappingSlice";
import { getStateData } from "../../../features/stateSlice";
import { getDistrictData } from "../../../features/districtSlice";
import Filters from "../../../component/common/Filters/Filters";
import { downloadViewSalesMappingReports } from "../../../features/viewSalesMappingReportDownloadSlice";

const columns = [
  "S.No.",
  "District",
  "State",
  "Territory Head Name",
  "Territory Head Mobile",
  "Territory Head Email",
  "State Head Name",
  "State Head Mobile",
  "State Head Email",
  "Regional Head Name",
  "Regional Head Mobile",
  "Regional Head Email",
  "Created Date",
];

function ViewSalesMapping() {
  const msLoggedUserId = localStorage.getItem("userId");
  const msLoggedRoleId = localStorage.getItem("roleId");

  const dispatch = useDispatch();
  const { data, totalRecords, message } = useSelector(
    (state) => state.viewSalesMapping.data
  );
  const loading = useSelector((state) => state.viewSalesMapping.loading);


  const stateList = useSelector((state) => state.stateList.data);
  const districtList = useSelector((state) => state.districtList.data);

  const [mobileNumber, setMobileNumber] = useState("");
  const [stateItemSelected, setStateItemSelected] = useState("");
  const [districtItemSelected, setDistrictItemSelected] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [startDate, setStartDate] = useState(() => {
    const today = new Date();
    today.setDate(today.getDate() - 1); // Subtract 1 day
    return today;
  });
  
  const [endDate, setEndDate] = useState("");
  const formattedStartDate = startDate
    ? startDate.toLocaleDateString("en-CA", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      })
    : "";
  const formattedEndDate = endDate
    ? endDate.toLocaleDateString("en-CA", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      })
    : "";

  const itemsPerPage = 10;
  const totalPages = Math.ceil(totalRecords / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  useEffect(() => {
    dispatch(
      getviewSalesMappingData({
        formattedStartDate,
        formattedEndDate,
        mobileNumber,
        districtItemSelected,
        stateItemSelected,
        startIndex,
        msLoggedUserId,
        msLoggedRoleId,
      })
    );
  }, [dispatch, currentPage, msLoggedUserId, msLoggedRoleId]);

  useEffect(() => {
    dispatch(getStateData({ msLoggedUserId, msLoggedRoleId }));
  }, []);
  useEffect(() => {
    dispatch(
      getDistrictData({
        msLoggedUserId,
        msLoggedRoleId,
        selectedState: stateItemSelected,
      })
    );
  }, [stateItemSelected]);

  const handleSubmit = () => {
    dispatch(
      getviewSalesMappingData({
        formattedStartDate,
        formattedEndDate,
        mobileNumber,
        districtItemSelected,
        stateItemSelected,
        startIndex,
        msLoggedUserId,
        msLoggedRoleId,
      })
    );
  };

  const handleDownload = () => {
    
    dispatch(
      downloadViewSalesMappingReports({
        formattedStartDate,
        formattedEndDate,
        mobileNumber,
        districtItemSelected,
        stateItemSelected,
        startIndex,
        msLoggedUserId,
        msLoggedRoleId,
      })
    )
      .then((response) => {
        console.log("api response hierarchy");
        const currentDate = new Date();
        const timestamp = currentDate.getTime();
        const formattedDate = currentDate.toISOString().slice(0, 10);
        const suggestedFileName = `view_sales_mapping_${formattedDate}_${timestamp}.csv`;
        const blob = new Blob([response.payload], { type: "text/csv" });

        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = suggestedFileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        
      })
      .catch((error) => {
        console.error("Download error:", error);
      });
  };

  const handleStateChange = (e) => {
    setStateItemSelected(e.target.value);
  };
  const handleDistrictChange = (e) => {
    setDistrictItemSelected(e.target.value);
  };

  // table data
  const tabledata = data
    ? data.map((item, index) => {
        const formatDate = (date) => {
          if (!date) return "";

          const d = new Date(date);
          const year = d.getFullYear();
          const month = String(d.getMonth() + 1).padStart(2, "0");
          const day = String(d.getDate()).padStart(2, "0");

          return `${day}-${month}-${year}`;
        };
        return {
          "S.No.": (currentPage - 1) * 10 + index + 1,
          District: item.district,
          State: item.state,
          "Territory Head Name": item.thName,
          "Territory Head Mobile": item.thMobile,
          "Territory Head Email": item.thEmail,
          "State Head Name": item.shName,
          "State Head Mobile": item.shMobile,
          "State Head Email": item.shEmail,
          "Regional Head Name": item.rhName,
          "Regional Head Mobile": item.rhMobile,
          "Regional Head Email": item.rhEmail,
          "Created Date": formatDate(item.created_date),
        };
      })
    : [];

  return (
    <div className="container" style={{ minHeight: "81.8vh" }}>
      <div className="row">
        <div className="col-md-12 mt-5">
          <h2 className="text-capitalize fs-5 fst-italic">
            View Sales Mapping
          </h2>
        </div>
        <div className="col-md-12">
          <Filters
            startDate={startDate}
            endDate={endDate}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            mobileNumber={mobileNumber}
            setMobileNumber={setMobileNumber}
            stateItemSelected={stateItemSelected}
            handleStateChange={handleStateChange}
            stateList={stateList}
            districtList={districtList}
            districtItemSelected={districtItemSelected}
            handleDistrictChange={handleDistrictChange}
            handleSubmit={handleSubmit}
            handleDownload={handleDownload}
            msLoggedRoleId={msLoggedRoleId}
          />
        </div>
        {loading ? (
          <div className="d-flex justify-content-center mt-4">
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        ) : (
          <div className="col-md-12 my-4">
            <Common.CustomTable
              columns={columns}
              data={tabledata}
              message={message}
              totalRecords={totalRecords}
              itemsPerPage={itemsPerPage}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              totalPages={totalPages}
              startIndex={startIndex}
              endIndex={endIndex}
            />
           
          </div>
        )}
      </div>
    </div>
  );
}

export default ViewSalesMapping;
