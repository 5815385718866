import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import appConfig from "../AppConfig";

const initialState = {
  loading: false,
  data: [],
  error: "",
};

export const getviewSalesMappingData = createAsyncThunk(
  "getviewSalesMappingData",
  async (
    {
      formattedStartDate,
      formattedEndDate,
      mobileNumber,
      districtItemSelected,
      stateItemSelected,
      startIndex,
      msLoggedUserId,
      msLoggedRoleId,
    },
    { rejectWithValue }
  ) => {
    const urls = `${appConfig.apiUrl}msSalesMappingList?msLoggedUserId=${msLoggedUserId}&msLoggedRoleId=${msLoggedRoleId}&fromDate=${formattedStartDate}&toDate=${formattedEndDate}&district=${districtItemSelected}&state=${stateItemSelected}&mobile=${mobileNumber}&start=${startIndex}&length=10`;
    const headers = {
      "X-API-Key": appConfig.apiKey,
    };
    const response = await axios.get(urls, { headers: headers });
    try {
      const result = response.data;
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const viewSalesMappingDetails = createSlice({
  name: "viewSalesMappingDetails",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getviewSalesMappingData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getviewSalesMappingData.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.error = "";
    });
    builder.addCase(getviewSalesMappingData.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = action.error.message;
    });
  },
});

export default viewSalesMappingDetails.reducer;
