// TextArea.js

import React from "react";
import styles from "./TextArea.module.css";

const TextArea = ({ label, value, onChange, rows, placeholder, name }) => {
  return (
    <div className={styles.textAreaContainer}>
      <label className={styles.label}>{label}</label>
      <textarea
        value={value}
        onChange={onChange}
        rows={rows}
        placeholder={placeholder}
        className={styles.textArea}
        name={name}
      />
    </div>
  );
};

export default TextArea;
