import React from "react";
import { Button, Modal} from "react-bootstrap";
import "./ModalStyles.css";


const Modal_Component = ({ showModal, onClose, title, body, onCancel, onOk }) => {
  return (
    <Modal
      show={showModal}
      onHide={onClose}
      centered
      className="modal-lg"
    >
      <Modal.Header closeButton>
        <Modal.Title className="h5">{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{body}</Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={onCancel}>
          Cancel
        </Button>
        <Button onClick={onOk}>Ok</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default Modal_Component;
