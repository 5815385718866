import React, { useEffect, useState } from "react";
import appConfig from "../../../AppConfig";
import { Link } from "react-router-dom";
import CustomTable from "../../../component/common/TableComponent/TableComponent";
import { useDispatch, useSelector } from "react-redux";
import { getKnowledgeCornerData } from "../../../features/viewKnowledgeCornerSlice";
import Button from "../../../component/Button/Button";
import { downloadKnowledgeCornerReports } from "../../../features/downloadKnowledgeCornerReportSlice";
function ViewKnowledeCorner() {
  const msLoggedUserId = localStorage.getItem("userId");
  const msLoggedRoleId = localStorage.getItem("roleId");
  const dispatch = useDispatch();
  const { data, totalRecords } = useSelector(
    (state) => state.viewKnowledgeCornerList.data
  );
  const loading = useSelector((state) => state.viewKnowledgeCornerList.loading);
  const [currentPage, setCurrentPage] = useState(1);

  const itemsPerPage = 10;
  const totalPages = Math.ceil(totalRecords / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  // const endIndex = startIndex + itemsPerPage;

  useEffect(() => {
    dispatch(getKnowledgeCornerData(startIndex));
  }, [currentPage]);

  // table columns
  const columns = [
    "S.No.",
    "Type",
    "Title",
    "Description",
    "File Name",
    "Created Date",
    "Status",
  ];
  // table data
  const tableData = data
    ? data.map((item, index) => {
        const formatDate = (date) => {
          if (!date) return "";
          const d = new Date(date);
          const year = d.getFullYear();
          const month = String(d.getMonth() + 1).padStart(2, "0");
          const day = String(d.getDate()).padStart(2, "0");
          return `${day}-${month}-${year}`;
        };
        return {
          "S.No.": (currentPage - 1) * 10 + index + 1,
          Type: item.type,
          Title: item.title,
          Description: item.description,
          "File Name": (
            <Link
              id="ahref"
              to={
                item.type === "Link"
                  ? `${item.filename}`
                  : `${appConfig.apiUrl}uploads/knowledge/${item.filename}`
              }
              target="_blank"
              // className={styles.viewBtn}
              className="btn btn-primary w-75 btn-sm"
            >
              {item.type === "Link" ? "Watch" : "View"}
            </Link>
          ),
          "Created Date": formatDate(item.created_date),
          Status: item.is_active,
        };
      })
    : [];
  const handleDownload = () => {
    dispatch(downloadKnowledgeCornerReports({ msLoggedUserId, msLoggedRoleId }))
      .then((response) => {
        const currentDate = new Date();
        const timestamp = currentDate.getTime();
        const formattedDate = currentDate.toISOString().slice(0, 10);
        const suggestedFileName = `view_knowledge_corners_${formattedDate}_${timestamp}.csv`;
        const blob = new Blob([response.payload], { type: "text/csv" });

        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = suggestedFileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((error) => {
        console.error("Download error:", error);
      });
  };
  return (
    <div className="container" style={{ minHeight: "81.8vh" }}>
      <div className="row">
        <div className="col-md-12">
          <div className="row">
            <div className="col-sm-9 mt-5">
              <h2 className="text-capitalize fs-5 fst-italic mt-md-3">
                View knowledge corners
              </h2>
            </div>
            <div className="col-sm-3 d-md-flex justify-content-md-end mt-sm-2 mt-md-4">
              {msLoggedRoleId === "1" || msLoggedRoleId === "3" ? (
                <div className="mt-4">
                  <Button variant={"download"} onClick={handleDownload}>
                    Download
                  </Button>
                </div>
              ) : null}
            </div>
          </div>
        </div>
        {loading ? (
          <div className="d-flex justify-content-center mt-4">
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        ) : (
          <div className="col-md-12 mt-3">
            <CustomTable
              columns={columns}
              data={tableData}
              itemsPerPage={itemsPerPage}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              totalPages={totalPages}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default ViewKnowledeCorner;
