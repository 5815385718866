import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Common } from "../../../component/common/common";
import { useDispatch, useSelector } from "react-redux";
import { getCodeGeneratedData } from "../../../features/codeGenerationReportSlice";
import Button from "../../../component/Button/Button";
import { downloadCodeGeneratedReport } from "../../../features/codeGenerationReportDownloadSlice";
import { downloadCouponReports } from "../../../features/couponReportDownloadSlice";
import Spinner from 'react-bootstrap/Spinner';

function ViewCoupons() {
  const msLoggedUserId = localStorage.getItem("userId");
  const [downloadingMap, setDownloadingMap] = useState({});

  const msLoggedRoleId = localStorage.getItem("roleId");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { data, totalRecords } = useSelector(
    (state) => state.codeGeneratedReport.data
  );
  const loading = useSelector((state) => state.codeGeneratedReport.loading);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const totalPages = Math.ceil(totalRecords / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  // const endIndex = startIndex + itemsPerPage;

  useEffect(() => {
    dispatch(
      getCodeGeneratedData({ startIndex, msLoggedUserId, msLoggedRoleId })
    );
  }, [currentPage]);


  const handleViewReport = (codeId) => {
    setDownloadingMap(prevState => ({
      ...prevState,
      [codeId]: true
    }));
    dispatch(
      downloadCouponReports({ codeId, msLoggedUserId, msLoggedRoleId })
     
    )
      .then((response) => {
        const currentDate = new Date();
        const timestamp = currentDate.getTime();
        const formattedDate = currentDate.toISOString().slice(0, 10);
        const suggestedFileName = `coupons_report_${formattedDate}_${timestamp}.csv`;
        const blob = new Blob([response.payload], { type: "text/csv" });

        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = suggestedFileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setDownloadingMap(prevState => ({
          ...prevState,
          [codeId]: false
        })); 
      })
      .catch((error) => {
        console.error("Download error:", error);
        setDownloadingMap(prevState => ({
          ...prevState,
          [codeId]: false
        })); 
      });
  };

  // table columns
  const columns = [
    "S.No.",
    "Group",
    "Brand",
    "Sub Category",
    "Product Name",
    "Pack Size",
    "Viscosity",
    "Point Assigned",
    "Quantity",
    "Created Date & Time",
    "Expiry Date",
    "Action",
  ];
  // table data
  const tabledata = data
    ? data.map((item, index) => {
        const formatExp = (expDate) => {
          const parts = expDate.split("-");
          return `${parts[2]}-${parts[1]}-${parts[0]}`;
        };

        const formatDate = (date) => {
          if (!date) return "";

          const d = new Date(date);
          const year = d.getFullYear();
          const month = String(d.getMonth() + 1).padStart(2, "0");
          const day = String(d.getDate()).padStart(2, "0");

          let hours = d.getHours();
          const minutes = String(d.getMinutes()).padStart(2, "0");
          const seconds = String(d.getSeconds()).padStart(2, "0");
          const ampm = hours >= 12 ? "PM" : "AM";
          hours = hours % 12;
          hours = hours ? hours : 12; // 0 should be converted to 12
          return `${day}-${month}-${year} ${hours}:${minutes}:${seconds} ${ampm}`;
        };
        return {
          "S.No.": (currentPage - 1) * 10 + index + 1,
          Group: item.group,
          Brand: item.brand,
          "Sub Category": item.subcategory,
          "Product Name": item.productname,
          "Pack Size": item.packsize,
          Viscosity: item.viscosity,
          "Point Assigned": item.pointassigned,
          Quantity: item.qty,
          "Created Date & Time": formatDate(item.createdDate),
          "Expiry Date": formatExp(item.expDate),
          Action: (
           <>
           {item.qty === item.generatedCount &&
            <button
              className="btn btn-primary btn-sm"
             
              onClick={() => handleViewReport(item.codeid)}
            >
           {downloadingMap[item.codeid] ? ( 

            <div className="pro">
            <Spinner id="spinner" />
            <span id="progressing">Progressing..</span>
            </div>
  
  ) : (
    "Download"
  )}
            </button>
        }
             {item.qty !== item.generatedCount &&
           <p id="inprogress">In Progress..</p>
        }
           </>
          ),
        };
      })
    : [];

  const handleDownload = () => {
    dispatch(
      // downloadCodeGeneratedReport({ msLoggedUserId, msLoggedRoleId })
      downloadCodeGeneratedReport({ msLoggedUserId, msLoggedRoleId })
    )
      .then((response) => {
        const currentDate = new Date();
        const timestamp = currentDate.getTime();
        const formattedDate = currentDate.toISOString().slice(0, 10);
        const suggestedFileName = `code_generation_report_${formattedDate}_${timestamp}.csv`;
        const blob = new Blob([response.payload], { type: "text/csv" });

        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = suggestedFileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((error) => {
        console.error("Download error:", error);
      });
  };
  return (
    <div className="container" style={{ minHeight: "81.8vh" }}>
      <div className="row">
        <div className="col-md-12 mt-3">
          <div className="row">
            <div className="col-sm-9 mt-5">
              <h2 className="text-capitalize fs-5 fst-italic">
                Code Generation Report
              </h2>
            </div>
            {msLoggedRoleId === "1" || msLoggedRoleId === "3" ? (
              <div className="col-sm-3 mt-md-3 mb-sm-4 d-md-flex justify-content-md-end">
                <div className="mt-md-4">
                  <Button variant={"download"} onClick={handleDownload}>


                Download

                  </Button>
                </div>
              </div>
            ) : null}
          </div>
        </div>
        {loading ? (
          <div className="d-flex justify-content-center">
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        ) : (
          <div className="col-md-12 my-2">
            <Common.CustomTable
              columns={columns}
              data={tabledata}
              itemsPerPage={itemsPerPage}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              totalPages={totalPages}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default ViewCoupons;
