import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  loading: false,
  data: [],
  error: "",
};

export const getClientIP = createAsyncThunk(
  "getClientIP",
  async (args, { rejectWithValue }) => {
    const urls = "https://api.ipify.org?format=json";
    // const headers = {
    //   "X-API-Key": appConfig.apiKey,
    //   "Content-Type": "application/x-www-form-urlencoded",
    // };
    const response = await axios.get(urls);
    try {
      const result = await response.data;
      // console.log("ClientIP>>>", result);
      return result;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const clientIpDetails = createSlice({
  name: "clientIpDetails",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getClientIP.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getClientIP.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.error = "";
    });
    builder.addCase(getClientIP.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = action.error.message;
    });
  },
});

export default clientIpDetails.reducer;
